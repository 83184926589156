import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Detalha_Evolucao() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [pac, setPac] = useState('')
  const [comboNi1, setComboNi1] = useState([{ value: '', display: '' }])
  const [comboPrf, setComboPrf] = useState([{ value: '', display: '' }])
  const [pk_pac, setPk_Pac] = useState('')
  const [fk_ni1, setFk_ni1] = useState('')
  const [fk_prf, setFk_prf] = useState('')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'datamov', numeric: false, label: 'Data/Hora Evolução', align: 'left', minWidth: 50, order: 'auxdatamov' },
    { id: 'descricao_ni1', numeric: false, label: 'Nível 1', align: 'left', minWidth: 50 },
    { id: 'nomeusu', numeric: false, label: 'Profissional', align: 'left', minWidth: 80 },
    { id: '', numeric: false, label: 'Detalhamento', align: 'left', minWidth: 50, icon: true },


  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboNi1(await api.getComboData('itens_evolucao'))
      setComboPrf(await api.getComboData('usuarios'))
      setComboPac(await api.getComboData('pacientes'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      if (query.fk_prf)
        setFk_prf(query.fk_prf)

      if (query.fk_ni1)
        setFk_ni1(query.fk_ni1)

      setPac(query.nomepac)
      if (query.pk_pac) {
        setPk_Pac(query.pk_pac)
      }
      setFilter(query)
      swal.close()
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...filter };
      // alert(pk_pac)
      console.log(auxValues)
      if (pk_pac > 0) {
        auxValues['fk_pac'] = pk_pac;
        setFilter(auxValues);
        let event = new Event('build');
        getData(event)
      }

    }
    fetchPac()
  }, [pk_pac])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    queryObject.pk_pac = pk_pac || filter.fk_pac
    if (fk_ni1 > 0 || filter.fk_ni1 > 0)
      queryObject.fk_ni1 = fk_ni1 || filter.fk_ni1

    if (fk_prf > 0 || filter.fk_prf > 0)
      queryObject.fk_prf = fk_prf || filter.fk_prf

    setFiltered(true)
    console.log(queryObject)
    window.history.replaceState({ filtered: true }, 'filter', "/primavera/detalhaEvolucao?" + objectToQueryString(queryObject));

    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    setFilter(query)

    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/evolucao/detalha', { params: queryObject }).then(r => {
      setData(r.data)
      swal.close()
    })
  }

  function handleClose(e) {
    e.preventDefault();
    window.history.back()
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
    if (e.target.name == "fk_ni1") {
      setFk_ni1(e.target.value)
    }
    if (e.target.name == "fk_prf") {
      setFk_prf(e.target.value)
    }
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Detalhamento de Evoluções</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrSelect
                value={filter.fk_pac || pk_pac}
                style={{ width: 300 }}
                name="fk_pac"
                id="fk_pac"
                label='Residente'
                data={comboPac}
                onChange={handleChange}
                disabled
              />


              <FrSelect
                value={filter.fk_ni1 || ''}
                style={{ width: 300 }}
                name="fk_ni1"
                id="fk_ni1"
                label='Nível 1'
                data={comboNi1}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.fk_prf || ''}
                style={{ width: 300 }}
                name="fk_prf"
                id="fk_prf"
                label='Profissional'
                data={comboPrf}
                onChange={handleChange}
              />

            </div>

            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
            </div>

          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTableReg regName="evolucao" readonly={true} columns={columns} searched={filtered} codeName="pk_evo" page={'Evolucao'} data={data} enableEdit={false} enableDelete={false} />

        </div>

        <div className="regButtonsDiv">
          <button onClick={handleClose}>Sair</button>
        </div>

        <FooterDelphus />
      </div>
    </div>
  );
}

export default Detalha_Evolucao;
