import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrLoading from '../components/FrLoading'
import FrImageEdit from '../components/FrImageEdit'

import api from '../services/api'
import { dataHoraSql, getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import Exporta_Evolucao from './Exporta_Evolucao'
import FrTableReg from '../components/FrTable'
import FooterDelphus from '../components/FooterDelphus'
import Dialog from '@material-ui/core/Dialog';
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import MUIRichTextEditor from 'mui-rte'
import NavBar from '../components/NavBar'
import moment from 'moment'

const swal = withReactContent(Swal)

function Resumo_Evolucoes() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [editorState, setEditorState] = useState({})
  const [residente, setResidente] = useState(null)
  const [tipo, setTipo] = useState('T')
  const [visibleImages, setVisibleImages] = useState(true)
  const [resumos, setResumos] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [selected, setSelected] = useState({});
  const [showModalSinais, setShowModalSinais] = useState(false)

  let auth = JSON.parse(localStorage['authPrimavera']);

  let sampleMarkup = ''
  let contentHTML = convertFromHTML(sampleMarkup)
  let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
  const [content, setContent] = useState(JSON.stringify(convertToRaw(state)))
  const [content2, setContent2] = useState('')


  const columns = [
    { id: 'data', hidden: true },
    { id: 'format_data', numeric: false, label: 'Data', align: 'left', minWidth: 500, order: 'data' },
    { id: 'tipo', numeric: false, label: 'Tipo', align: 'left', minWidth: 30 },
    { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 30 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)

      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Resumo Evoluções', 'Alteracao'))

      } else {
        setEnableEdit(await api.getAcessos('Resumo Evoluções', 'Inclusao'))
        setTipo(query.tipo)
      }

      setComboPac(await api.getComboData('pacientes', 'reg'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/resumo_evolucoes?pk_rev=' + query.codigo).then(async r => {
          if (r.data[0]) {
            setData(r.data[0])
            setComboPac(await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac))

            if (auth.residente > 0)
              setVisibleImages(r.data[0].imagem1 || r.data[0].imagem2 || r.data[0].imagem3 || r.data[0].imagem4 || r.data[0].imagem5)
            let sampleMarkup = r.data[0].texto_evolucao
            let contentHTML = convertFromHTML(sampleMarkup)
            let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
            setContent(JSON.stringify(convertToRaw(state)))
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])

  const handleImageChange = (campo, image) => {
    const auxValues = { ...data };
    auxValues[campo] = image
    setData(auxValues)
  }

  function consistData(data) {
    let required = [
      'fk_pac',
      'data'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr = 'fk_pac') {
        fieldName = 'Residente'
      }
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.fk_usu = getUserCode()
        auxValues.texto_evolucao = stateToHTML(editorState.getCurrentContent())
        auxValues.data = dataHoraSql(auxValues.data + ' ' + moment(new Date()).format('HH:mm'))
        auxValues.tipo = tipo
        if (errors.length === 0) {
          if (updateType) {
            api.post('/resumo_evolucoes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/resumo_evolucoes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    swal.fire({
                      title: 'ATENÇÃO',
                      text: "Deseja lançar resumo de evolução do tipo " + (tipo == 'F' ? ' Técnico' : 'Familiar') + ' na sequencia?',
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    }).then((result) => {
                      if (result.value) {
                        setTipo(tipo == 'F' ? 'T' : 'F')
                        let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
                        setContent(JSON.stringify(convertToRaw(state)))

                      } else
                        window.history.back()
                    })
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    if ((data.status === 'A') || (enableEdit !== 'S') || (residente) || (data.fk_usu != getUserCode() && updateType)) {
      window.history.back()
      return
    }

    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  function carregarEvolucoes(e, usuario) {
    e.preventDefault();
    let rotinaBack = '/resumo_evolucoes/pegaEvolucoes?fk_pac=' + data.fk_pac + '&data=' + data.data + (usuario ? '&usuario=' + auth.user : '')

    if (tipo == 'F')
      rotinaBack = '/resumo_evolucoes/pegaEvolucoesF?fk_pac=' + data.fk_pac + '&data=' + data.data + (usuario ? '&usuario=' + auth.user : '')

    api.get(rotinaBack).then(r => {
      if (r.data.length > 0) {
        let sampleMarkup = r.data
        let contentHTML = convertFromHTML(sampleMarkup)
        let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        setContent(JSON.stringify(convertToRaw(state)))
      } else
        swal.fire({
          title: 'Atenção!',
          text: "Não existem movimentações na data informada!",
          icon: 'warning',
        })

    })
  }

  function carregarResumoAnterior(e) {
    e.preventDefault();
    let rotinaBack = '/resumo_evolucoes/pegaResumos?fk_pac=' + data.fk_pac + '&usuario=' + auth.user
    if (!data.fk_pac) {
      swal.fire({
        title: 'Atenção!',
        text: "Informe o residente!",
        icon: 'warning',
      })

    } else {
      api.get(rotinaBack).then(r => {
        if (r.data.length > 0) {
          setSelected([])
          setResumos(r.data);
          setShowModal(true)
        } else
          swal.fire({
            title: 'Atenção!',
            text: "Não existem resumos do residente informado!",
            icon: 'warning',
          })

      })
    }
  }

  function carregaSinais(e) {
    e.preventDefault();
    setShowModalSinais(true)

  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  const escondeModal = (e) => {
    setShowModal(false)
    setShowModalSinais(false)
  };


  const confirmaModal = (e) => {
    setShowModal(false)
    if (selected.length > 0) {
      api.get('/resumo_evolucoes?pk_rev=' + selected[0]).then(async r => {
        if (r.data[0]) {
          // setData(r.data[0])

          let sampleMarkup = r.data[0].texto_evolucao
          let contentHTML = convertFromHTML(sampleMarkup)
          let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
          setContent(JSON.stringify(convertToRaw(state)))
        }
      })
    } else {
      swal.fire({
        title: 'Atenção!',
        text: "Nenhum resumo selecionado!",
        icon: 'warning',
      }).then((result) => {
        if (result.value) {
          setShowModal(true)
        }
      })
    }
  };

  return (
    <div className={"defaultReg" + (residente ? '-app' : '')}>
      <NavBar />

      <div className={"defaultRegContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>{updateType ? 'Edição' : 'Inclusão'} de Resumo de Evoluções</h3>)}
          <UserOptions app={'Registro de Diário'} />
        </div>
        {residente ? (<></>) : (<br />)}
        <form id="regForm" onSubmit={handleSubmit} className={residente ? 'reg-app' : ''}>

          <div class="container-fluid">
            <div class="row" >

              <FrSelect
                value={data.fk_pac || ''}
                onChange={handleChange}
                name="fk_pac"
                id="fk_pac"
                data={comboPac}
                style={{ width: 450 }}
                required
                readOnly={data.status === 'A' || (data.fk_usu != getUserCode() && updateType)}
                label="Residente"
                hidden={residente}
              />
              <FrDatePicker
                value={(data.data || "").split("T")[0]}
                onChange={handleChange}
                name="data"
                id="data"
                color="#528b46"
                label="Data"
                variant="outlined"
                size="small"
                readOnly={(data.fk_usu != getUserCode() && updateType) || data.status === 'A'}
                style={{ width: 190 }}
                hidden={residente}
              />

              <button hidden={residente} className="resumoButton" onClick={e => carregarEvolucoes(e, false)}>
                Carregar Todas Evoluções
              </button>
              <button hidden={residente} className="resumoButton" onClick={e => carregarEvolucoes(e, true)}>
                Carregar Evoluções Usuário
              </button>
              <button hidden={residente} className="resumoButton" onClick={e => carregarResumoAnterior(e)}>
                Carregar Resumo Anterior
              </button>
              <button hidden={residente} className="resumoButton" onClick={e => carregaSinais(e)}>
                Controle de Sinais
              </button>
            </div>
          </div>
          <br />
          <MUIRichTextEditor
            onChange={value => {
              const content = JSON.stringify(
                convertToRaw(value.getCurrentContent())
              );
              setContent2(content);
              setEditorState(value)
            }}

            readOnly={(data.fk_usu != getUserCode() && updateType) || data.status === 'A' || residente}
            controls={residente ? [] : ["title", "bold", "italic", "underline", "highlight", "undo", "redo", "numberList", "bulletList"]} defaultValue={content} data={data.texto_evolucao} name="texto_evolucao" id="texto_evolucao" />

        </form>

        <div >
          <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'} >
            <div style={{ padding: '5px' }}>

              <h5 syle={{ marginLeft: '10px', fontWeight: 'bold' }}>Selecionar o resumo desejado</h5>

              <FrTableReg
                mostraCheck={'S'}
                readOnly={true}
                detail
                columns={columns}
                searched={false}
                codeName="pk_rev"
                page={"resumos"}
                data={resumos}
                setSelected={setSelected}
                startOrderBy={'data'}
                startOrder={'desc'}
              />
            </div>

            <div className="FrModalFooter">
              <div className="FrModalButtonsDiv" >
                <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                <button style={{ height: '40px' }} onClick={confirmaModal}> Selecionar Resumo </button>
              </div>
            </div>
          </Dialog>
        </div>

        <div >
          <Dialog aria-labelledby="simple-dialog-title" open={showModalSinais} fullWidth={true} maxWidth={'lg'} >
            <div style={{ padding: '5px' }}>
              <Exporta_Evolucao modal={true} fk_pac={data.fk_pac} />
            </div>
            <div className="FrModalFooter">
              <div className="FrModalButtonsDiv" >
                <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
              </div>
            </div>
          </Dialog>
        </div>

        <div className="grupoTitulo" hidden={!visibleImages}>
          <h5>Imagens</h5>
        </div>
        <div className="grupoEdits" hidden={!visibleImages}>

          <div className="flexImageFields">
            <FrImageEdit readOnly={data.status == 'A' || auth.residente > 0} campo={'imagem1'} id={'1'} regName='resumo_evolucoes' image={data.imagem1} title="Foto 1" pk={data.pk_rev || 999999} visible={true} onImageChange={handleImageChange} />
            <FrImageEdit readOnly={data.status == 'A' || auth.residente > 0} campo={'imagem2'} id={'2'} regName='resumo_evolucoes' image={data.imagem2} title="Foto 2" pk={data.pk_rev || 999999} visible={true} onImageChange={handleImageChange} />
            <FrImageEdit readOnly={data.status == 'A' || auth.residente > 0} campo={'imagem3'} id={'3'} regName='resumo_evolucoes' image={data.imagem3} title="Foto 3" pk={data.pk_rev || 999999} visible={true} onImageChange={handleImageChange} />
            <FrImageEdit readOnly={data.status == 'A' || auth.residente > 0} campo={'imagem4'} id={'4'} regName='resumo_evolucoes' image={data.imagem4} title="Foto 4" pk={data.pk_rev || 999999} visible={true} onImageChange={handleImageChange} />
            <FrImageEdit readOnly={data.status == 'A' || auth.residente > 0} campo={'imagem5'} id={'5'} regName='resumo_evolucoes' image={data.imagem5} title="Foto 5" pk={data.pk_rev || 999999} visible={true} onImageChange={handleImageChange} />

          </div>
        </div>


        <div className={"regButtonsDiv" + (residente ? '-app' : '')}>
          {enableEdit === 'S' && data.status !== 'A' && !residente && !(data.fk_usu !== getUserCode() && updateType) ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' && data.status !== 'A' && !residente && !(data.fk_usu !== getUserCode() && updateType) ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Resumo_Evolucoes;
