import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import api from '../../services/api'
import FaceIcon from '@material-ui/icons/Face';
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';


const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);



const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
  }
}));

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#528b46'

    },
    "& .Mui-selected:hover": {
      backgroundColor: '#528b46'

    },
    width: '100%',
  },
  container: {
    borderRadius: '4px',
    maxHeight: '68vh',
    minHeight: '68vh'
  },
});


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#c0c0c0',
    color: theme.palette.common.black,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 12,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      border: 50,
      borderColor: theme.palette.common.white
    },
  },
}))(TableRow);

export default function TableAgenda(props) {
  const classes = useStyles();
  const tooltipClass = useStylesBootstrap();

  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [dataHora, setDataHora] = useState(props.dataHora)


  useEffect(() => {
    setData(props.data)
    // console.log(props.data)
    setColumns(props.columns)
    // console.log(props.columns)
    setDataHora(props.dataHora)

  }, [props.data])


  const handleClick = (event, pk_age, pk_pac, nomepac) => {
    // alert('agenda:'+pk_age+' pac: '+pk_pac)
    window.location.href = "/primavera/pedeEvolucao?pk_pac=" + pk_pac + "&nomepac=" + nomepac
  };


  return (
    <div>
      {/* <Table stickyHeader aria-label="sticky table"> */}
      {/* <Paper className={classes.root}> */}
      <TableContainer className={classes.container}>

        <Table stickyHeader aria-label="sticky table" className={classes.table} size="small" >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: 20, backgroundColor: '#c0c0c0' }}>
                Horários
              </StyledTableCell>

              {columns.map((column, index) => (


                <Tooltip arrow classes={tooltipClass} placement="top"
                  title={
                    <React.Fragment  >
                      <Typography style={{ padding: 10, backgroundColor: '#528b46', border: '3px solid #c0c0c0' }}
                      >
                        {'Nome: ' + column.nomepac}
                        <br />
                        {'Idade: ' + column.idade}
                        <br />
                        {'Unidade: ' + column.unidade}
                        <br />
                        {column.foto ?

                          <img style={{ height: 100, width: 100 }} src={api.backendUrl + "/static/upload/residente/" + column.id + "/" + column.foto} />
                          : <></>}

                      </Typography>



                    </React.Fragment>
                  }

                >
                  <StyledTableCell
                    key={column.id}
                    align={'center'}
                    borderColor={theme.palette.common.white}
                  >

                    <Link style={{ color: '#000000', fontStyle: 'fsBold' }} to={"/primavera/pacientes/registro?codigo=" + column.id}>

                      {column.nomepac}

                    </Link>
                  </StyledTableCell>
                </Tooltip>
              ))}

            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              dataHora.map((x, index) => {
                return (
                  <>
                    <StyledTableRow>
                      <StyledTableCell align={'center'} width={10} style={{ backgroundColor: '#c0c0c0' }}>
                        {x['horario']}
                      </StyledTableCell>


                      {

                        data.filter(value => { return (value.hora == x['horario']) }).map((row) => (

                          (props.residente ?
                            (< StyledTableCell align={'center'} style={{
                              minWidth: 30, width: 30, backgroundColor: row['color']
                            }}
                            >
                              {row['nomerot']}  <br />
                            </StyledTableCell>) :

                            (< StyledTableCell align={'center'} style={{
                              minWidth: 30, width: 30, backgroundColor: row['color']
                            }}
                            >

                              <Link style={{ color: '#000000' }} to={"/primavera/agenda/registro?codigo=" + row['id']}>
                                {row['nomerot']}  <br />
                              </Link>

                            </StyledTableCell>))



                        ))
                      }
                    </StyledTableRow>
                  </>
                );


              })
            }

          </TableBody>


        </Table>
      </TableContainer>
      {/* </Paper> */}
    </div >
  );
}
