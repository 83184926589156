import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_request_page_outline } from 'react-icons-kit/md/ic_request_page_outline'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'
import FrTextInput from '../components/FrTextInput'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, setMultiSelectFilter } from '../utils/functions'
import ReactExport from "react-data-export";
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { ic_done_all } from 'react-icons-kit/md/ic_done_all'

const swal = withReactContent(Swal)

function Contas_Pagar() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboFcb, setComboFcb] = useState([{ value: '', display: '' }])
  const [arquivo, setArquivo] = React.useState("");
  const [showModal, setshowModal] = React.useState(false);
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [selected, setSelected] = React.useState([]);
  let auth = JSON.parse(localStorage['authPrimavera']);
  const [codPac, setCodPac] = useState(auth.residente)
  const [residente, setResidente] = useState(false)
  const [somaCtr, setSomaCtr] = useState('')
  const [saldoCtr, setSaldoCtr] = useState('')
  const [comboNota, setComboNota] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'pk_ctr', numeric: true, label: 'Título', align: 'left', minWidth: 80 },
    { id: 'numdoc', numeric: true, label: 'Nº NF', align: 'left', minWidth: 80, isLink: true, linkTo: 'nota_fiscal', searchValue: 'pk_ntf' },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data_emissao' },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 100, order: 'data_vencimento' },
    { id: 'format_data_liquidacao', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 100, order: 'data_liquidacao' },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'format_valor_liquidado', numeric: false, label: 'Valor Liquidado', align: 'left', minWidth: 100 },
    { id: 'format_pendente', numeric: false, label: 'Saldo', align: 'left', minWidth: 100 },
  ]

  const columns2 = [
    { id: 'pk_ctr', numeric: true, label: 'Título', align: 'left', minWidth: 80 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data_emissao' },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 100, order: 'data_vencimento' },
    { id: 'format_data_liquidacao', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 100, order: 'data_liquidacao' },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'format_valor_liquidado', numeric: false, label: 'Valor Liquidado', align: 'left', minWidth: 100 },
    { id: 'format_pendente', numeric: false, label: 'Saldo', align: 'left', minWidth: 100 },
  ]

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      let tela = 'Contas a Receber'
      let consulta = await api.getAcessos(tela, 'Consulta')
      setCodPac(auth.residente)
      setComboNota([
        {
          value: " and fk_ntf is not null",
          display: "com Nota Emitida",
        },
        {
          value: " and fk_ntf is null",
          display: "sem Nota Emitida",
        },])

      if (auth.residente > 0) {
        setComboPac(await api.getComboData('pacientes'))
        setComboSituacao([
          {
            value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)>0.01",
            display: "Pendentes",
          },
          {
            value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)<=0.01",
            display: "Liquidados",
          },])



        let event = new Event('build');
        setTimeout(() => getData(event), 3000)

      } else {
        if (consulta !== 'S') {
          swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        } else {
          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          setenableEdit(await api.getAcessos(tela, 'Alteracao'))
          setenableDel(await api.getAcessos(tela, 'Exclusao'))
          setComboFcb(await api.getComboData('forma_cobranca'))

          setComboSituacao([
            {
              value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)>0.01",
              display: "Pendentes",
            },
            {
              value: " and coalesce(valor_total,0)-coalesce(valor_liquidado,0)<=0.01",
              display: "Liquidados",
            },])


          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)
          if (query.fk_pac)
            setComboPac(await api.getComboData('pacientes', 'pac' + query.fk_pac))
          else
            setComboPac(await api.getComboData('pacientes', 'reg'))

          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          swal.close()
          if (query.buscar == true) {
            let event = new Event('build');
            getData(event)
          }
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    setSelected([])
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/contas_receber?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/contas_receber?codpac=' + auth.residente + '&' + objectToQueryString(queryObject)).then(r => {
        setData(r.data)
        if (r.data[0]) {
          setSomaCtr(r.data[0].somaCtr)
          setSaldoCtr(r.data[0].saldoCtr)
        } else {
          setSomaCtr('')
          setSaldoCtr('')
        }
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    setPac('')
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/contas_receber');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
      auxValues['fk_pac'] = e.target.value;
      setPac(e.target.value)
    } else
      auxValues[e.target.name] = e.target.value;


    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      setComboPac(await api.getComboData('pacientes', 'pac' + pac))

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

      }
      setFilter(auxValues);
      setSelected([])
    }
    fetchPac()
  }, [pac])

  function escondeModal(e) {
    e.preventDefault()
    setshowModal(false)
  }

  function liquidacaoLote(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para liquidação em lote.', 'warning')
    } else {
      window.location.href = "/primavera/contas_receber/liquidacaoLote?pks=[" + selected + ']'
    }
  }

  function imprimeData(e) {
    e.preventDefault()
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para impressão do extrato.', 'warning')
    } else {
      setArquivo(api.backendUrl + '/api/contas_receber/extrato_cobranca?pks=' + selected)
      setshowModal(true)
    }
  }


  function emiteNotas(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para emissão da nota.', 'warning')
    } else {
      window.location.href = "/primavera/notas/emissao?pks=[" + selected + ']'
    }
  }

  return (
    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      <div className={"Manut" + (residente ? '-app' : '')}>
        <NavBar />
        <div className={"ManutContent" + (residente ? '-app' : '')}>
          <div className={"defaultHeader" + (residente ? '-app' : '')}>
            {residente ? (<></>) : (<h3>Contas a Receber </h3>)}
            <UserOptions app={'Financeiro'} />
          </div>
          {residente ? (<></>) : (<br />)}


          <div className={"filtersDiv" + (residente ? '-app' : '')}>


            <form id="searchForm" onSubmit={getData} className={residente ? 'filter-app' : ''}>


              {residente ? (<></>) : (<div class="container-fluid">
                <div class="row" >

                  <div class="column">

                    <FrSelect
                      value={filter.fk_pac || ''}
                      onChange={handleChange}
                      name="fk_pac"
                      id="fk_pac"
                      data={comboPac}
                      style={{ width: 450 }}
                      hidden={auth.residente > 0}
                    />
                  </div>
                  <div hidden={auth.residente > 0} class="column" >
                    <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                  </div>
                </div>
              </div>
              )}

              <div style={{ display: (residente ? 'flex' : 'block'), flex: (residente ? '1' : 'block'), flexDirection: (residente ? 'column' : '') }}>
                <FrSelect
                  value={codPac}
                  data={comboPac}
                  style={{ width: (residente ? '100%' : 450), flex: (residente ? '1' : '') }}
                  required
                  disabled={true}
                  hidden={auth.residente == 0}
                  app={residente}
                />

                {residente ? (<></>) : (
                  <>
                    <FrTextInput
                      maxLength='150'
                      value={filter.pk_ctr}
                      onChange={handleChange}
                      name='pk_ctr'
                      id='pk_ctr'
                      color='#c0c0c0'
                      label='Nº Título'
                      variant='outlined'
                      size='small'
                      style={{ width: 80 }}
                    />

                    <FrDatePicker
                      value={(filter.dataini_emissao || '').split('T')[0]}
                      name="dataini_emissao"
                      id="dataini_emissao"
                      color="#528b46"
                      label="Período de Emissão"
                      variant="outlined"
                      size="small"
                      style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                      onChange={handleChange}
                    />

                    <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                    <FrDatePicker
                      value={(filter.datafim_emissao || '').split('T')[0]}
                      name="datafim_emissao"
                      id="datafim_emissao"
                      color="#528b46"
                      variant="outlined"
                      size="small"
                      style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                      onChange={handleChange}

                    />
                  </>
                )}

                <FrDatePicker
                  value={(filter.dataini_vencimento || '').split('T')[0]}
                  name="dataini_vencimento"
                  id="dataini_vencimento"
                  color="#528b46"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                  onChange={handleChange}

                />
                {residente ? (<></>) : (<label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>)}

                <FrDatePicker
                  value={(filter.datafim_vencimento || '').split('T')[0]}
                  name="datafim_vencimento"
                  id="datafim_vencimento"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                  onChange={handleChange}

                />
                {residente ? (<></>) : (<br />)}
                {residente ? (<></>) : (
                  <>
                    <FrDatePicker
                      value={(filter.dataini_liquidacao || '').split('T')[0]}
                      name="dataini_liquidacao"
                      id="dataini_liquidacao"
                      label="Período de Liquidação"
                      color="#528b46"
                      variant="outlined"
                      size="small"
                      style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                      onChange={handleChange}
                    />
                    {residente ? (<></>) : (<label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>)}

                    <FrDatePicker
                      value={(filter.datafim_liquidacao || '').split('T')[0]}
                      onChange={handleChange}
                      name="datafim_liquidacao"
                      id="datafim_liquidacao"
                      color="#528b46"
                      variant="outlined"
                      size="small"
                      style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
                    />

                    <FrSelect
                      value={filter.fk_fcb || ''}
                      name="fk_fcb"
                      id="fk_fcb"
                      label='Forma de Cobrança'
                      data={comboFcb}
                      style={{ width: (residente ? '100%' : 250), flex: (residente ? '1' : '') }}
                      onChange={handleChange}
                      app={residente}
                    />
                  </>
                )}
                <FrSelect
                  value={filter.situacao || ''}
                  name="situacao"
                  id="situacao"
                  label='Situação'
                  data={comboSituacao}
                  style={{ width: (residente ? '100%' : 150), flex: (residente ? '1' : '') }}
                  onChange={handleChange}
                  app={residente}
                />
                <FrSelect
                  value={filter.nota || ''}
                  name="nota"
                  id="nota"
                  label='Nota Fiscal'
                  data={comboNota}
                  style={{ width: (residente ? '100%' : 150), flex: (residente ? '1' : '') }}
                  onChange={handleChange}
                  app={residente}
                />


                <FrTextInput
                  value={filter.numdoc || ''}
                  style={{ width: 130 }}
                  name="numdoc"
                  id="numdoc"
                  color="#528b46"
                  label="Nº Nota"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                />
              </div>
              <div>
                <button onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>
                <button hidden={auth.residente > 0} onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
                {enableAdd !== 'S' ? '' :
                  <Link hidden={auth.residente > 0} to="/primavera/contas_receber/registro">
                    <button >
                      <Icon icon={ic_add_box} size={18} />
                      Novo Registro
                    </button>
                  </Link>}

                <button hidden={auth.residente > 0} onClick={liquidacaoLote}>
                  <Icon icon={ic_done_all} size={18} />
                  Liquidação em Lote
                </button>

                <button hidden={auth.residente > 0} onClick={imprimeData}>
                  <Icon icon={ic_request_page_outline} size={18} />
                  Imprimir Extrato
                </button>


                <button hidden={auth.residente > 0} onClick={emiteNotas}>
                  <Icon icon={ic_request_page_outline} size={18} />
                  Emitir Nota
                </button>

                <ExcelFile element={<button className='btnDownload' id='btnDownload'  >
                  <Icon icon={fileExcel} size={18} />
                  Exportar</button>} filename="Relatório Contas Receber">
                  <ExcelSheet data={data} name="Relatório Contas Receber">
                    <ExcelColumn label="Título" value="pk_ctr" />
                    <ExcelColumn label="Nº NF" value="numdoc" />
                    <ExcelColumn label="Residente" value="nomepac" />
                    <ExcelColumn label="Data Emissão" value="format_data_emissao" />
                    <ExcelColumn label="Data Vencimento" value="format_data_vencimento" />
                    <ExcelColumn label="Data Liquidação" value="format_data_liquidacao" />
                    <ExcelColumn label="Banco Liquidação" value="nomebco" />
                    <ExcelColumn label="Valor" value="format_valor" />
                    <ExcelColumn label="Juros" value="format_juros" />
                    <ExcelColumn label="Descontos" value="format_descontos" />
                    <ExcelColumn label="Valor liquidado" value="format_valor_liquidado" />
                    <ExcelColumn label="Saldo" value="format_pendente" />
                  </ExcelSheet>
                </ExcelFile>
                <br />

                <label hidden={!somaCtr} style={{ paddingLeft: '10px' }}>Total Valor:</label>
                <label hidden={!somaCtr} style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}> {somaCtr}</label>
                <label hidden={!saldoCtr} style={{ paddingLeft: '10px' }}>Total Saldo:</label>
                <label hidden={!saldoCtr} style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}>{saldoCtr}</label>

              </div>
            </form>
          </div>
          {residente ? (<></>) : (<br />)}
          <div className={"tableDiv" + (residente ? '-app' : '')}>
            <FrTable liquidar={auth.residente > 0 ? 'N' : 'S'} widthOptions={auth.residente > 0 ? 80 : 150} selected={selected} setSelected={setSelected} mostraCheck={auth.residente <= 0} regName="contas_receber" columns={auth.residente > 0 ? columns2 : columns} searched={filtered} codeName="pk_ctr" page={'contas_receber'} data={data} enableEdit={enableEdit} enableDel={enableDel} startOrderBy={'pk_ctr'} startOrder={'desc'} />
          </div>
          <FooterDelphus />
        </div>
      </div >
    </div>
  )
}

export default Contas_Pagar;
