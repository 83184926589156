import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';

import FrLoading from '../FrLoading'
import './index.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 50,
    margin: 5,
    marginLeft: 5,
    minWidth: 50,
    '&:first-child': {
      marginLeft: 15,
    },
    border: '2px solid #528b46'

  }
})

function TableDetail(props) {
  const [data, setData] = useState(props.data)
  const [loading, setLoading] = useState(false)
  const classes = useStyles();


  useEffect(() => {
    const fetchData = async () => {
      if (props.data.length > 0) {
        setData(props.data.filter((item) => (item.fk_pmi === props.pk)))
      } else
        setData([])
    }

    fetchData()
  }, [props.data])



  if (loading) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3} >
              <FrLoading text="Buscando dados..." />
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  } else if (data.length > 0) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>

          <div className="DetailScrollWrapper">
            <div className="DetailOfetasList">
              <strong>{props.titulo}</strong>
              {data.map((item) => {
                return (
                  <Card className={classes.root}>
                    {props.requisicao ? item.pk_esl : item.hora}

                  </Card>
                )

              })}
            </div>
          </div>
        </TableCell>

      </>
    )
  } else
    return (<></>)

}

export default TableDetail;