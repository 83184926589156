import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit'
import { upload } from 'react-icons-kit/fa/upload'
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function FrImageEdit(props) {
    const [imagePath, setImagePath] = useState(api.noImageUrl);
    const [imageFile, setImageFile] = useState({})

    useEffect(() => {
        const fetchImage = async () => {
            if (props.regName == 'resumo_evolucoes')
                setImagePath(await api.getImageRev(props.pk, props.image))

            else

                setImagePath(await api.getImage(props.pk_pac, props.pk_est, props.image))
        }

        fetchImage()
    }, [props.image, props.pk_pac, props.pk_est])

    const selectFile = (e) => {
        e.preventDefault();
        if (props.id)
            $('#' + props.id).click();
        else
            $('#html_btn_foto').click();

    }

    const handleSelectedFile = (e) => {
        let file = e.target.files[0];
        if (file) {
            setImageFile(file)
        }
    }

    useEffect(() => {
        if (imageFile.name) {
            let formData = new FormData();
            formData.append('file', imageFile, imageFile.name)
            let chamadaPost = '/pacientes/postImage/' + props.pk_pac
            let chamadaChange = '/pacientes/changeImage/' + props.pk_pac + '/' + imageFile.name
            if (props.pk_est) {
                chamadaPost = '/movimentacao_estoque/postImage/' + props.pk_est
                chamadaChange = '/movimentacao_estoque/changeImage/' + props.pk_est + '/' + imageFile.name
            } else
                if (props.regName == 'resumo_evolucoes') {
                    chamadaPost = '/resumo_evolucoes/postImage/' + props.pk
                    chamadaChange = '/resumo_evolucoes/changeImage/' + props.pk + '/' + props.campo + '/' + imageFile.name
                }

            api.post(chamadaPost, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                if (r.status === 200) {
                    api.post(chamadaChange).then(r => {
                        if (r.status === 200) {
                            swal.fire({
                                customClass: {
                                    container: 'my-swal'
                                },
                                title: 'Imagem Alterada!',
                                text: "A imagem foi alterada com sucesso!",
                                icon: 'success',
                            }).then((result) => {
                                if (result.value) {
                                    if (props.campo)
                                        props.onImageChange(props.campo, imageFile.name)
                                    else
                                        props.onImageChange(imageFile.name)
                                }
                            })
                        } else {
                            swal.fire({
                                customClass: {
                                    container: 'my-swal'
                                },
                                title: 'Erro!',
                                text: "A imagem não pôde ser alterada!",
                                icon: 'error',
                            })
                        }
                    })
                } else {
                    swal.fire({
                        customClass: {
                            container: 'my-swal'
                        },
                        title: 'Erro!',
                        text: "Ocorreu um erro no upload da imagem!",
                        icon: 'error',
                    })
                }
            })
        }
    }, [imageFile])

    const clearImage = (e) => {
        e.preventDefault();
        swal.fire({
            customClass: {
                container: 'my-swal'
            },

            title: 'ATENÇÃO',
            text: "Deseja remover a imagem?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            let chamadaDel = '/pacientes/deleteImage/' + props.pk_pac
            if (props.pk_est) {
                chamadaDel = '/movimentacao_estoque/deleteImage/' + props.pk_est
            } else
                if (props.regName == 'resumo_evolucoes')
                    chamadaDel = '/resumo_evolucoes/deleteImage?pk=' + props.pk + '&campo=' + props.campo

            if (result.value) {
                api.post(chamadaDel).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            customClass: {
                                container: 'my-swal'
                            },

                            title: 'Imagem Excluída',
                            text: "A imagem foi excluída com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                if (props.campo)
                                    props.onImageChange(props.campo, null)
                                else
                                    props.onImageChange(null)
                            }
                        })
                    } else {
                        swal.fire({
                            customClass: {
                                container: 'my-swal'
                            },

                            title: 'Erro!',
                            text: "A imagem não foi excluída!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    return (
        <div className="FrImageEdit" style={props.center ? {alignItems: 'center'} : {}}>
            <div className="FrImageContainer">
                <img src={imagePath} />


            </div>
            <div className="FrImageOptions">
                <input
                    type="file"
                    name="arquivo"
                    id={props.id || "html_btn_foto"}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSelectedFile}

                />
                <button hidden={props.readOnly} type="file" onClick={selectFile}>
                    <Icon icon={upload} size={20} />
                </button>
                <br />
                <button hidden={props.readOnly} onClick={clearImage}>
                    <Icon icon={ic_delete_forever} size={20} />
                </button>
            </div>
        </div>
    )
}

export default FrImageEdit;