import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit'
import { upload } from 'react-icons-kit/fa/upload'
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function FrImageEdit(props) {
    const [imagePath, setImagePath] = useState(api.noImageUrl);
    const [imageFile, setImageFile] = useState({})

    useEffect(() => {
        const fetchImage = async () => {

            // alert(props.pk)
            setImagePath(await api.getImageInv(props.pk, imageFile.name, props.pasta))
        }

        fetchImage()
    }, [imageFile.name])


    useEffect(() => {
        const fetchImage = async () => {
            setImagePath(await api.getImageInv(props.pk, props.image, props.pasta))
        }

        fetchImage()
    }, [props.image])

    const selectFile = (e) => {
        e.preventDefault();
        $('#'+props.id).click();

    }

    const handleSelectedFile = (e) => {
        let file = e.target.files[0];
        console.log(file)
        if (file) {
            setImageFile(file)
        }
    }

    useEffect(() => {
        if (imageFile.name) {
            // swal.fire({
            //     title: 'ATENÇÃO',
            //     text: "Deseja alterar a foto ?",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Sim',
            //     cancelButtonText: 'Não'
            // }).then((result) => {
                if (true) {
                    let formData = new FormData();
                    formData.append('file', imageFile, imageFile.name)
                    console.log('/' + props.regName + '/postImage/' + props.pk)
                    api.post('/' + props.regName + '/postImage/' + props.pk, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(r => {
                      console.log(r)
                        if (r.status === 200) {
                            api.post('/' + props.regName + '/changeImage/' + props.pk + '/' + imageFile.name + '/' + props.idImage).then(r => {
                                if (r.status === 200) {
                                    // swal.fire({
                                    //     title: 'Foto Alterada!',
                                    //     text: "A foto foi alterada com sucesso!",
                                    //     icon: 'success',
                                    // }).then((result) => {
                                        if (true) {
                                            props.onImageChange(imageFile.name, props.campo)
                                        }
                                    // })
                                } else {
                                    swal.fire({
                                        title: 'Erro!',
                                        text: "A foto não pôde ser alterada!",
                                        icon: 'error',
                                    })
                                }
                            })
                        } else {
                            swal.fire({
                                title: 'Erro!',
                                text: "Ocorreu um erro no upload da foto!",
                                icon: 'error',
                            })
                        }
                    })
                }
            // })
        }
    }, [imageFile])

    const clearImage = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja remover a foto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/' + props.regName + '/deleteImage/pk=' + props.pk + '/' + props.idImage).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Foto Excluída',
                            text: "A foto foi excluída com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                props.onImageChange(null, props.campo)
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "A foto não foi excluída!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    return (
        <div className="ImageEdit" style={{ display: props.visible ? 'flex' : 'none' }}>
            <div className="ImageContainer">
                <img src={imagePath} onClick={selectFile} />


            </div>
            <div className="ImageOptions">
                <input
                    type="file"
                    name="arquivo"
                    id={props.id}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSelectedFile}

                />
                <button type="file" onClick={selectFile}>
                    <Icon icon={upload} size={20} />
                </button>
                <br />
                <button onClick={clearImage}>
                    <Icon icon={ic_delete_forever} size={20} />
                </button>
            </div>
        </div>
    )
}

export default FrImageEdit;