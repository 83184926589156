import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrDatePicker from '../components/FrDatePicker';
import Tabs from '../components/Tabs';
import FrTableReg from '../components/FrTableReg'
import FrameF2 from '../components/FrameF2'


const swal = withReactContent(Swal)

function Admissao() {
  const [data, setData] = useState({})
  const [dataDoe, setDataDoe] = useState([{ value: '', display: '' }])
  const [updateType, setUpdateType] = useState(false)
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboAlergias, setComboAlergias] = useState([{ value: '', display: '' }])
  const [comboDoe, setComboDoe] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboRefeicoes, setComboRefeicoes] = useState([{ value: '', display: '' }])
  const [comboSon, setComboSon] = useState([{ value: '', display: '' }])
  const [comboCoc, setComboCoc] = useState([{ value: '', display: '' }])
  const [comboXix, setComboXix] = useState([{ value: '', display: '' }])
  const [comboFumante, setComboFumante] = useState([{ value: '', display: '' }])
  const [comboDrogas, setComboDrogas] = useState([{ value: '', display: '' }])
  const [comboAlcool, setComboAlcool] = useState([{ value: '', display: '' }])
  const [comboExercicios, setComboExercicios] = useState([{ value: '', display: '' }])
  const [comboLazer, setComboLazer] = useState([{ value: '', display: '' }])
  const [comboAnimais, setComboAnimais] = useState([{ value: '', display: '' }])
  const [dataPre, setDataPre] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [comboVal, setComboVal] = useState([{ value: '', display: '' }])
  const [comboEliminacoes, setComboEliminacoes] = useState([{ value: '', display: '' }])

  const columns = [{ id: 'display', numeric: false, label: 'Doença', align: 'left', minWidth: 300 },]


  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Admissão'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {


        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        if (query.pk_pac > 0) {
          const auxValues = { ...data };
          auxValues.fk_pac = query.pk_pac
          setData(auxValues)
          setPac(query.pk_pac)
        }
        setEnableEdit(await api.getAcessos('Admissão', 'Inclusao'))

        setComboPac(await api.getComboData('pacientes'))
        setComboDoe(await api.getComboData('doencas'))
        setComboSon(await api.getComboData('qualidade_sono'))
        setComboCoc(await api.getComboData('eliminacoes_intestinais'))
        setComboXix(await api.getComboData('eliminacoes_urinarias'))
        // setComboGpp(await api.getComboData('grupo_preocupacoes'))
        setComboVal(await api.getComboData('via_alimentacao'))
        setComboEliminacoes([
          {
            "value": "F",
            "display": "Fralda",
          },
          {
            "value": "W",
            "display": "WC",
          },
        ])

        // setComboPreocupacoes(await api.getComboData('preocupacoes'))

        setComboAlergias([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboFumante([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboDrogas([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboAlcool([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboExercicios([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboLazer([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])
        setComboAnimais([{ "value": "S", "display": "Sim", }, { "value": "N", "display": "Não", },])


        setComboRefeicoes([
          {
            "value": "1",
            "display": "1",
          },
          {
            "value": "2",
            "display": "2",
          },
          {
            "value": "3",
            "display": "3",
          },
          {
            "value": "4",
            "display": "4",
          },
          {
            "value": "5",
            "display": "5",
          },

          {
            "value": "6",
            "display": "6",
          },])


        if (query.codigo > 0) {
          setUpdateType(true)
          api.get('/pacientes?pk_pac=' + query.codigo).then(r => {
            if (r.data[0]) {
              setData(r.data[0])
              swal.close()
            } else {
              swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                .then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
            }
          })
        } else swal.close()
      }
    }
    fetchData()
  }, [])

  function handlePac(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };

      api.get('/pacientes?pk_pac=' + e.target.value).then(r => {

        auxValues['idade'] = r.data[0].idade;
        auxValues['sexo'] = r.data[0].strsexo;
        auxValues['nomepho'] = r.data[0].nomepho;

        setData(auxValues)
      })
    }
  }

  function handleData(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      if (query.pk_int == 0) {
        api.get('/internacao?datafim_entrada=' + e.target.value + '&dataini_saida=' + e.target.value + '&fk_pac=' + data.fk_pac).then(r => {
          if (r.data[0]) {
            if (r.data[0].stiativo !== 'S') {
              swal.fire({
                html: "Lançamento em internação inativa!",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              })

              swal.close()
            }
            auxValues['pk_int'] = r.data[0].pk_int;
            setData(auxValues)
          }
        })
      }
    }
  }


  function incluiDoe(e) {
    e.preventDefault();
    const auxValues = { ...data };
    let pk = 1

    if (dataDoe[0].display === '') {
      setDataDoe([{
        value: auxValues['fk_doe'],
        display: auxValues['nomedoe'],
        pk: pk
      }])

    } else {

      let item = [{
        value: dataDoe[0].value,
        display: dataDoe[0].display,
        pk: pk

      }]
      pk++
      var i;
      for (i = 1; i < dataDoe.length; i++) {
        item.push({
          value: dataDoe[i].value,
          display: dataDoe[i].display,
          pk: pk

        })
        pk++
      }
      item.push({
        value: auxValues['fk_doe'],
        display: auxValues['nomedoe'],
        pk: pk

      })
      pk++
      setDataDoe(item)
    }

  }

  function incluiPre(e) {
    e.preventDefault();
    const auxValues = { ...data };

    setDataPre(auxValues['fk_pre']);
  }


  function consistData(data) {
    let required = [
      'fk_pac',
      'data_movimento',
      'alergias',
      'qtd_refeicoes',
      'fk_son',
      'fk_xix',
      'fk_coc',
      'horas_sono',
      'fumante',
      'drogas',
      'alcool',
      'exercicios',
      'lazer',
      'animais',
      'doenca_familia_base',
      'relacao_familia',
      'convivio_criancas',
      'irmaos',
      'consideracoes'

    ]


    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_pac') fieldName = 'RESIDENTE'
      if (curr === 'qtd_refeicoes') fieldName = 'QTD REFEIÇÕES (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_son') fieldName = 'SONO/REPOUSO (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_xix') fieldName = 'ELIMINAÇÕES URINÁRIAS (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'fk_coc') fieldName = 'ELIMINAÇÕES INTESTINAIS (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '
      if (curr === 'horas_sono') fieldName = 'QTD. SONO POR NOITE (ABA HISTÓRIA FISIOLÓGICA/SOCIAL) '

      if (curr === 'doenca_familia_base') fieldName = 'Doença Familiar de Base? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'relacao_familia') fieldName = 'Relação com a família? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'convivio_criancas') fieldName = 'Convívio diário com crianças? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'irmaos') fieldName = 'Tem irmãos? (ABA HISTÓRIA FAMILIAR) '
      if (curr === 'consideracoes') fieldName = 'Consideracoes gerais (ABA HISTÓRIA FAMILIAR) '


      if (curr === 'alergias') {
        if ((data[curr] === 'S') && (!data['quais_alergias']))
          errors.push('QUAIS ALERGIAS?')
      }

      if (curr === 'fumante') {
        if ((data[curr] === 'S') && (!data['detalhe_fumante']))
          errors.push('FUMANTE - QUANTIDADE/DIA E POR QUANTOS ANOS? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'drogas') {
        if ((data[curr] === 'S') && (!data['detalhe_drogas']))
          errors.push('DROGAS - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'alcool') {
        if ((data[curr] === 'S') && (!data['detalhe_alcool']))
          errors.push('ÁLCOOL - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'exercicios') {
        if ((data[curr] === 'S') && (!data['detalhe_exercicios']))
          errors.push('EXERCÍCIOS - QUAL FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'lazer') {
        if ((data[curr] === 'S') && (!data['detalhe_lazer']))
          errors.push('RECREAÇÃO/LAZER QUAL ATIVIDADE? FREQUENCIA? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (curr === 'animais') {
        if ((data[curr] === 'S') && (!data['detalhe_animais']))
          errors.push('ANIMAIS DOMÉSTICOS - QUAIS? QUANTOS? (ABA HISTÓRIA FISIOLÓGICA/SOCIAL)')
      }

      if (!data[curr]) return errors.push(fieldName.toUpperCase())
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.pk_doe = dataDoe;
        auxValues.pk_pre = dataPre;
        let errors = consistData(auxValues)
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        if (query.pk_int > 0)
          auxValues.pk_int = query.pk_int
        console.log(errors.length)
        auxValues.fk_usu = getUserCode()
        if (errors.length === 0) {
          api.post('/admissao/add', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Admissão Concluída',
                text: "Admissão concluída com sucesso!",
                icon: 'success',
              }).then(async (result) => {
                if (result.value) {
                  const urlParams = new URLSearchParams(window.location.search);
                  let query = paramsToObject(urlParams);
                  if (query.pk_int > 0) {
                    //pergunta se quer gerar a agenda
                    const { value: formValues } = await swal.fire({
                      title: 'Deseja gerar a agenda para o residente?',
                      html:

                        <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>

                          <FrDatePicker
                            name="dataIni"
                            id="dataIni"
                            color="#528b46"
                            label="Período"
                            variant="outlined"
                            size="small"
                            style={{ width: 190 }}

                          />

                          <FrDatePicker
                            name="dataFim"
                            id="dataFim"
                            color="#528b46"
                            variant="outlined"
                            size="small"
                            style={{ width: 190 }}

                          />


                        </div>,
                      focusConfirm: false,
                      preConfirm: () => {
                        return {
                          dataIni: document.getElementById('dataIni').value,
                          dataFim: document.getElementById('dataFim').value,
                        }
                      },
                      showCancelButton: true,
                      cancelButtonText: 'Cancelar',
                      confirmButtonText: 'OK',
                      confirmButtonColor: `#528b46`,
                      allowOutsideClick: false
                    })

                    if ((formValues) && (formValues['dataIni']) && (formValues['dataFim'])) {
                      swal.fire({
                        html: <FrLoading text="Gerando agenda..." />,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                      })

                      await api.post('/agenda/gerarAgenda?dataIni=' + formValues['dataIni'] + '&dataFim=' + formValues['dataFim']).then(r => {
                        swal.fire({
                          // title: 'Erro!',
                          text: "Agenda gerada com sucesso!",
                          icon: 'success',
                        })
                      })
                    } else
                      if (formValues) {
                        swal.fire({
                          title: 'Erro!',
                          text: "Data inicial e data final devem ser preenchidas!",
                          icon: 'warning',
                        })
                      }

                    window.location.href = "/primavera/internacao"
                  }
                  else
                    window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Admissão não concluída!",
                icon: 'warning',
              })
            }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a admissão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };

    switch (e.target.name) {
      case 'fk_doe':
        if (e.target.value > 0) {
          api.get('/doencas?pk_doe=' + e.target.value).then(r => {
            auxValues['nomedoe'] = r.data[0].nomedoe;
          })
        }
        auxValues[e.target.name] = e.target.value;
        break

      case 'fk_pre':
        setDataPre(e.target.value);
        auxValues[e.target.name] = e.target.value;
        break
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        api.get('/pacientes?pk_pac=' + pac).then(r => {

          auxValues['idade'] = r.data[0].idade;
          auxValues['sexo'] = r.data[0].strsexo;
          auxValues['nomepho'] = r.data[0].nomepho;

          setData(auxValues);

        })
      }

    }
    fetchPac()
  }, [pac])


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Admissão de Residente</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <Tabs
            aba1_caption="IDENTIFICAÇÃO" aba1_hidden={false} Aba1={<div>
              <div class="container-fluid">
                <div class="row" >

                  <div class="column">

                    <FrTextInput
                      maxLength='150'
                      value={pac}
                      onChange={handleChange}
                      name='pac'
                      id='pac'
                      color='#c0c0c0'
                      label='Residente'
                      variant='outlined'
                      size='small'
                      style={{ width: 80 }}
                      onBlur={handlePac}
                    />

                    <FrSelect
                      value={data.fk_pac || ''}
                      onChange={handleChange}
                      name="fk_pac"
                      id="fk_pac"
                      data={comboPac}
                      style={{ width: 450 }}
                      onBlur={handlePac}
                      required
                    />
                  </div>
                  <div class="column">

                    <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                  </div>


                </div>
              </div>
              <FrDatePicker
                time='S'
                value={(data.data_movimento || '')}
                onChange={handleChange}
                name="data_movimento"
                id="data_movimento"
                color="#528b46"
                label="Data Admissão"
                variant="outlined"
                size="small"
                required
                style={{ width: 250 }}
                onBlur={handleData}

              />
              <br />

              <FrTextInput
                maxLength='150'
                value={data.sexo || ''}
                onChange={handleChange}
                name='sexo'
                id='sexo'
                color='#c0c0c0'
                label='Sexo'
                variant='outlined'
                size='small'
                disabled={true}
                style={{ width: 100 }}
              />

              <FrTextInput
                maxLength='150'
                value={data.idade || ''}
                onChange={handleChange}
                name='idade'
                id='idade'
                color='#c0c0c0'
                label='Idade'
                variant='outlined'
                size='small'
                disabled={true}
                style={{ width: 80 }}
              />

            </div>}


            aba2_caption="HISTÓRIA DOENÇA ATUAL/PREGRESSA" aba2_hidden={false}

            Aba2={<div>
              <strong>História de Doença Atual</strong>
              <hr width="100%" size="50" color=" #528b46" />
              <br />

              <FrTextInput
                maxLength='150'
                value={data.queixa_principal || ''}
                onChange={handleChange}
                name='queixa_principal'
                id='queixa_principal'
                color='#528b46'
                label='Queixa Principal'
                variant='outlined'
                size='small'
                style={{ width: 800 }}
              />
              <br />

              <FrTextInput
                maxLength='150'
                value={data.correlacionadas || ''}
                onChange={handleChange}
                name='correlacionadas'
                id='correlacionadas'
                color='#528b46'
                label='Correlacionadas'
                variant='outlined'
                size='small'
                style={{ width: 800 }}
              />

              <br />
              <FrDatePicker
                value={(data.data_inicio || '').split('T')[0]}
                onChange={handleChange}
                name="data_inicio"
                id="data_inicio"
                color="#528b46"
                label="Data Início"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />

              <FrTextInput
                maxLength='50'
                value={data.duracao || ''}
                onChange={handleChange}
                name='duracao'
                id='duracao'
                color='#528b46'
                label='Duração'
                variant='outlined'
                size='small'
                style={{ width: 300 }}
              />

              <FrTextInput
                maxLength='50'
                value={data.frequencia || ''}
                onChange={handleChange}
                name='frequencia'
                id='frequencia'
                color='#528b46'
                label='Frequência'
                variant='outlined'
                size='small'
                style={{ width: 300 }}
              />

              <br />
              <FrTextInput
                maxLength='100'
                value={data.local_dor || ''}
                onChange={handleChange}
                name='local_dor'
                id='local_dor'
                color='#528b46'
                label='Local da Dor'
                variant='outlined'
                size='small'
                style={{ width: 800 }}
              />

              <br />

              <FrSelect
                value={data.alergias || ''}
                onChange={handleChange}
                name="alergias"
                id="alergias"
                label='Alergias'
                data={comboAlergias}
                style={{ width: 80 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.quais_alergias || ''}
                onChange={handleChange}
                name='quais_alergias'
                id='quais_alergias'
                color='#528b46'
                label='Se sim, quais?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />
              <br />

              <strong>História de Doença Pregressa</strong>
              <hr width="100%" size="50" color=" #528b46" />


              <FrSelect
                value={data.fk_doe || ''}
                onChange={handleChange}
                name="fk_doe"
                id="fk_doe"
                label='Doenças'
                data={comboDoe}
                style={{ width: 450 }}
                required
              />
              <button className="incluiFileButton" onClick={incluiDoe}> {'Incluir'} </button>

              <br />


              <div className="tableDiv">
                <FrTableReg setDataDoe={setDataDoe} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={dataDoe} emptyMessage="Nenhuma doença vinculada!" />
              </div>                            

            </div>}


            aba3_caption="HISTÓRIA FISIOLÓGICA/SOCIAL" aba3_hidden={false}

            Aba3={<div>
              <strong>História Fisiológica</strong>
              <hr width="100%" size="50" color=" #528b46" />

              <FrSelect
                value={data.qtd_refeicoes || ''}
                onChange={handleChange}
                name="qtd_refeicoes"
                id="qtd_refeicoes"
                label='Refeições/dia'
                data={comboRefeicoes}
                style={{ width: 120 }}
                required
              />

              <FrSelect
                value={data.fk_son || ''}
                onChange={handleChange}
                name="fk_son"
                id="fk_son"
                label='Sono/Repouso'
                data={comboSon}
                style={{ width: 200 }}
                required
              />

              <FrSelect
                value={data.fk_coc || ''}
                onChange={handleChange}
                name="fk_coc"
                id="fk_coc"
                label='Eliminações Intestinais'
                data={comboCoc}
                style={{ width: 200 }}
                required
              />

              <FrSelect
                value={data.fk_xix || ''}
                onChange={handleChange}
                name="fk_xix"
                id="fk_xix"
                label='Eliminações Urinárias'
                data={comboXix}
                style={{ width: 200 }}
                required
              />


              <FrTextInput
                maxLength='50'
                value={data.horas_sono || ''}
                onChange={handleChange}
                name='horas_sono'
                id='horas_sonos'
                color='#528b46'
                label='Qtd.Horas Sono'
                variant='outlined'
                size='small'
                style={{ width: 210 }}
              />
              <br />
              <FrSelect
                value={data.fk_val || ''}
                onChange={handleChange}
                name="fk_val"
                id="fk_val"
                label='Via de Alimentação'
                data={comboVal}
                style={{ width: 200 }}
              />
              <FrSelect
                value={data.eliminacoes || ''}
                onChange={handleChange}
                name="eliminacoes"
                id="eliminacoes"
                label='Eliminações'
                data={comboEliminacoes}
                style={{ width: 200 }}
              />
              <br />
              <br />

              <strong>Histórico Social</strong>
              <hr width="100%" size="50" color=" #528b46" />

              <FrSelect
                value={data.fumante || ''}
                onChange={handleChange}
                name="fumante"
                id="fumante"
                label='Fumante'
                data={comboFumante}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_fumante || ''}
                onChange={handleChange}
                name='detalhe_fumante'
                id='detalhe_fumante'
                color='#528b46'
                label='Se sim, quantidade/dia e por quantos anos?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />

              <FrSelect
                value={data.drogas || ''}
                onChange={handleChange}
                name="drogas"
                id="drogas"
                label='Drogas'
                data={comboDrogas}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_drogas || ''}
                onChange={handleChange}
                name='detalhe_drogas'
                id='detalhe_drogas'
                color='#528b46'
                label='Se sim, com qual frequência?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />

              <FrSelect
                value={data.alcool || ''}
                onChange={handleChange}
                name="alcool"
                id="alcool"
                label='Álcool'
                data={comboAlcool}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_alcool || ''}
                onChange={handleChange}
                name='detalhe_alcool'
                id='detalhe_alcool'
                color='#528b46'
                label='Se sim, com qual frequência?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />


              <br />

              <FrSelect
                value={data.exercicios || ''}
                onChange={handleChange}
                name="exercicios"
                id="exercicios"
                label='Pratica exercícios'
                data={comboExercicios}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_exercicios || ''}
                onChange={handleChange}
                name='detalhe_exercicios'
                id='detalhe_exercicios'
                color='#528b46'
                label='Se sim, com qual frequência?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />

              <FrSelect
                value={data.lazer || ''}
                onChange={handleChange}
                name="lazer"
                id="lazer"
                label='Recreação / Lazer'
                data={comboLazer}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_lazer || ''}
                onChange={handleChange}
                name='detalhe_lazer'
                id='detalhe_lazer'
                color='#528b46'
                label='Se sim, qual atividade? Com qual frequência?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />

              <FrSelect
                value={data.animais || ''}
                onChange={handleChange}
                name="animais"
                id="animais"
                label='Animais Domésticos'
                data={comboAnimais}
                style={{ width: 150 }}
                required
              />

              <FrTextInput
                maxLength='150'
                value={data.detalhe_animais || ''}
                onChange={handleChange}
                name='detalhe_animais'
                id='detalhe_animais'
                color='#528b46'
                label='Se sim, quais? Quantos?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />


            </div>}

            aba4_caption="PREOCUPAÇÕES" aba4_hidden={false}
            Aba4={<div>
              <FrTextInput
                maxLength='500'
                value={data.preocupacoes || ''}
                onChange={handleChange}
                name='preocupacoes'
                id='preocupacoes'
                color='#528b46'
                label='Preocupações'
                variant="filled"
                size='small'
                required
                style={{ width: 500 }}
                multiline
                rows="8"
                rowsMax="8"
                fullWidth
              />

            </div>}


            aba5_caption="HISTÓRIA FAMILIAR" aba5_hidden={false}
            Aba5={<div>
              <strong>História Familiar</strong>
              <hr width="100%" size="50" color=" #528b46" />

              <FrTextInput
                maxLength='100'
                value={data.doenca_familia_base || ''}
                onChange={handleChange}
                name='doenca_familia_base'
                id='doenca_familia_base'
                color='#528b46'
                label='Doença familiar de base?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />

              <br />

              <FrTextInput
                maxLength='100'
                value={data.relacao_familia || ''}
                onChange={handleChange}
                name='relacao_familia'
                id='relacao_familia'
                color='#528b46'
                label='Relação com a família?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />


              <br />

              <FrTextInput
                maxLength='100'
                value={data.convivio_criancas || ''}
                onChange={handleChange}
                name='convivio_criancas'
                id='convivio_criancas'
                color='#528b46'
                label='Convívio diário com crianças? Quantas? Graus de parentesco?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />


              <br />

              <FrTextInput
                maxLength='100'
                value={data.irmaos || ''}
                onChange={handleChange}
                name='irmaos'
                id='irmaos'
                color='#528b46'
                label='Tem irmãos? Quantos?'
                variant='outlined'
                size='small'
                required
                style={{ width: 500 }}
              />


              <br />
              <br />

              <strong>Considerações Finais</strong>
              <hr width="100%" size="50" color=" #528b46" />
              <br />

              <FrTextInput
                maxLength='500'
                value={data.consideracoes || ''}
                onChange={handleChange}
                name='consideracoes'
                id='consideracoes'
                color='#528b46'
                label='Parecer final sobre a entrevista de admissão'
                variant="filled"
                size='small'
                required
                style={{ width: 500 }}
                multiline
                rows="8"
                rowsMax="8"
                fullWidth
              />

            </div>}


            aba6_caption="IDENTIFICAÇÃO" aba6_hidden={true}

          >
          </Tabs>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Admissao;
