import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const swal = withReactContent(Swal)

function Medicamentos(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboGpm, setComboGpm] = useState([{ value: '', display: '' }])
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [comboTrm, setComboTrm] = useState([{ value: '', display: '' }])
  const [comboLab, setComboLab] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' }])
  const [comboPri, setComboPri] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        customClass: {
          container: 'my-swal'
        },
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_mdc ? props.pk_mdc : query.codigo

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Medicamentos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Medicamentos', 'Inclusao'))
      }

      setComboGpm(await api.getComboData('grupo_medicamentos'))
      setComboUni(await api.getComboData('unidades_medida'))
      setComboTrm(await api.getComboData('tipo_receita_medica'))
      setComboLab(await api.getComboData('laboratorios'))
      setComboPri(await api.getComboData('principio_ativo'))


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/medicamentos?pk_mdc=' + codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  if (props.modal)
                    props.setShowModal(false)
                  else
                    window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'nome_comercial',
      'fk_uni',
      'fk_trm',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_uni') fieldName = 'Unidade de Medida'
      if (curr === 'fk_trm') fieldName = 'Tipo de Receita Médica'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/medicamentos/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    if (props.modal)
                      props.setShowModal(false)
                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            auxValues.fk_usu = getUserCode()
            api.post('/medicamentos/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    if (props.modal)
                      props.setShowModal(false)
                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            customClass: {
              container: 'my-swal'
            },

            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'referencia':
        auxValues['referencia'] = auxValues['referencia'] == 'S' ? 'N' : 'S'
        break
      default: auxValues[e.target.name] = e.target.value;
    }


    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      {!props.modal ? <NavBar /> : <></>}

      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Medicamento</h3>
          {!props.modal ? <UserOptions /> : <></>}
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={data.nome_comercial || ''}
            onChange={handleChange}
            name="nome_comercial"
            id="nome_comercial"
            color="#528b46"
            label="Nome Comercial"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.nome_generico || ''}
            onChange={handleChange}
            name="nome_generico"
            id="nome_generico"
            color="#528b46"
            label="Nome Genérico"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />

          <br />
          {/* <FrTextInput
            maxLength='45'
            value={data.principio_ativo || ''}
            onChange={handleChange}
            name="principio_ativo"
            id="principio_ativo"
            color="#528b46"
            label="Princípio Ativo"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          /> */}


          <FrTextInput
            maxLength='45'
            value={data.apresentacao || ''}
            onChange={handleChange}
            name="apresentacao"
            id="apresentacao"
            color="#528b46"
            label="Apresentação"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_pri || ''}
            onChange={handleChange}
            name="fk_pri"
            id="fk_pri"
            label='Princípio Ativo'
            data={comboPri}
            style={{ width: 300 }}
          />

          <FormControlLabel
            style={{ margin: '-50px', marginLeft: '0px' }}
            control={
              <Checkbox
                value={data.referencia}
                name='referencia'
                id='referencia'
                size='small'
                color="primary"
                checked={data.referencia == 'S'}
                onClick={handleChange}
              />
            }
            label={'Referência'}
          />

          <br />

          <FrSelect
            value={data.fk_gpm || ''}
            onChange={handleChange}
            name="fk_gpm"
            id="fk_gpm"
            label='Grupo de Medicamento'
            data={comboGpm}
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_uni || ''}
            onChange={handleChange}
            name="fk_uni"
            id="fk_uni"
            label='Unidade de Medida'
            data={comboUni}
            style={{ width: 300 }}
          />

          <br />

          <FrSelect
            value={data.fk_trm || ''}
            onChange={handleChange}
            name="fk_trm"
            id="fk_trm"
            label='Tipo de Receita Médica'
            data={comboTrm}
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_lab || ''}
            onChange={handleChange}
            name="fk_lab"
            id="fk_lab"
            label='Laboratório'
            data={comboLab}
            style={{ width: 300 }}
          />

          <br />


          <FrTextInput
            maxLength='45'
            value={data.codigo_ean || ''}
            onChange={handleChange}
            name="codigo_ean"
            id="codigo_ean"
            color="#528b46"
            label="Código EAN"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.codigo_tuss || ''}
            onChange={handleChange}
            name="codigo_tuss"
            id="codigo_tuss"
            color="#528b46"
            label="Código TUSS"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.qtd_fracionamento || ''}
            onChange={handleChange}
            name="qtd_fracionamento"
            id="qtd_fracionamento"
            color="#528b46"
            label="Qtd.Fracionamento"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.margem || ''}
            onChange={handleChange}
            name="margem"
            id="margem"
            color="#528b46"
            label="Margem"
            variant="outlined"
            size="small"
            style={{ width: 80 }}
          />


          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            data={comboAtivo}
            style={{ width: 100 }}
            label={!updateType ? '' : 'Situação'}
            hidden={!updateType}

          />

        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Medicamentos;
