import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import TableEvolucao from '../components/TableEvolucao'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import Calendar from 'react-calendar';
import FrSelect from '../components/FrSelect'
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, pegaDia, dateSql } from '../utils/functions'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
// import 'react-calendar/dist/Calendar.css';

const swal = withReactContent(Swal)

function Consulta_Evolucao() {
  const [data, setData] = useState([{}])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [value, onChange] = useState(new Date());
  const [dia, setDia] = useState(pegaDia(new Date()));
  const [colunas, setColunas] = useState([{}])
  const [comboPre, setComboPre] = useState([{ value: '', display: '' }])
  const [comboAnd, setComboAnd] = useState([{ value: '', display: '' }])
  const [comboSto, setComboSto] = useState([{ value: '', display: '' }])
  const [comboAla, setComboAla] = useState([{ value: '', display: '' }])
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboUnd, setComboUnd] = useState([{ value: '', display: '' }])
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [linhas, setLinhas] = useState([{}])


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      setComboPre(await api.getComboData('predio'))
      setComboAnd(await api.getComboData('andar'))
      setComboSto(await api.getComboData('setor'))
      setComboAla(await api.getComboData('ala'))
      setComboPac(await api.getComboData('pacientes'))
      setComboUnd(await api.getComboData('unidade'))

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchCombos = async () => {
      if (filter.fk_pre > 0) {
        setComboAnd(await api.getComboData('andar', filter.fk_pre))
        setComboSto(await api.getComboData('setor', filter.fk_pre))
        setComboAla(await api.getComboData('ala', filter.fk_pre))


      }
    }

    fetchCombos()
  }, [filter.fk_pre])

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/evolucao');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  function handleClickButton(e) {
    e.preventDefault();
    if (isCalendarVisible)
      setCalendarVisible(false)
    else
      setCalendarVisible(true)
  }

  useEffect(() => {
    const fetchDia = async () => {
      setDia(pegaDia(value))

    }
    fetchDia()
  }, [value])


  useEffect(() => {
    const fetchDia = async () => {

      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      queryObject.buscar = true

      api.get('/evolucao/colunas?data=' + dateSql(value)).then(r => {


        if (r.data) setColunas(r.data)

        api.get('/evolucao/linhas?data=' + dateSql(value)).then(r => {

          if (r.data) setLinhas(r.data)

          api.get('/evolucao?data=' + dateSql(value) + '&' + objectToQueryString(queryObject)).then(r => {
            if (r.data) {
              setData(r.data)

            }
          })
        })
      })
    }
    fetchDia()
  }, [value])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/primavera/evolucao?data=" + dateSql(value) + '&' + objectToQueryString(queryObject));

    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    api.get('/evolucao/colunas').then(r => {
      if (r.data) setColunas(r.data)

      api.get('/evolucao/linhas?data=' + dateSql(value)).then(r => {
        if (r.data) setLinhas(r.data)

        api.get('/evolucao?data=' + dateSql(value) + '&' + objectToQueryString(queryObject)).then(r => {
          if (r.data) {
            setData(r.data)
          }
          swal.close();
        })
      })
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };


  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Consulta Evoluções </h3>
          <UserOptions />
        </div>
        <br />

        <form id="searchForm" onSubmit={getData}>

          <div style={{ color: 'white', backgroundColor: '#528b46', padding: '5px', borderRadius: '4px' }}   >

            <h3>{dia}
              <IconButton style={{ padding: '5px' }} size="small" onClick={(event) => handleClickButton(event)}>
                {isCalendarVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>

            </h3>


            <Calendar className={(!isCalendarVisible ? 'hide-' : '') + "Calendar"}
              onChange={onChange}
              value={value}
            />
          </div>

          <br />

          <div style={{ color: 'white', backgroundColor: '#528b46', padding: 5, borderRadius: '4px' }}   >

            <FrSelect
              value={filter.fk_pac || ''}
              onChange={handleChange}
              name="fk_pac"
              id="fk_pac"
              label='Residente'
              data={comboPac}
              style={{ width: 350 }}
            />


            <FrSelect
              value={filter.fk_pre || ''}
              onChange={handleChange}
              name="fk_pre"
              id="fk_pre"
              label='Prédio'
              data={comboPre}
              style={{ width: 200 }}
            />

            <FrSelect
              value={filter.fk_and || ''}
              onChange={handleChange}
              name="fk_and"
              id="fk_and"
              label='Andar'
              data={comboAnd}
              style={{ width: 150 }}
            />


            <FrSelect
              value={filter.fk_sto || ''}
              onChange={handleChange}
              name="fk_sto"
              id="fk_sto"
              label='Setor'
              data={comboSto}
              style={{ width: 200 }}
            />

            <FrSelect
              value={filter.fk_ala || ''}
              onChange={handleChange}
              name="fk_ala"
              id="fk_ala"
              label='Ala'
              data={comboAla}
              style={{ width: 150 }}
            />

            <FrSelect
              value={filter.fk_und || ''}
              onChange={handleChange}
              name="fk_und"
              id="fk_und"
              label='Unidade'
              data={comboUnd}
              style={{ width: 200 }}
            />

          </div>

          <button className="buttonAgenda" onClick={getData}>
            <Icon icon={ic_search} size={18} />
            Pesquisar
          </button>
          <button className="buttonAgenda" onClick={clearForm}>
            <Icon icon={ic_delete} size={18} />
            Limpar
          </button>
        </form>

        <div className="tableDiv">
          <TableEvolucao linhas={linhas} columns={colunas} data={data} />
        </div>
        <FooterDelphus />
      </div>
    </div>

  );
}

export default Consulta_Evolucao;
