import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import $ from 'jquery'
import FrTableReg from '../components/FrTableReg'

import Dialog from '@material-ui/core/Dialog';

const swal = withReactContent(Swal)

function Plano_Hospedagem() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboTho, setComboTho] = useState([{ value: '', display: '' }])
  const [comboAco, setComboAco] = useState([{ value: '', display: '' }])
  const [comboSvc, setComboSvc] = useState([{ value: '', display: '' }])
  const [dataSvc, setDataSvc] = useState([{ value: '', display: '' }])
  const [dataTph, setDataTph] = useState([{ value: '', display: '' }])
  const [comboTpg, setComboTpg] = useState([{ value: '', display: '' }])
  const [comboUnd, setComboUnd] = useState([{ value: '', display: '' }])
  const [fk_und, setUnd] = useState('')
  const [fk_tpg, setTpg] = useState('')
  const [valor, setValor] = useState('')
  const [nomeund, setNomeUnd] = useState('')
  const [nometpg, setNomeTpg] = useState('')
  const [pkModal, setPkModal] = useState('')

  const [enableEdit, setEnableEdit] = useState('N')
  const [showModalTph, setshowModalTph] = useState(false)
  const [arrayExcluidos, setArrayExcluidos] = useState([])

  const columnsSvc = [{ id: 'display', numeric: false, label: 'Serviço', align: 'left', minWidth: 300 },]
  const columnsTph = [{ id: 'display', numeric: false, label: 'Unidade', align: 'left', minWidth: 100 },
  { id: 'nometpg', numeric: false, label: 'Tipo de Pagamento', align: 'left', minWidth: 100 },
  { id: 'valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Planos de Hospedagem', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Planos de Hospedagem', 'Inclusao'))
      }

      setComboTho(await api.getComboData('tipo_hospedagem'))
      setComboAco(await api.getComboData('acomodacao'))
      setComboSvc(await api.getComboData('servicos'))
      setComboUnd(await api.getComboData('unidade'))
      setComboTpg(await api.getComboData('tipo_pagamento'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/plano_hospedagem?pk_pho=' + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].valor = r.data[0].valor.toFixed(2)
            setData(r.data[0])

            api.get('/plano_hospedagem/servicos?pk_pho=' + query.codigo).then(r => {
              setDataSvc(r.data)

              api.get('/plano_hospedagem/tph?pk_pho=' + query.codigo).then(r => {
                if (r.data.length > 0)
                  setDataTph(r.data)
                swal.close()
              })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao',
      'fk_tho',
      'fk_tpg',
      'fk_aco',
      'valor'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_aco') fieldName = 'Tipo de Acomodação'
      if (curr === 'fk_tho') fieldName = 'Tipo de Hospedagem'
      if (curr === 'fk_tpg') fieldName = 'Tipo de Pagamento'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  const selectFile = (e) => {
    e.preventDefault();
    $('#html_btn').click();
  }


  const handleSelectedFile = (e) => {
    let file = e.target.files[0];
    console.log(file)
    let auxValues = { ...data }
    if (file) {
      auxValues.arq = file
      auxValues.arquivo = file.name
    } else {
      auxValues.arq = null
      auxValues.arquivo = 'Nenhum arquivo selecionado'
    }
    setData(auxValues)
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.pk_svc = dataSvc;
        auxValues.pk_tph = dataTph;
        auxValues.arrayExcluidos = arrayExcluidos;
        auxValues.user = getUserCode()

        console.log(errors.length)
        if (errors.length === 0) {
          let formData = new FormData();
          try {
            formData.append('file', auxValues.arq, auxValues.arquivo)
          } catch {

          }

          formData.append('data', JSON.stringify(auxValues))

          if (updateType) {
            api.post('/plano_hospedagem/edit', formData).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/plano_hospedagem/add', formData).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {

      case 'descricao':
        auxValues[e.target.name] = e.target.value.toUpperCase();
        break
      case 'valor':
        if (/^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(e.target.value))
          setValor(e.target.value);
        break

      case 'fk_tpg':
        setTpg(e.target.value);
        break

      case 'fk_und':
        setUnd(e.target.value);
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  async function incluiSvc(e) {
    e.preventDefault();
    const auxValues = { ...data };
    let pk = 1
    await api.get('/servicos?pk_svc=' + auxValues['fk_svc']).then(r => {
      auxValues['nomesvc'] = r.data[0].descricao;
    })

    if (dataSvc[0].display === '') {
      setDataSvc([{
        value: auxValues['fk_svc'],
        display: auxValues['nomesvc'],
        pk: pk

      }])

    } else {

      let item = [{
        value: dataSvc[0].value,
        display: dataSvc[0].display,
        pk: pk
      }]
      pk++

      var i;
      for (i = 1; i < dataSvc.length; i++) {
        item.push({
          value: dataSvc[i].value,
          display: dataSvc[i].display,
          pk: pk
        })
        pk++
      }
      item.push({
        value: auxValues['fk_svc'],
        display: auxValues['nomesvc'],
        pk: pk

      })
      setDataSvc(item)
    }

  }


  function incluiTph(e) {
    e.preventDefault();
    setValor('')
    setUnd('')
    setTpg('')
    setPkModal(0)
    setshowModalTph(true)

  }


  function escondeModal(e) {
    e.preventDefault();
    setshowModalTph(false)

  }


  function confirmaTph(e) {
    e.preventDefault();
    let duplicidade = false
    let item = []

    if ((!valor) || (!fk_und) || (!fk_tpg)) {
      setshowModalTph(false)

      swal.fire({
        title: 'Erro!',
        text: "Informa a unidade, o tipo de pagamento e o valor!",
        icon: 'warning',
      }).then((result) => {
        setshowModalTph(true)
      })


    } else {

      for (i = 0; i < dataTph.length; i++) {

        if ((dataTph[i].fk_und == fk_und) && (dataTph[i].fk_tpg == fk_tpg) && (dataTph[i].pk !== pkModal)) {
          duplicidade = true
        }
      }


      if (!duplicidade) {
        let pk = -1

        if (pkModal) {

          for (i = 0; i < dataTph.length; i++) {
            if (pkModal == dataTph[i].pk)
              item.push({
                display: dataTph[i].display,
                pk: dataTph[i].pk,
                nometpg: dataTph[i].nometpg,
                valor: valor,
                fk_und: dataTph[0].fk_und,
                fk_tpg: dataTph[0].fk_tpg

              })
            else
              item.push({
                display: dataTph[i].display,
                pk: dataTph[i].pk,
                nometpg: dataTph[i].nometpg,
                valor: dataTph[i].valor,
                fk_und: dataTph[0].fk_und,
                fk_tpg: dataTph[0].fk_tpg

              })
          }
          setDataTph(item)
          setshowModalTph(false)

        } else {
          if (dataTph[0].display === '') {
            setDataTph([{
              display: nomeund,
              pk: pk,
              nometpg: nometpg,
              valor: valor,
              fk_und: fk_und,
              fk_tpg: fk_tpg
            }])
            setshowModalTph(false)

          } else {

            item = [{
              display: dataTph[0].display,
              pk: dataTph[0].pk,
              nometpg: dataTph[0].nometpg,
              valor: dataTph[0].valor,
              fk_und: dataTph[0].fk_und,
              fk_tpg: dataTph[0].fk_tpg
            }]

            var i;
            for (i = 1; i < dataTph.length; i++) {
              item.push({
                display: dataTph[i].display,
                pk: dataTph[i].pk,
                nometpg: dataTph[i].nometpg,
                valor: dataTph[i].valor,
                fk_und: dataTph[0].fk_und,
                fk_tpg: dataTph[0].fk_tpg

              })
              pk--
            }


            item.push({
              display: nomeund,
              pk: pk,
              nometpg: nometpg,
              valor: valor,
              fk_und: fk_und,
              fk_tpg: fk_tpg
            })
          }
          setDataTph(item)
          setshowModalTph(false)
        }

      }

      else {
        setshowModalTph(false)

        swal.fire({
          title: 'Erro!',
          text: "Item em duplicidade!",
          icon: 'warning',
        }).then((result) => {
          setshowModalTph(true)
        })

      }
    }
  }

  useEffect(() => {
    const fetchUnd = async () => {
      setNomeUnd('')
      if (fk_und > 0) {
        api.get('/unidade?pk_und=' + fk_und).then(r => {
          setNomeUnd(r.data[0].descricao);
        })

      }

    }
    fetchUnd()
  }, [fk_und])


  useEffect(() => {
    const fetchTpg = async () => {
      setNomeTpg('')
      if (fk_tpg > 0) {
        api.get('/tipo_pagamento?pk_tpg=' + fk_tpg).then(r => {
          setNomeTpg(r.data[0].descricao);
        })

      }

    }
    fetchTpg()
  }, [fk_tpg])


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Plano de Hospedagem</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#528b46"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />
          <FrSelect
            value={data.fk_tho || ''}
            onChange={handleChange}
            name="fk_tho"
            id="fk_tho"
            label='Tipo'
            data={comboTho}
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_aco || ''}
            onChange={handleChange}
            name="fk_aco"
            id="fk_aco"
            label='Acomodação'
            data={comboAco}
            style={{ width: 200 }}
          />
          <br />
          <strong>Serviços</strong>
          <hr width="100%" size="50" color=" #528b46" />

          <div >
            <FrSelect
              value={data.fk_svc || ''}
              onChange={handleChange}
              name="fk_svc"
              id="fk_svc"
              label='Serviço'
              data={comboSvc}
              style={{ width: 200 }}
            />

            <button className="incluiFileButton" onClick={incluiSvc}> {'Incluir'} </button>

            <br />


            <div className="tableDiv">
              <FrTableReg setDataGrid={setDataSvc} detail regName="" columns={columnsSvc} searched={false} codeName="display" page={''} data={dataSvc} />
            </div>

          </div>

          <br />

          <FrTextInput
            maxLength='500'
            value={data.resumo || ''}
            onChange={handleChange}
            name="resumo"
            id="resumo"
            color="#528b46"
            label="Resumo Descritivo"
            variant="filled"
            size="small"
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />

          <br />
          <strong>Faturamento</strong>
          <hr width="100%" size="50" color=" #528b46" />
          <br />

          <input
            type="file"
            name="arquivo"
            id="html_btn"
            accept=".pdf"
            onChange={handleSelectedFile}
          />
          <FrTextInput
            value={data.arquivo || ''}
            onChange={handleChange}
            name="arquivo"
            id="arquivo"
            color="#528b46"
            label="Minuta de Contrato"
            variant="outlined"
            size="small"
            disabled
            required
            style={{ width: 300 }}
          />
          <button type="file" className="selectFileButton" onClick={selectFile}>
            Minuta de Contrato
          </button>
          <br />
          <button className="incluiFileButton" onClick={incluiTph}> {'Incluir Valores'} </button>

          <br />
          <br />


          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModalTph} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '20px', paddingTop: '40px' }}>

                <FrSelect
                  value={fk_und || ''}
                  onChange={handleChange}
                  name="fk_und"
                  id="fk_und"
                  label='Unidade'
                  data={comboUnd}
                  style={{ width: 200 }}
                  disabled={pkModal}
                />

                <FrSelect
                  value={fk_tpg || ''}
                  onChange={handleChange}
                  name="fk_tpg"
                  id="fk_tpg"
                  label='Tipo de Pagamento'
                  data={comboTpg}
                  style={{ width: 200 }}
                  disabled={pkModal}
                />


                <FrTextInput
                  maxLength='150'
                  value={valor || ''}
                  onChange={handleChange}
                  name='valor'
                  id='valor'
                  color='#c0c0c0'
                  label='Valor'
                  variant='outlined'
                  size='small'
                  style={{ width: 100 }}
                />

              </div>

              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={confirmaTph}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>




          <div className="tableDiv">
            <FrTableReg setPkModal={setPkModal} setModal={setshowModalTph} setfk_und={setUnd} setvalor={setValor} setfk_tpg={setTpg} editar={'S'} arrayExcluidos={arrayExcluidos} setArrayExcluidos={setArrayExcluidos} setDataGrid={setDataTph} detail regName="" columns={columnsTph} searched={false} codeName="display" page={''} data={dataTph} />
          </div>




        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Plano_Hospedagem;
