import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import TableModal from '../components/TableModal'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import FrDatePicker from '../components/FrDatePicker';

const swal = withReactContent(Swal)

function AdmissaoManut() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 50, order: 'data_movimento' },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Admissão'
      let consulta = await api.getAcessos(tela, 'Consulta')

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        let pacientes = await api.getComboData('pacientes')
        setComboPac(pacientes)

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/admissao?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/admissao', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/admissao');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
    console.log(auxValues)
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Admissões</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                color="#528b46"
                label="Período"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_pac || ''}
                name="fk_pac"
                id="fk_pac"
                label='Residente'
                data={comboPac}
                style={{ width: 350 }}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/primavera/admissao/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          {/* <FrTable regName="Admissao" columns={columns} searched={filtered} codeName="pk_ana" page={'Admissao'} data={data} enableEdit={enableEdit} enableDel={enableDel} /> */}
          <TableModal detail regName="Admissao" columns={columns} searched={false} codeName="pk_ana" page={'Admissao'} data={data} enableEdit={true} enableDelete={false} orderBy={'data_movimento'}/>

        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default AdmissaoManut;
