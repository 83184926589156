import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrDatePicker from '../components/FrDatePicker';
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from '../services/api'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTableReg from '../components/FrTableReg'
import FrameF2 from '../components/FrameF2'
import { getUserCode } from '../utils/functions';

const swal = withReactContent(Swal)
const moment = require("moment");

function Administracoes() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])

  const [fk_mdc, setFk_Mdc] = useState(0)
  const [fk_mat, setFk_Mat] = useState(0)
  const [fk_uni, setFk_Uni] = useState(0)
  const [nomemdc, setNomeMdc] = useState('')
  const [nomemat, setNomeMat] = useState('')

  const [complemento, setComplemento] = useState('')
  const [dosagem, setDosagem] = useState('')
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)
  const [pac, setPac] = useState('')

  const [dataPmi, setDataPmi] = useState([{ display: '' }])

  const columns = [
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 100 },
    { id: 'nomemat', numeric: false, label: 'Material', align: 'left', minWidth: 100 },
    { id: 'dosagem', numeric: false, label: 'Qtd.', align: 'left', minWidth: 100 },
    { id: 'complemento', numeric: false, label: 'Observação', align: 'left', minWidth: 100 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setEnableEdit(await api.getAcessos('Prescrição Médica', 'Inclusao'))
      setComboPac(await api.getComboData('pacientes'))
      setComboMdc(await api.getComboData('medicamentos'))
      setComboUni(await api.getComboData('unidades_medida'))
      setComboMat(await api.getComboData('materiais'))


      swal.close()
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);
      }

    }
    fetchPac()
  }, [pac])


  function consistData(data) {
    let required = [
      'fk_pac',
      'dt_previsto_formatado'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr == 'dt_previsto_formatado')
        fieldName = 'Data/Hora Previsão'
      if (curr == 'fk_pac')
        fieldName = 'Residente'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)

        auxValues.pk_pmi = dataPmi;
        auxValues.user = getUserCode()
        
        if (dataPmi[0].fk_uni === '') {
          errors.push('Nenhum material/medicamento informado!')
        }


        if (errors.length === 0) {
          if (!updateType) {

            swal.fire({
              // title: 'ATENÇÃO',
              text: "Deseja atualizar os registros como administrados? ",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não'
            }).then((result) => {
              if (result.value)
                auxValues.administrado = 'S'



              api.post('/administracoes/add', auxValues).then(r => {
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            })
          }

        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break


      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  const handleChangeModal = (e) => {
    if (e.target.name == 'fk_mdc') {
      setFk_Mdc(e.target.value)
      setNomeMdc('');

      if (e.target.value)
        api.get('/medicamentos?pk_mdc=' + e.target.value).then(r => {
          setNomeMdc(r.data[0].nome_comercial);
        })
    }

    if (e.target.name == 'fk_mat') {
      setFk_Mat(e.target.value)
      setNomeMat('');

      if (e.target.value)
        api.get('/materiais?pk_mat=' + e.target.value).then(r => {
          setNomeMat(r.data[0].descricao);
        })
    }

    if (e.target.name == 'fk_uni')
      setFk_Uni(e.target.value)
    if (e.target.name == 'complemento')
      setComplemento(e.target.value)
    if (e.target.name == 'dosagem')
      setDosagem(e.target.value)

  };

  function mostraModal(e) {
    e.preventDefault()
    setFk_Mdc(null)
    setFk_Mat(null)
    setFk_Uni(null)
    setUpdateModal(false)
    setModal(true)
    setComplemento(null)
    setDosagem(null)
    setNomeMat('')
    setNomeMdc('')
  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault()
    if (((!fk_mdc) && (!fk_mat)) || (!dosagem) || (!fk_uni)) {
      swal.fire({
        title: 'Erro!',
        text: "Os campos medicamento ou material, quantidade e unidade devem ser informados!",
        icon: 'warning',
      })

    } else

      if ((fk_mdc) && (fk_mat)) {
        swal.fire({
          title: 'Erro!',
          text: "Informe medicamento OU material!",
          icon: 'warning',
        })

      } else {

        if (!updatemodal)
          incluiPmi(e)
        else
          updatePmi(e)
        closeModal(e)
      }
  }


  function incluiPmi(e) {
    e.preventDefault();
    let pk = 1

    let duplicidade = false
    // if (dataPmi[0].nomemdc !== '') {
    //   var i;
    //   for (i = 0; i < dataPmi.length; i++) {
    //     if ((fk_mdc > 0) && (dataPmi[i].fk_mdc == fk_mdc)) {
    //       duplicidade = true
    //     }
    //   }
    // }


    if (!duplicidade) {
      if ((dataPmi[0].fk_uni === '') || (dataPmi[0].display === '')) {
        setDataPmi([{
          fk_mdc: fk_mdc,
          nomemdc: nomemdc,
          fk_mat: fk_mat,
          nomemat: nomemat,
          fk_uni: fk_uni,
          dosagem: dosagem,
          complemento: complemento,
          pk: pk
        }])

      } else {

        let item = [{
          fk_mdc: dataPmi[0].fk_mdc,
          nomemdc: dataPmi[0].nomemdc,
          fk_mat: dataPmi[0].fk_mat,
          nomemat: dataPmi[0].nomemat,
          fk_uni: dataPmi[0].fk_uni,
          dosagem: dataPmi[0].dosagem,
          complemento: dataPmi[0].complemento,
          pk: pk
        }]
        pk++
        var i;
        for (i = 1; i < dataPmi.length; i++) {
          item.push({
            fk_mdc: dataPmi[i].fk_mdc,
            nomemdc: dataPmi[i].nomemdc,
            fk_mat: dataPmi[i].fk_mat,
            nomemat: dataPmi[i].nomemat,
            fk_uni: dataPmi[i].fk_uni,
            dosagem: dataPmi[i].dosagem,
            complemento: dataPmi[i].complemento,
            pk: pk

          })
          pk++
        }
        item.push({
          fk_mdc: fk_mdc,
          nomemdc: nomemdc,
          fk_mat: fk_mat,
          nomemat: nomemat,
          fk_uni: fk_uni,
          dosagem: dosagem,
          complemento: complemento,
          pk: pk

        })
        pk++
        setDataPmi(item)
      }
    } else
      swal.fire({
        title: 'Erro!',
        text: "Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: 'warning',
      })
  }

  function updatePmi(e) {
    e.preventDefault();

    let item = []
    let i

    let duplicidade = false
    // for (i = 0; i < dataPmi.length; i++) {
    //   if ((dataPmi[i].pk !== pkModal) && (fk_mdc > 0) && (dataPmi[i].fk_mdc == fk_mdc)) {
    //     duplicidade = true
    //   }
    // }

    if (!duplicidade) {
      for (i = 0; i < dataPmi.length; i++) {
        if (dataPmi[i].pk == pkModal) {

          item.push({
            fk_mdc: fk_mdc,
            nomemdc: nomemdc,
            fk_mat: fk_mat,
            nomemat: nomemat,
            fk_uni: fk_uni,
            dosagem: dosagem,
            complemento: complemento,
            pk: pkModal
          })
        } else {
          item.push({
            fk_mdc: dataPmi[i].fk_mdc,
            nomemdc: dataPmi[i].nomemdc,
            fk_mat: dataPmi[i].fk_mat,
            nomemat: dataPmi[i].nomemat,
            fk_uni: dataPmi[i].fk_uni,
            dosagem: dataPmi[i].dosagem,
            complemento: dataPmi[i].complemento,
            pk: dataPmi[i].pk,
          })
        }

        if (i + 1 == dataPmi.length)
          setDataPmi(item)
      }
    }
  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Administração de Medicamento</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrTextInput
                  maxLength='150'
                  value={pac}
                  onChange={handleChange}
                  name='pac'
                  id='pac'
                  color='#c0c0c0'
                  label='Residente'
                  variant='outlined'
                  size='small'
                  style={{ width: 80 }}
                />

                <FrSelect
                  value={data.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  data={comboPac}
                  style={{ width: 450 }}
                  required
                />
              </div>
              <div class="column">

                <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
              </div>

              <div class="column" style={{ paddingLeft: '5px' }}>

                <FrDatePicker
                  time='S'
                  value={(data.dt_previsto_formatado || '')}
                  onChange={handleChange}
                  name="dt_previsto_formatado"
                  id="dt_previsto_formatado"
                  color="#58b46"
                  label="Data/Hora Previsão"
                  variant="outlined"
                  size="small"
                  style={{ width: 220 }}
                />
              </div>
            </div>

          </div>


          <hr width="100%" size="50" color=" #528b46" />


          <div className="grupoTitulo">
            Medicamentos/Materiais
          </div>
          <div className="grupoEdits">
            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div className="manutReg"  >
              <FrTableReg hidden={modal} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setFk_Mat={setFk_Mat} setFk_Mdc={setFk_Mdc} setFk_Uni={setFk_Uni} editar={true} setDataGrid={setDataPmi} setDosagem={setDosagem} setComplemento={setComplemento} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={dataPmi} />
              <div className="register" hidden={!modal}>
                <div className="fields">

                  <FrSelect
                    name="fk_mdc"
                    id="fk_mdc"
                    label='Medicamento'
                    data={comboMdc}
                    style={{ width: 300 }}
                    value={fk_mdc || ''}
                    onChange={handleChangeModal}

                  />
                  <FrSelect
                    name="fk_mat"
                    id="fk_mat"
                    label='Material'
                    data={comboMat}
                    style={{ width: 300 }}
                    value={fk_mat || ''}
                    onChange={handleChangeModal}

                  />

                  <FrTextInput
                    maxLength='30'
                    name="dosagem"
                    id="dosagem"
                    color="#528b46"
                    label="Qtd"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: '85px' }}
                    value={dosagem || ''}
                    onChange={handleChangeModal}

                  />

                  <FrSelect
                    value={fk_uni || ''}
                    name="fk_uni"
                    id="fk_uni"
                    label='Unidade'
                    data={comboUni}
                    style={{ width: 150 }}
                    onChange={handleChangeModal}

                  />

                  <FrTextInput
                    maxLength='80'
                    name="complemento"
                    id="complemento"
                    color="#528b46"
                    label="Observação"
                    variant="outlined"
                    size="small"
                    style={{ width: '300px' }}
                    value={complemento || ''}
                    onChange={handleChangeModal}

                  />



                  <div className="regButtonsDiv">
                    <button onClick={handleSubmitModal}>Salvar</button>
                    <button onClick={closeModal}>Cancelar</button>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Administracoes;
