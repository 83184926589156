import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Principio_Ativo(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        customClass: {
          container: 'my-swal'
        },

        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_pri ? props.pk_pri : query.codigo

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Princípios Ativos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Princípios Ativos', 'Inclusao'))
      }

      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/principio_ativo?pk_pri=' + codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])

            swal.close()

          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  if (props.modal)
                    props.setShowModal(false)
                  else
                    window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };

        let errors = consistData(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/principio_ativo/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    if (props.modal)
                      props.setShowModal(false)
                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/principio_ativo/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    if (props.modal)
                      props.setShowModal(false)
                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            customClass: {
              container: 'my-swal'
            },

            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      {!props.modal ? <NavBar /> : <></>}

      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Princípio Ativo</h3>
          {!props.modal ? <UserOptions /> : <></>}

        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='100'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#528b46"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />

          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label={!updateType ? '' : 'Situação'}
            data={comboAtivo}
            hidden={!updateType}
            style={{ width: 80 }}
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Principio_Ativo;
