import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrTextInput from '../components/FrTextInput'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'

import api from '../services/api'
import { paramsToObject, getFullAge } from '../utils/functions'

import './styles/DefaultReg.css';
import FrTableReg from '../components/FrTableReg'

const swal = withReactContent(Swal)

function Prescricao_Medica(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboPrf, setComboPrf] = useState([{ value: '', display: '' }])
  const [enableAge, setEnableAge] = useState(false)
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [dataPmi, setDataPmi] = useState([{
    fk_mdc: '',
    nomemdc: '',
    complemento: '',
    dosagem: '',
    fk_pmf: '',
    nomepmf: '',
    fk_pmp: '',
    nomepmp: ''
  }])
  const [comboPmf, setComboPmf] = useState([{ value: '', display: '' }])
  const [comboPmp, setComboPmp] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 300 },
    { id: 'dosagem', numeric: false, label: 'Dosagem', align: 'left', minWidth: 100 },
    { id: 'complemento', numeric: false, label: 'Complemento', align: 'left', minWidth: 200 },
    { id: 'nomepmf', numeric: false, label: 'Frequência', align: 'left', minWidth: 100 },
    { id: 'nomepmp', numeric: false, label: 'Período', align: 'left', minWidth: 100 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (props.pk_pmd > 0) {
        setEnableEdit(false)//await api.getAcessos('Prescrição Médica', 'Alteracao'))
        await api.get('/prescricao_medica/pmi?pk_pmd=' + props.pk_pmd).then(r => {
          if (r.data)
            setDataPmi(r.data)
        })

      } else {
        setEnableEdit(await api.getAcessos('Prescrição Médica', 'Inclusao'))

      }

      setComboPac(await api.getComboData('pacientes'))
      setComboPrf(await api.getComboData('profissionais'))
      setComboMdc(await api.getComboData('medicamentos'))
      setComboPmf(await api.getComboData('prescricao_medica_frequencia'))
      setComboPmp(await api.getComboData('prescricao_medica_periodo'))

      if (props.pk_pmd > 0) {
        setUpdateType(true)
        api.get('/prescricao_medica?pk_pmd=' + props.pk_pmd).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            r.data[0].idade = getFullAge(r.data[0].data_nasc)
            setData(r.data[0])

            setEnableAge(true)

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [props.pk_pmd])

  function handleAge(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };
      api.get('/pacientes?pk_pac=' + e.target.value).then(r => {
        auxValues['idade'] = getFullAge(r.data[0].data_nasc)
        setData(auxValues)
        setEnableAge(true)
      })
    }
  }


  function consistData(data) {
    let required = [
      'fk_pac',
      'fk_prf'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.pk_pmi = dataPmi;

        if (errors.length === 0) {
          if (updateType) {
            api.post('/prescricao_medica/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/prescricao_medica/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_mdc':
        if (e.target.value)
          api.get('/medicamentos?pk_mdc=' + e.target.value).then(r => {
            auxValues['nomemdc'] = r.data[0].nome_comercial;
          })
        auxValues[e.target.name] = e.target.value;
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div>
      <FrSelect
        value={data.fk_pac || ''}
        onChange={handleChange}
        name="fk_pac"
        id="fk_pac"
        label='Residente'
        data={comboPac}
        style={{ width: 350 }}
        onBlur={handleAge}
      />


      <FrTextInput
        maxLength='250'
        value={data.idade || ''}
        name="idade"
        id="idade"
        color="#528b46"
        // label="Descrição"
        variant="standard"
        size="small"
        disabled
        hidden={!enableAge}
        style={{ width: 230 }}
      />

      <br />


      <FrSelect
        value={data.fk_prf || ''}
        onChange={handleChange}
        name="fk_prf"
        id="fk_prf"
        label='Profissional'
        data={comboPrf}
        style={{ width: 350 }}
      />

      <br />


      <hr width="100%" size="50" color=" #528b46" />


      <div className="tableDiv">
        <FrTableReg detail regName="" columns={columns} searched={false} codeName="pk_pmi" page={''} data={dataPmi} />
      </div>

    </div>
  );
}

export default Prescricao_Medica;
