import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'


import './index.css';


const theme = createMuiTheme({
  palette: {
    primary: { main: '#528b46' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#beccaf'
    },
    "& .Mui-selected:hover": {
      backgroundColor: '#beccaf'
    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: '#528b46',
    backgroundColor: '#528b46',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



export default function FrTablePermissions(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [selectedV, setSelectedV] = React.useState([]);
  const [selectedI, setSelectedI] = React.useState([]);
  const [selectedE, setSelectedE] = React.useState([]);
  const [selectedX, setSelectedX] = React.useState([]);


  useEffect(() => {
    setData(props.data)
    setPage(0)


  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {

    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)



  }, [selected])

  const handleSelectAllClick = (event, id) => {
    const tamanho = id.length
    let newSelected = [];
    let newSelectedV = [];
    let newSelectedI = [];
    let newSelectedE = [];
    let newSelectedX = [];


    props.selected.map((s) => {
      if (s.substring(0, tamanho) !== id)
        newSelected = newSelected.concat(s);
    })

    if (event.target.checked) {
      data.map((d) => {
        newSelected = newSelected.concat(id + d.name);
      })


      setSelected(newSelected);
      props.setSelected(newSelected);

      newSelected.map((s) => {
        if (s.substring(0, 10) == 'Visualizar')
          newSelectedV = newSelectedV.concat(s);

        if (s.substring(0, 7) == 'Incluir')
          newSelectedI = newSelectedI.concat(s);

        if (s.substring(0, 6) == 'Editar')
          newSelectedE = newSelectedE.concat(s);

        if (s.substring(0, 7) == 'Excluir')
          newSelectedX = newSelectedX.concat(s);

      })

      setSelectedE(newSelectedE)
      setSelectedI(newSelectedI)
      setSelectedV(newSelectedV)
      setSelectedX(newSelectedX)
      return;
    }


    setSelected(newSelected);
    props.setSelected(newSelected);

    newSelected.map((s) => {
      if (s.substring(0, 10) == 'Visualizar')
        newSelectedV = newSelectedV.concat(s);

      if (s.substring(0, 7) == 'Incluir')
        newSelectedI = newSelectedI.concat(s);

      if (s.substring(0, 6) == 'Editar')
        newSelectedE = newSelectedE.concat(s);

      if (s.substring(0, 7) == 'Excluir')
        newSelectedX = newSelectedX.concat(s);

    })


    setSelectedE(newSelectedE)
    setSelectedI(newSelectedI)
    setSelectedV(newSelectedV)
    setSelectedX(newSelectedX)
  };

  const handleClick = (event, name) => {
    const selectedIndex = props.selected.indexOf(name);
    // let newSelected = [...props.selected];
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1),
      );
    }
    // console.log(newSelected)

    setSelected(newSelected);
    props.setSelected(newSelected);

  };


  const isSelected = (acao, tela) => props.selected.indexOf(acao + tela) !== -1;


  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} size="small">
              {/* aria-label="sticky table"> */}
              <TableHead>
                <TableRow style={{ height: 5 }}>
                  {/* <StyledTableCell padding="checkbox">
                      <FrCheckBox
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell> */}
                  {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.minWidth, height: 5 }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                        align={'left'}
                      >
                        {column.label}
                      </TableSortLabel>
                      <br />
                      {(column.label == 'Tela') ? <></> :

                        (column.label == 'Visualizar') ?
                          <FrCheckBox
                            indeterminate={selectedV.length > 0 && selectedV.length < data.length}
                            checked={data.length > 0 && selectedV.length === data.length}
                            onClick={(event) => handleSelectAllClick(event, column.id)}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                          :
                          (column.label == 'Incluir') ?
                            <FrCheckBox
                              indeterminate={selectedI.length > 0 && selectedI.length < data.length}
                              checked={data.length > 0 && selectedI.length === data.length}
                              onClick={(event) => handleSelectAllClick(event, column.id)}
                              inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                            :
                            (column.label == 'Editar') ?
                              <FrCheckBox
                                indeterminate={selectedE.length > 0 && selectedE.length < data.length}
                                checked={data.length > 0 && selectedE.length === data.length}
                                onClick={(event) => handleSelectAllClick(event, column.id)}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                              />
                              :
                              (column.label == 'Excluir') ?
                                <FrCheckBox
                                  indeterminate={selectedX.length > 0 && selectedX.length < data.length}
                                  checked={data.length > 0 && selectedX.length === data.length}
                                  onClick={(event) => handleSelectAllClick(event, column.id)}
                                  inputProps={{ 'aria-label': 'select all desserts' }}
                                />


                                : <></>}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let value = '';
                  if (props.detail) {
                    return (
                      <>
                        {/* <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}> */}
                        <TableRow style={{ height: 5 }}>

                          {/* <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                              <FrCheckBox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell> */}
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align} padding="checkbox" style={{ minWidth: 30, width: 30 }}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  } else {
                    return (
                      // <TableRow onClick={(event) => handleClick(event, row[props.codeName])} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                      <TableRow>
                        {/* <TableCell padding="checkbox">
                            <FrCheckBox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell> */}

                        {columns.map((column) => {
                          if (row[column.id] !== undefined) {
                            value = row[column.id]
                          }
                          if (column.id !== 'name') {
                            const isItemSelected = isSelected(column.id, value);

                            return (
                              <TableCell align={'left'} >
                                <FrCheckBox onClick={(event) => handleClick(event, column.id + value)}
                                  checked={isItemSelected}//(column.id == 'Visualizar') ? true : false}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>


                            );
                          } else
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            )
                        })}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}
