import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function MostraAlertas() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)


  const columns = [
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 50 },
    { id: 'datamov', numeric: false, label: 'Data/Hora', align: 'left', minWidth: 50 },
    { id: '', numeric: false, label: 'Detalhamento', align: 'left', minWidth: 50, icon: true },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      swal.close()
      let event = new Event('build');
      getData(event)
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    setFiltered(true)
    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    setFilter(query)

    api.get('/evolucao/detalhaAlertas', { params: query }).then(r => {
      console.log(r.data)
      setData(r.data)
      swal.close()
    })
  }

  function handleClose(e) {
    e.preventDefault();
    window.history.back()
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Alertas de Evoluções</h3>
          <UserOptions />
        </div>
        <div className="tableDiv">
          <FrTableReg regName="evolucao" readonly={true} columns={columns} searched={filtered} codeName="pk_evo" page={'Evolucao'} data={data} enableEdit={false} enableDelete={false} />
        </div>

        <div className="regButtonsDiv">
          <button onClick={handleClose}>Sair</button>
        </div>

        <FooterDelphus />
      </div>
    </div>
  );
}

export default MostraAlertas;
