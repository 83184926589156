import React, { useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Materiais(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboGrm, setComboGrm] = useState([{ value: '', display: '' }])
  const [comboFab, setComboFab] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 300 },
    { id: 'nomegrm', numeric: false, label: 'Grupo de Material', align: 'left', minWidth: 150 },
    { id: 'nomefab', numeric: false, label: 'Fabricante', align: 'left', minWidth: 150 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboGrm(await api.getComboData('grupo_materiais'))
      setComboFab(await api.getComboData('fabricantes'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    if (1===1) {
      queryObject.buscar = true
      setFiltered(true)
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/materiais', { params: filter }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    if(e.target.value =='') auxValues[e.target.name] = null
    console.log(auxValues)
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Materiais</h3>
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrTextInput
                value={filter.descricao || ''}
                style={{ width: 300 }}
                name="descricao"
                id="descricao"
                color="#528b46"
                label="Descrição"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.pk_grm || ''}
                style={{ width: 300 }}
                name="pk_grm"
                id="pk_grm"
                label='Grupo de Material'
                data={comboGrm}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.pk_fab || ''}
                style={{ width: 300 }}
                name="pk_fab"
                id="pk_fab"
                label='Fabricante'
                data={comboFab}
                onChange={handleChange}
              />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
            <FrTableReg 
                readonly={true} 
                mat={props.mat} 
                setMat={props.setMat} 
                regName="" 
                columns={columns} 
                searched={filtered} 
                codeName="pk_mat" 
                page={''} 
                data={data} 
                enableEdit={false} 
                enableDel={false}
            />
        </div>
      </div>
    </div>
  );
}

export default Materiais;
