import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Internacao() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboPlt, setComboPlt] = useState([{ value: '', display: '' }])
  const [comboPho, setComboPho] = useState([{ value: '', display: '' }])
  const [comboSti, setComboSti] = useState([{ value: '', display: '' }])
  const [trazPreenchido, setTrazPreenchido] = useState(true)

  const columns = [
    { id: 'data_entrada_formatada', numeric: false, label: 'Data Entrada', align: 'left', minWidth: 300, order: 'data_entrada' },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'responsavel', numeric: false, label: 'Responsável', align: 'left', minWidth: 200 },
    { id: 'nomesti', numeric: false, label: 'Status', align: 'left', minWidth: 150 },


  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Internação'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('pacientes'))
        setComboPho(await api.getComboData('plano_hospedagem'))
        setComboPlt(await api.getComboData('plano_trabalho'))
        setComboSti(await api.getComboData('status_internacao'))


        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)


        if (trazPreenchido) {
          window.history.replaceState({ filtered: true }, 'filter', "/primavera/internacao?fk_sti=1&buscar=true")

          let url = new URLSearchParams(window.location.search);
          query = paramsToObject(url)

          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          let event = new Event('build');
          getData(event)
          setTrazPreenchido(false)
        }

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/internacao?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/internacao', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/internacao');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Internações</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrSelect
                value={filter.fk_pac || ''}
                name="fk_pac"
                id="fk_pac"
                label='Residente'
                data={comboPac}
                style={{ width: 250 }}
                onChange={handleChange}

              />

              <FrDatePicker
                value={(filter.dataini_entrada || '').split('T')[0]}
                name="dataini_entrada"
                id="dataini_entrada"
                color="#528b46"
                label="Período Entrada"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>
              <FrDatePicker
                value={(filter.datafim_entrada || '').split('T')[0]}
                name="datafim_entrada"
                id="datafim_entrada"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />

              <FrDatePicker
                value={(filter.dataini_prevsaida || '').split('T')[0]}
                name="dataini_prevsaida"
                id="dataini_prevsaida"
                color="#528b46"
                label="Período Previsão de Saída"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>
              <FrDatePicker
                value={(filter.datafim_prevsaida || '').split('T')[0]}
                name="datafim_prevsaida"
                id="datafim_prevsaida"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <br />
              <FrDatePicker
                value={(filter.dataini_saida || '').split('T')[0]}
                name="dataini_saida"
                id="dataini_saida"
                label="Período de Saída"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>
              <FrDatePicker
                value={(filter.datafim_saida || '').split('T')[0]}
                onChange={handleChange}
                name="datafim_saida"
                id="datafim_saida"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />

              <FrSelect
                value={filter.pk_plt || ''}
                name="pk_plt"
                id="pk_plt"
                label='Plano de Trabalho'
                data={comboPlt}
                style={{ width: 250 }}
                onChange={handleChange}

              />

              <FrSelect
                value={filter.pk_pho || ''}
                name="pk_pho"
                id="pk_pho"
                label='Plano de Hospedagem'
                data={comboPho}
                style={{ width: 250 }}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_sti || ''}
                name="fk_sti"
                id="fk_sti"
                label='Status da Internação'
                data={comboSti}
                style={{ width: 150 }}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/primavera/internacao/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable widthOptions={125} regName="internacao" columns={columns} searched={filtered} codeName="pk_int" page={'internacao'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Internacao;
