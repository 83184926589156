import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content/";
import "./index.css";
import Checkbox from '@material-ui/core/Checkbox';
import Input from "@material-ui/core/Input";
import { Icon } from 'react-icons-kit'
import { ic_fact_check_outline } from 'react-icons-kit/md/ic_fact_check_outline'
import Dialog from '@material-ui/core/Dialog';
import TableRequisicoes from '../TableRequisicoes'
import Card from '@material-ui/core/Card';

const swal = withReactContent(Swal);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#beccaf",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#beccaf",
    },
    width: "100%",

  },
  container: {
    maxHeight: "75vh",
    minHeight: "75vh",
  },
  containerLotes: {
    maxHeight: "45vh",
    minHeight: "45vh",
  },

  card: {
    maxWidth: 50,
    margin: 5,
    marginLeft: 5,
    minWidth: 50,
    '&:first-child': {
      marginLeft: 15,
    },
    border: '2px solid #528b46'

  }
});

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#528b46",
    color: theme.palette.common.white,
    padding: '2px'
  },
  body: {
    fontSize: 12,
    padding: '2px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

  },
}))(TableRow);

export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState(props.data);
  const [dataEsl, setDataEsl] = useState(props.dataEsl);

  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = useState(props.order || "desc");
  const [orderBy, setOrderBy] = useState(props.orderBy || props.columns[0].id);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [nomeMdc, setNomeMdc] = useState('');
  const [pendente, setPendente] = useState(0);
  const [pk_rei, setpk_rei] = useState('');


  const columnsEsl = [
    // {
    //   id: "pk_esl",
    //   numeric: false,
    //   label: "PK",
    //   align: "left",
    //   minWidth: 150,
    // },

    // {
    //   id: "fk_est",
    //   numeric: false,
    //   label: "Est",
    //   align: "left",
    //   minWidth: 150,
    // },

    {
      id: "lote",
      numeric: false,
      label: "Lote",
      align: "left",
      minWidth: 150,
    },
    {
      id: "validade",
      numeric: false,
      label: "Validade",
      align: "left",
      minWidth: 150,
    },
    {
      id: "saldo",
      numeric: false,
      label: "Saldo",
      align: "left",
      minWidth: 150,
    },
    {
      id: "qtd_atender",
      numeric: false,
      label: "Qtd.Atender",
      align: "left",
      minWidth: 150,
    },

  ];



  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheck = (event, name) => {
    if (props.mostraCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      if (props.setSelected)
        props.setSelected(newSelected)
    }
  };

  useEffect(() => {
    setData(props.data);

    setPage(0);
    if (props.setSelected)
      setSelected([])
  }, [props.data]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  function lotes(e, nomeMdc, pk_est, pendente, pk_rei) {
    const auxData = props.dataEsl.filter(value => { return (value.fk_est == pk_est) })
    setpk_rei(pk_rei)
    setDataEsl(auxData);
    setNomeMdc(nomeMdc)
    setPendente(pendente)
    setShowModal(true)
  }

  function escondeModal() {
    setShowModal(false)
  }

  function atualizaQtdAtender() {
    var i

    for (i = 0; i < dataEsl.length; i++) {
      if (dataEsl[i].qtd_atender > 0) {
        let index = props.dataAtender.map(e => e.pk_esl).indexOf(dataEsl[i].pk_esl);
        if (index >= 0)
          delete props.dataAtender[index]
        index = props.dataAtender.map(e => e.fk_est).indexOf(dataEsl[i].fk_est);
        if (index >= 0)
          delete props.dataAtender[index]

        props.dataAtender.push({
          pk_esl: dataEsl[i].pk_esl,
          lote: dataEsl[i].lote,
          validade: dataEsl[i].validade,
          saldo: dataEsl[i].saldo,
          qtd_atender: dataEsl[i].qtd_atender,
          fk_est: dataEsl[i].fk_est,
          pk_rei: pk_rei
        })
      }
    }
  }

  function confirmaModal() {
    let total = 0
    var i
    for (i = 0; i < dataEsl.length; i++) {
      total += parseInt(dataEsl[i].qtd_atender)
    }

    if (total > pendente)
      swal.fire({
        customClass: {
          container: 'my-swal'
        },

        title: 'Erro!',
        text: "Soma das quantidades informadas -> " + total + " é superior à quantidade pendente -> " + pendente + " !",
        icon: 'warning',
      })
    else {
      // alert(pk_rei)
      atualizaQtdAtender()
      setShowModal(false)
    }
  }

  const handleChange = (e) => {
    let item = []
    var i;
    for (i = 0; i < data.length; i++) {
      if (data[i].pk_esl == e.target.name) {

        item.push({
          pk_esl: data[i].pk_esl,
          lote: data[i].lote,
          validade: data[i].validade,
          saldo: data[i].saldo,
          qtd_atender: e.target.value,
          fk_est: data[i].fk_est,

        })
      } else
        item.push({
          pk_esl: data[i].pk_esl,
          lote: data[i].lote,
          validade: data[i].validade,
          saldo: data[i].saldo,
          qtd_atender: data[i].qtd_atender,
          fk_est: data[i].fk_est
        })
    }
    setData(item)
    props.setData(item)
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <ThemeProvider theme={theme}>
      <div >
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >


          <div style={{ padding: '5px' }}>

            <label style={{ marginLeft: '10px', fontWeight: 'bold' }}>{nomeMdc}</label>

            <TableRequisicoes regName='itens' columns={columnsEsl} data={dataEsl} setData={setDataEsl} codeName={'pk_esl'} dataAtender={props.dataAtender} />

          </div>

          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv" >
              <button style={{ height: '40px' }} onClick={confirmaModal}> Confirma </button>
              <button style={{ height: '40px' }} onClick={escondeModal}> Cancela </button>

            </div>
          </div>
        </Dialog>
      </div>

      {data.length === 0 ? (
        <div>{props.searched ? props.emptyMessage : ""}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={props.regName == 'itens' ? classes.containerLotes : classes.container}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >

              <TableHead>
                <StyledTableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row[props.codeName]);

                    return (
                      <>
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row[props.codeName]}
                          aria-checked={isItemSelected} role="checkbox" selected={isItemSelected}
                        >

                          {columns.map((column) => {
                            const value = row[column.id];

                            if ((column.id == 'qtd_atender') && (props.regName !== 'itens')) {
                              return (
                                <TableCell style={{ padding: '1px' }} key={column.id} align={column.align}>
                                  <Icon hidden={row['saldo_estoque'] == 0} className="tableIcon" icon={ic_fact_check_outline} size={18} onClick={(e) => lotes(e, row['nomemdc'], row['pk_est'], row['pendente_nova'], row['pk_rei'])} />
                                  {row['saldo_estoque'] == 0 ? '' : 'Lotes'}

                                </TableCell>
                              )
                            }
                            if ((column.id == 'qtd_atender') && (props.regName == 'itens')) {
                              return (
                                <TableCell style={{ padding: '1px' }} key={column.id} align={column.align}>
                                  <Input
                                    value={value}
                                    name={row.pk_esl}
                                    onChange={handleChange}
                                    style={{ width: '20px', align: 'center' }}
                                  />
                                </TableCell>)
                            } else
                              return (
                                <TableCell style={{ padding: '1px' }} key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                          })}
                        </StyledTableRow>
                        {((props.regName !== 'itens') && (props.dataAtender.filter(value => { return (value.pk_rei == row['pk_rei']) }).length > 0)) ?
                          <TableRow>


                            {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}> */}

                            {props.dataAtender.filter(value => { return (value.pk_rei == row['pk_rei']) }).map((row) => {

                              return (
                                <div style={{ fontSize: 10 }}>
                                  <strong style={{ marginRight: '10px', marginLeft: '20px' }}>Atendimentos</strong>
                                  <label style={{ marginRight: '10px' }}>{row.lote}</label>
                                  <label style={{ marginRight: '10px' }}>{row.validade}</label>
                                  <label style={{ marginRight: '10px' }}>{row.qtd_atender}</label>
                                </div>
                              )

                            })}
                            {/* </TableCell> */}



                          </TableRow> : <></>

                        }
                      </>

                    );


                  })


                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
