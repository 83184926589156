import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import api from '../services/api'
import swal from 'sweetalert2'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import $ from 'jquery'
import { getUserCode, asyncForEach } from '../utils/functions';
import ReactLoading from 'react-loading';


const swal2 = withReactContent(Swal)

function ImportacaoSimpro() {

  const [data, setData] = useState({})
  const [texto, setTexto] = useState('')

  function consistData(data) {
    let required = [
      'arquivo'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  const selectFile = (e) => {
    e.preventDefault();
    $('#html_btn').click();
  }


  const handleSelectedFile = (e) => {
    let file = e.target.files[0];
    console.log(file)
    let auxValues = { ...data }
    if (file) {
      auxValues.arq = file
      auxValues.arquivo = file.name
    } else {
      auxValues.arq = null
      auxValues.arquivo = 'Nenhum arquivo selecionado'
    }
    setData(auxValues)
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal2.fire({
      title: 'ATENÇÃO',
      text: "Confirma importação do arquivo?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.fk_usu = getUserCode()

        if (errors.length === 0) {
          let formData = new FormData();
          try {
            formData.append('file', auxValues.arq, auxValues.arquivo)
          } catch {

          }


          api.post('/medicamentos/linhasArquivo', formData).then(async r => {
            let linhas = r.data
            asyncForEach(linhas, async (item, index) => {
              auxValues.linha = item
              auxValues.fk_usu = getUserCode()
              await api.post('/medicamentos/importaLinha', auxValues)
                .then(async (r) => {
                  setTexto('Lendo arquivo linhas (' + (index + 1) + '/' + linhas.length + ')')
                })

              if (index + 1 === linhas.length) {
                await api.post('/medicamentos/importacao?pk_usu=' + getUserCode()).then(r => {
                  swal2.fire('Arquivo importado', 'Arquivo importado com sucesso!', 'success');
                  setTexto('')
                }
                )


              }
            })
          })

        } else {
          swal2.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal2.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    auxValues[e.target.name] = e.target.value;
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Importação Simpro</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <input
            type="file"
            name="arquivo"
            id="html_btn"
            accept=".txt"
            onChange={handleSelectedFile}
          />
          <FrTextInput
            value={data.arquivo || ''}
            onChange={handleChange}
            name="arquivo"
            id="arquivo"
            color="#528b46"
            label="Tabela Simpro"
            variant="outlined"
            size="small"
            disabled
            required
            style={{ width: 300 }}
          />
          <button type="file" className="selectFileButton" onClick={selectFile}>
            Escolher Arquivo
          </button>

          <br />
          <br />
          {texto ?
            <FrLoading text={texto} />
            : <></>}
          <br />
        </form>
        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Importar</button>
          <button onClick={handleCancel}>Fechar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ImportacaoSimpro;
