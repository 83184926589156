import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import RegFornecedores from './RegFornecedores'
import Dialog from '@material-ui/core/Dialog';
import FrTableReg from '../components/FrTableReg'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Fornecedores(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [showModal, setShowModal] = useState(false)
  const [pk_cad, setpk_Cad] = useState('')

  const columns = [
    { id: 'razao_social', numeric: false, label: 'Razão Social', align: 'left', minWidth: 250 },
    { id: 'fantasia', numeric: false, label: 'Nome Fantasia', align: 'left', minWidth: 200 },
    { id: 'endereco', numeric: false, label: 'Endereço', align: 'left', minWidth: 150 },
    { id: 'nomecid', numeric: false, label: 'Cidade', align: 'left', minWidth: 100 },
    { id: 'uf', numeric: false, label: 'UF', align: 'left', minWidth: 50 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Fornecedores/Funcionários'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        if (!props.modal)
          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        setComboTipo([
          {
            "value": " and tipo_for='S'",
            "display": "Fornecedor",
          },
          {
            "value": " and tipo_fun='S'",
            "display": "Funcionário",
          },])

        setComboCid(await api.getComboData('cidades'))


        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        if (!props.modal) swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    setFiltered(true)
    if (!props.modal) {
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/fornecedores?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    api.get('/fornecedores', { params: queryObject }).then(r => {
      setData(r.data)
      if (!props.modal) swal.close()
    })

  }

  function clearForm(e) {
    e.preventDefault();
    if (!props.modal)
      window.history.replaceState({ filtered: false }, 'filter', '/primavera/fornecedores');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  function mostraModal(e) {
    e.preventDefault();
    setShowModal(true)
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {!props.modal ? <NavBar /> : <></>}
      <div className={props.modal ? "ManutContentModal" : "ManutContent"}>
        <div className="defaultHeader">
          <h3>Fornecedores / Funcionários</h3>
          {!props.modal ? <UserOptions /> : <></>}
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.razao_social || ''}
                style={{ width: 330 }}
                name="razao_social"
                id="razao_social"
                color="#528b46"
                label="Razão Social"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrTextInput
                value={filter.fantasia || ''}
                style={{ width: 230 }}
                name="fantasia"
                id="fantasia"
                color="#528b46"
                label="Nome Fantasia"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                value={filter.tipo || ''}
                style={{ width: 150 }}
                name="tipo"
                id="tipo"
                label='Tipo'
                data={comboTipo}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.pk_cid || ''}
                style={{ width: 200 }}
                name="pk_cid"
                id="pk_cid"
                label='Cidade'
                data={comboCid}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' || props.modal ?
                <button onClick={mostraModal}>
                  <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>

                :
                <Link to="/primavera/fornecedores/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}


            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          {props.modal ? <FrTableReg setShowModal={setShowModal} setpk_Cad={setpk_Cad} editar={true} f2={true} cad={props.cad} setCad={props.setCad} regName="fornecedores" columns={columns} searched={filtered} codeName="pk_cad" page={'fornecedores'} data={data} enableEdit={enableEdit} enableDel={enableDel} /> : <FrTable regName="fornecedores" columns={columns} searched={filtered} codeName="pk_cad" page={'fornecedores'} data={data} enableEdit={enableEdit} enableDel={enableDel} />}
        </div>

        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>

          <RegFornecedores pk_cad={pk_cad} editar={true} setShowModal={setShowModal} modal={true}></RegFornecedores>
        </Dialog>

        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Fornecedores;
