import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { isMobile } from 'react-device-detect';
import api from '../services/api'
import FrSelect from '../components/FrSelect'

import './styles/Login.css';

import logo from '../imgs/logo.png';

const swal = withReactContent(Swal)

function Login() {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
    const [residente, setResidente] = useState('')
    const [usuario, setUsuario] = useState('')
    const [blockResidente, setBlockResidente] = useState(false)
    const [visibleResidente, setVisibleResidente] = useState(false)

    // useEffect(() => {
    //     if (auth.authenticated) {
    //         if (isMobile)
    //             window.location.href = '/primavera/lancaEvolucao'
    //         else window.location.href = '/primavera/home'
    //     }
    // }, [auth])


    useEffect(() => {
        const fetchClientes = async () => {
            if (usuario !== '') {
                let pacientes = await api.getComboData('pacientes', usuario)
                setComboPac(pacientes)
                if (pacientes.length == 1) {
                    setResidente(pacientes[0].value)
                    setBlockResidente(true)
                    setVisibleResidente(true)

                } else {
                    setResidente('')
                    setBlockResidente(false)
                    if (pacientes.length == 0)
                        setVisibleResidente(false)
                    else {
                        setVisibleResidente(true)
                    }
                }
            }
        }

        fetchClientes()
    }, [usuario])


    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            console.log(value, key)
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/login', object)
            .then(async function (response) {
                let { pk_usu, nome, ultimo_login, fk_gpu } = response.data[0];
                if (pk_usu && pk_usu > 0) {
                    if ((fk_gpu == 4) && (!residente)) {//familiar obrigatório informar residente
                        swal.fire('Resdiente não informado!', 'Informe o residente.', 'warning')
                        return
                    }
                    api.defaults.headers.common['token'] = response.data.token;
                    localStorage['authPrimavera'] = JSON.stringify({ authenticated: true, user: pk_usu, name: nome, ultimo_login, last_activity: new Date(), residente: residente, access_token: response.data.token })
                    if ((isMobile) && (fk_gpu !== 4) && (fk_gpu !== 1))//não é familiar nem administrador
                        window.location.href = '/primavera/lancaEvolucao'
                    else
                        window.location.href = '/primavera/home'
                } else {
                    swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
                }
            })
            .catch(function (error) {
                swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            });
    }


    const handleChange = (e) => {
        if (e.target.name == 'login') {
            setUsuario(e.target.value)
        }
        if (e.target.name == 'fk_pac')
            setResidente(e.target.value)

    };

    return (
        <div className="Login">
            <div className="form">
                <img alt="fr_logo" src={logo} title="Primavera" className="logo" />
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário" value={usuario} onChange={handleChange} />
                        <label htmlFor="login">Login</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" id="senha" name="senha" className="loginInput" placeholder="Senha" />
                        <label htmlFor="senha">Senha</label>
                    </div>
                    <br />

                    <div className="form-label-group">

                        <FrSelect
                            style={{
                                width: 206, border: '3px solid #000000',
                            }}
                            name="fk_pac"
                            id="fk_pac"
                            label='Residente'
                            data={comboPac}
                            value={residente}
                            onChange={handleChange}
                            disabled={blockResidente}
                            hidden={!visibleResidente}
                        />
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Entrar</p></span>
                    </button>
                    <div >
                        <a href='/primavera/forgotPw' style={{ color: 'white' }}>Esqueci minha senha</a>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Login;
