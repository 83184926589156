import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import FrSelect from "../components/FrSelect";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";

import api from "../services/api";
import { getUserCode, paramsToObject, onBlurDecimal } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

const swal = withReactContent(Swal);

function Tabela_Precos() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [comboUni, setComboUni] = useState([{ value: "", display: "" }]);
  const [comboMdc, setComboMdc] = useState([{ value: "", display: "" }]);
  const [comboMat, setComboMat] = useState([{ value: "", display: "" }]);
  const [enableEdit, setEnableEdit] = useState("N");

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos("Tabela de Preços", "Alteracao"));
      } else {
        setEnableEdit(await api.getAcessos("Tabela de Preços", "Inclusao"));
      }

      setComboUni(await api.getComboData("unidades_medida"));
      setComboMat(await api.getComboData("materiais"));
      setComboMdc(await api.getComboData("medicamentos"));

      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/tabela_precos?pk_tab=" + query.codigo).then((r) => {
          if (r.data[0]) {
            if (r.data[0].preco) r.data[0].preco = r.data[0].preco.toFixed(2);
            if (r.data[0].custo) r.data[0].custo = r.data[0].custo.toFixed(2);
            if (r.data[0].margem)
              r.data[0].margem = r.data[0].margem.toFixed(2);

            console.log(r.data[0]);
            setData(r.data[0]);
            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then((result) => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else swal.close();
    };

    fetchData();
  }, []);

  function consistData(data) {
    let required = ["fk_uni", "preco"];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_uni") fieldName = "Unidade de Medida";

      if (!data[curr]) return errors.push(fieldName);

      if (!data["fk_mat"] && !data["fk_mdc"])
        return errors.push("Material ou Medicamento");
      if (data["fk_mat"] && data["fk_mdc"])
        return errors.push("Material ou Medicamento");
    });

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Deseja salvar " +
          (updateType ? "as alterações" : "a inclusão") +
          " do registro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          let errors = consistData(auxValues);
          auxValues.user = getUserCode();
          if (errors.length === 0) {
            if (updateType) {
              api
                .post("/tabela_precos/edit", auxValues)
                .then((r) => {
                  if (r.status === 200) {
                    swal
                      .fire({
                        title: "Registro Alterado",
                        text: "O registro foi alterado com sucesso!",
                        icon: "success",
                      })
                      .then((result) => {
                        if (result.value) {
                          window.history.back();
                        }
                      });
                  } else {
                    swal.fire({
                      title: "Erro!",
                      text: "O registro não foi alterado!",
                      icon: "warning",
                    });
                  }
                })
                .catch((error) => {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi alterado! " + error,
                    icon: "warning",
                  });
                });
            } else {
              api
                .post("/tabela_precos/add", auxValues)
                .then((r) => {
                  if (r.status === 200) {
                    swal
                      .fire({
                        title: "Registro Adicionado",
                        text: "O registro foi adicionado com sucesso!",
                        icon: "success",
                      })
                      .then((result) => {
                        if (result.value) {
                          window.history.back();
                        }
                      });
                  } else {
                    swal.fire({
                      title: "Erro!",
                      text: "O registro não foi adicionado!",
                      icon: "warning",
                    });
                  }
                })
                .catch((error) => {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi adicionado! " + error,
                    icon: "warning",
                  });
                });
            }
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning",
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case "custo":
      case "margem":
        auxValues[e.target.name] = e.target.value;
        auxValues.preco =
          Number(auxValues.custo || 0) *
          (1 + Number(auxValues.margem || 0) / 100);
        auxValues.preco = auxValues.preco.toFixed(2);
        break;
      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>
            {" "}
            {updateType ? "Edição" : "Inclusão"}
            de Tabela de Preço{" "}
          </h3>{" "}
          <UserOptions />
        </div>{" "}
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <FrSelect
            value={data.fk_mat || ""}
            onChange={handleChange}
            name="fk_mat"
            id="fk_mat"
            label="Material"
            data={comboMat}
            style={{ width: 300 }}
          />{" "}
          <FrSelect
            value={data.fk_mdc || ""}
            onChange={handleChange}
            name="fk_mdc"
            id="fk_mdc"
            label="Medicamento"
            data={comboMdc}
            style={{ width: 300 }}
          />{" "}
          <br />
          <FrSelect
            value={data.fk_uni || ""}
            onChange={handleChange}
            name="fk_uni"
            id="fk_uni"
            label="Unidade de Medida"
            data={comboUni}
            style={{ width: 150 }}
          />{" "}
          <FrTextInput
            maxLength="10"
            value={data.custo || ""}
            onChange={handleChange}
            name="custo"
            id="custo"
            color="#528b46"
            label="Custo"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
            // onBlur={(e) => {
            //   onBlurDecimal(e, 2, handleChange);
            // }}
          />{" "}
          <FrTextInput
            maxLength="10"
            value={data.margem || ""}
            onChange={handleChange}
            name="margem"
            id="margem"
            color="#528b46"
            label="Margem"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
            // onBlur={(e) => {
            //   onBlurDecimal(e, 2, handleChange);
            // }}
          />{" "}
          <FrTextInput
            maxLength="10"
            value={data.preco || ""}
            onChange={handleChange}
            name="preco"
            id="preco"
            color="#528b46"
            label="Preço"
            variant="outlined"
            size="small"
            // onBlur={(e) => {
            //   onBlurDecimal(e, 2, handleChange);
            // }}
            required
            style={{ width: 130 }}
          />{" "}
        </form>{" "}
        <div className="regButtonsDiv">
          {" "}
          {enableEdit === "S" ? (
            <button onClick={handleSubmit}> Salvar </button>
          ) : (
            <> </>
          )}{" "}
          <button onClick={handleCancel}>
            {" "}
            {enableEdit === "S" ? "Cancelar" : "Fechar"}{" "}
          </button>{" "}
        </div>{" "}
        <FooterDelphus />
      </div>{" "}
    </div>
  );
}

export default Tabela_Precos;
