import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FAFAFA',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '1px solid #528b46',
    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '16px',
    paddingLeft: '10px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#528b46',
      borderWidth: '2px',
      backgroundColor: '#FAFAFA',
      height: '12px'
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },
  marginApp: {
    marginBottom: '10px',
    flex: 1
  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#FAFAFA',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '5px',
      top: '0',
      color: '#528b46'
    },
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  return (
    <>
      <FormControl className={(props.app ? classes.marginApp : classes.margin)} hidden={props.hidden}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput />}
          {...props}
        >
          <option aria-label="Nenhum" value="" />
          {
            props.data.map((item) => {
              return (<option value={item.value}>{item.display}</option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}