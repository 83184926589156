import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'
import FrameF2 from '../components/FrameF2'
import api from '../services/api'
import { dateSql, paramsToObject, getUserCode } from '../utils/functions'
import { mMoeda } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Contas_Pagar() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCad, setComboCad] = useState([{ value: '', display: '' }])
  const [comboSub, setComboSub] = useState([{ value: '', display: '' }])
  const [dataTsu, setDataTsu] = useState([{ value: '', display: '' }])
  const [cad, setCad] = useState('')

  const columns = [
    { id: 'display', numeric: false, label: 'SubClasse', align: 'left', minWidth: 200 },
    { id: 'valor', numeric: true, label: 'Valor', align: 'left', minWidth: 80 },
    // { id: 'value', numeric: true, label: 'PK', align: 'left', minWidth: 80 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboCad(await api.getComboData('fornecedores'))
      setComboSub(await api.getComboData('subclasses'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Contas a Pagar', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Contas a Pagar', 'Inclusao'))
      }


      if (query.codigo > 0) {
        setUpdateType(true)
        await api.get('/contas_pagar/tit_sub?pk_tit=' + query.codigo).then(r => {
          if (r.data.length > 0) {
            setDataTsu(r.data)
          }

        })

        api.get('/contas_pagar?pk_tit=' + query.codigo).then(async r => {
          if (r.data[0]) {
            r.data[0].valor = mMoeda(Number(r.data[0].valor).toFixed(2))
            r.data[0].valor_liquidado = mMoeda(Number(r.data[0].valor_liquidado).toFixed(2))
            r.data[0].descontos = mMoeda(Number(r.data[0].descontos).toFixed(2))
            r.data[0].valor_previsao = mMoeda(Number(r.data[0].valor_previsao).toFixed(2))


            setData(r.data[0])
            if (r.data[0].valor_liquidado > 0)
              setEnableEdit(false);

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  function incluiTsu(e) {
    e.preventDefault();
    const auxValues = { ...data };
    let pk_tsu = 1

    if (dataTsu[0].display === '') {
      setDataTsu([{
        value: auxValues['fk_sub'],
        display: auxValues['nomesub'],
        valor: auxValues['valor_tsu'],
        pk: pk_tsu
      }])
      pk_tsu++
    } else {
      pk_tsu++
      let item = [{
        value: dataTsu[0].value,
        display: dataTsu[0].display,
        valor: dataTsu[0].valor,
        pk: pk_tsu
      }]
      var i;
      for (i = 1; i < dataTsu.length; i++) {
        pk_tsu++
        item.push({
          value: dataTsu[i].value,
          display: dataTsu[i].display,
          valor: dataTsu[i].valor,
          pk: pk_tsu

        })
      }
      pk_tsu++
      item.push({
        value: auxValues['fk_sub'],
        display: auxValues['nomesub'],
        valor: auxValues['valor_tsu'],
        pk: pk_tsu

      })
      setDataTsu(item)

      // let total = 0;
      // for (i = 0; i < item.length; i++) {
      //   total = total + parseFloat(item[i].valor)
      // }
      // console.log(total)
      // auxValues['valor_total'] = Number(total).toFixed(2)
      // setData(auxValues)

    }

  }

  function consistData(data) {
    let required = [
      'data_vencimento',
      'fk_cad',
      'data_emissao',
      'numdoc',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_cad") fieldName = "Fornecedor / Funcionário";
      if (curr === "data_emissao") fieldName = "Data de Emissão";
      if (curr === "data_vencimento") fieldName = "Data de Vencimento";
      if (curr === "numdoc") fieldName = "Nº Documento";


      if (!data[curr]) return errors.push(fieldName)
    })


    if ((data['valor'] == 0) && (data['valor_previsao'] == 0))
      errors.push('Valor ou Valor Previsão')

    if (dataTsu[0].display == '')
      errors.push('Rateios por SubClasses')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues['data_emissao'] = dateSql(auxValues['data_emissao'])
        auxValues['data_vencimento'] = dateSql(auxValues['data_vencimento'])
        auxValues['fk_tsu'] = dataTsu;
        auxValues['fk_usu'] = getUserCode()
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/contas_pagar/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/contas_pagar/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_sub':
        if (e.target.value > 0) {
          api.get('/subclasses?pk_cls=' + e.target.value).then(r => {
            auxValues['nomesub'] = r.data[0].descricao;
          })
        }
        auxValues[e.target.name] = e.target.value;
        break

      case 'valor':
      case 'valor_previsao':
      case 'descontos':
      case 'valor_liquidado':
      case 'valor_tsu':
        auxValues[e.target.name] = mMoeda(e.target.value);
        break;

      case 'fk_cad':
      case 'cad': {
        auxValues['fk_cad'] = e.target.value;
        setCad(e.target.value)
        break
      }

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };



  useEffect(() => {
    const fetchCad = async () => {

      setComboCad(await api.getComboData('fornecedores'))

      const auxValues = { ...data };

      if (cad > 0) {
        auxValues['fk_cad'] = cad;

        setData(auxValues);
      }
    }
    fetchCad()
  }, [cad])



  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Conta a Pagar</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='15'
            value={data.numdoc || ''}
            onChange={handleChange}
            name='numdoc'
            id='numdoc'
            color='#c0c0c0'
            label='Nº Documento'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='3'
            value={data.parcela || ''}
            onChange={handleChange}
            name='parcela'
            id='parcela'
            color='#c0c0c0'
            label='Parcela'
            variant='outlined'
            size='small'
            style={{ width: 80 }}
          />

          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrSelect
                  value={data.fk_cad || ''}
                  onChange={handleChange}
                  name="fk_cad"
                  id="fk_cad"
                  label='Fornecedor / Funcionário'
                  data={comboCad}
                  style={{ width: 350 }}
                />
              </div>
              <div class="column" >
                <FrameF2 tipoModal={'Fornecedor'} data={data} cad={cad} setCad={setCad} ></FrameF2>
              </div>
            </div>
          </div>


          <br />


          <FrDatePicker
            value={(data.data_emissao || "").split("T")[0]}
            onChange={handleChange}
            name="data_emissao"
            id="data_emissao"
            color="#528b46"
            label="Data Emissão"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />

          <FrDatePicker
            value={(data.data_vencimento || "").split("T")[0]}
            onChange={handleChange}
            name="data_vencimento"
            id="data_vencimento"
            color="#528b46"
            label="Data Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />

          <FrDatePicker
            value={(data.data_original || "").split("T")[0]}
            onChange={handleChange}
            name="data_original"
            id="data_original"
            color="#528b46"
            label="Data Vencimento Original"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            disabled
          />
          <br />
          <FrTextInput
            maxLength='150'
            value={data.valor || ''}
            onChange={handleChange}
            name='valor'
            id='valor'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_previsao || ''}
            onChange={handleChange}
            name='valor_previsao'
            id='valor_previsao'
            color='#c0c0c0'
            label='Valor Previsão'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.descontos || ''}
            onChange={handleChange}
            name='descontos'
            id='descontos'
            color='#c0c0c0'
            label='Valor Desconto'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrDatePicker
            value={(data.liquidacao || "").split("T")[0]}
            onChange={handleChange}
            name="data_liquidacao"
            id="data_liquidacao"
            color="#528b46"
            label="Data Liquidação"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 190 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_liquidado || ''}
            onChange={handleChange}
            name='valor_liquidado'
            id='valor_liquidado'
            color='#c0c0c0'
            label='Valor Liquidado'
            variant='outlined'
            size='small'
            disabled
            style={{ width: 120 }}
          />

          <br />
          <FrTextInput
            maxLength='500'
            value={data.observacao || ''}
            onChange={handleChange}
            name="observacao"
            id="observacao"
            color="#528b46"
            label="Observações"
            variant="filled"
            size="small"
            style={{ width: 530 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />

          <hr width="100%" size="50" color=" #528b46" />
          <br />


          <FrSelect
            value={data.fk_sub || ''}
            onChange={handleChange}
            name="fk_sub"
            id="fk_sub"
            label='SubClasse'
            data={comboSub}
            style={{ width: 450 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_tsu || ''}
            onChange={handleChange}
            name='valor_tsu'
            id='valor_tsu'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <button className="incluiFileButton" onClick={incluiTsu}> {'Incluir'} </button>
          <br />

          <div className="tableDiv">
            <FrTableReg setDataGrid={setDataTsu} detail regName="" columns={columns} searched={false} codeName="pk_tsu" page={''} data={dataTsu} />
          </div>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Pagar;
