import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import TableDetail from '../TableDetail'
import { ic_local_printshop } from 'react-icons-kit/md/ic_local_printshop'
import { checkCircle } from 'react-icons-kit/fa/checkCircle'
import { retweet } from 'react-icons-kit/fa/retweet'
import api from '../../services/api'
import { time } from 'react-icons-kit/ikons/time'
import { Modal } from 'react-bootstrap'
import FrTextInput from '../FrTextInput'
import FrDatePicker from '../FrDatePicker';
import FrInventarioDetail from '../FrInventarioDetail';
import { userCheck } from 'react-icons-kit/icomoon/userCheck'
import { ic_picture_as_pdf } from 'react-icons-kit/md/ic_picture_as_pdf'
import { ic_fingerprint } from 'react-icons-kit/md/ic_fingerprint'
import { ic_visibility } from 'react-icons-kit/md/ic_visibility'
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import { getUserCode } from '../../utils/functions';
import { ic_done } from 'react-icons-kit/md/ic_done'
import { isMobile } from 'react-device-detect';
import Dialog from '@material-ui/core/Dialog';
import { slash } from 'react-icons-kit/feather/slash'
import { undo2 } from 'react-icons-kit/icomoon/undo2'
import { alarm } from 'react-icons-kit/icomoon/alarm'
import { mMoeda, mCPF } from '../../utils/masks';
import { mail } from 'react-icons-kit/feather/mail'
import { deleteIconic } from 'react-icons-kit/typicons/deleteIconic'
import { ic_fact_check_outline } from 'react-icons-kit/md/ic_fact_check_outline'

const moment = require("moment");

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#cfffcf'

    },
    "& .Mui-selected:hover": {
      backgroundColor: '#cfffcf'

    },
    width: '100%',
  },
  container: {
    borderRadius: '4px',
    maxHeight: '58vh',
    minHeight: '58vh'
  },

  containerF2: {
    borderRadius: '4px',
    maxHeight: '40vh',
    minHeight: '40vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#528b46',
    color: theme.palette.common.white,
    padding: '2px'
  },
  body: {
    fontSize: 14,
    padding: '2px'
  },
}))(TableCell);



export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = React.useState(props.startOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.startOrderBy || props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [numdoc, setNumdoc] = React.useState('');
  const [parcela, setParcela] = React.useState('');
  const [vcto, setVcto] = React.useState('');
  const [valor, setValor] = React.useState('');
  const [obs, setObs] = React.useState('');
  const [pk, setPk] = React.useState('');
  const [widthOptions, setwidthOptions] = React.useState(props.widthOptions || 100);
  const [dataPre, setDataPre] = React.useState('')
  const [dataAdm, setDataAdm] = React.useState(moment().format('YYYY-MM-DDTHH:mm'))
  const [showModalAdm, setShowModalAdm] = React.useState(false);
  const [pk_adm, setPk_Adm] = React.useState('');
  const [showModalEmail, setShowModalEmail] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [idNota, setIdNota] = React.useState('');


  useEffect(() => {
    setData(props.data)
    setPage(0)
    if (props.selected)
      setSelected(props.selected)
    if (props.widthOptions > 0)
      setwidthOptions(props.widthOptions)
  }, [props.data])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])


  function handleModal(e, row) {
    e.preventDefault()

    setNumdoc(row['numdoc'])
    setParcela(row['parcela'])
    setVcto(row['vencimento'])
    setValor(row['format_valor'])
    setObs(row['observacao'])
    setPk(row['pk'])

    setShowModal(true)
  }

  function confirmaModal(e) {
    e.preventDefault()
    var novo = [];
    for (var i = 0; i < data.length; i++)

      if (data[i].pk == pk) {
        novo.push({
          numdoc: numdoc,
          parcela: parcela,
          format_valor: valor,
          format_data_vencimento: moment(vcto).format("DD/MM/YYYY"),
          vencimento: vcto,
          observacao: obs,
          pk: pk
        })
      } else {
        novo.push(data[i])
      }

    props.setDataProg(novo)
    setShowModal(false)
  }

  async function confirmaAdm(e) {
    e.preventDefault()
    setShowModalAdm(false)

    const { value: formValues } = await swal.fire({
      title: 'Administração de Medicamento',
      html:

        <div style={{
          display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column',
          alignItems: 'flex-start'
        }}>

          <FrTextInput
            maxLength='300'
            name='observacoes'
            id='observacoes'
            color='#528b46'
            label='Observações'
            variant="filled"
            size='small'
            required
            // style={{ width: 500 }}
            multiline
            rows="5"
            rowsMax="5"
            fullWidth
          />


        </div>,
      focusConfirm: false,
      preConfirm: () => {
        return {
          obs: document.getElementById('observacoes').value,
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'OK',
      confirmButtonColor: `#528b46`,
      allowOutsideClick: false
    })

    if (formValues) {
      await api.post('/administracoes/administrar?pk_adm=' + pk_adm +
        '&data=' + dataAdm + '&obs=' + formValues['obs'] + '&user=' + getUserCode()).then(r => {
          setShowModalAdm(false)

          swal.fire({
            text: "Administração do medicamento gravada com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              let event = new Event('build');
              props.getData(event)
              // window.location.reload()
            }
          })
        })
    }


    if (!dataAdm) {
      swal.fire({
        title: 'Erro!',
        text: "Informe a data/hora!",
        icon: 'warning',
      })
    }

  }


  async function estornar(e, pk_icb, fk_mit) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma estornar o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {

      if (result.value) {

        await api.post('/itens_cobranca/estornar?pk_icb=' + pk_icb +
          '&fk_mit=' + fk_mit + '&fk_usu=' + getUserCode()).then(r => {
            if ((r.status === 200)) {
              swal.fire({
                title: 'Registro Estornado',
                text: "O registro foi estornado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.location.reload()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi estornado! ",
                icon: 'warning',
              })
            }
          })
      }
    })
  }




  async function confirmaNaoAdm(e) {
    e.preventDefault()
    setShowModalAdm(false)

    const { value: formValues } = await swal.fire({
      title: 'Não Administração de Medicamento',
      html:

        <div style={{
          display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column',
          alignItems: 'flex-start'
        }}>

          <FrTextInput
            maxLength='300'
            name='observacoes'
            id='observacoes'
            color='#528b46'
            label='Observações'
            variant="filled"
            size='small'
            required
            // style={{ width: 500 }}
            multiline
            rows="5"
            rowsMax="5"
            fullWidth
          />


        </div>,
      focusConfirm: false,
      preConfirm: () => {
        return {
          obs: document.getElementById('observacoes').value,
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'OK',
      confirmButtonColor: `#528b46`,
      allowOutsideClick: false
    })

    if ((formValues) && (formValues['obs'])) {

      await api.post('/administracoes/naoadministrar?pk_adm=' + pk_adm + '&obs=' + formValues['obs'] +
        '&user=' + getUserCode()).then(r => {
          swal.fire({
            text: "Não Administração do medicamento gravada com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              let event = new Event('build');
              props.getData(event)
              // window.location.reload()
            }
          })
        })
    } else
      if (formValues) {
        swal.fire({
          title: 'Erro!',
          text: "Observação deve ser preenchida!",
          icon: 'warning',
        })
      }
  }


  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
    setShowModalAdm(false)
    setShowModalEmail(false)

  }

  function imprimeData(e, id, tipo) {
    e.preventDefault()
    window.open(api.backendUrl + '/api/prescricao_medica/print?pk_pmi=' + id + '&pk_usu=' + getUserCode() + '&tipo=' + tipo, '_blank');
  }

  function pedeimprimeData(e, id) {
    e.preventDefault()


    swal.fire({
      title: 'Tipo de Impressão',
      text: "Escolha o tipo de impressão:",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#528b46',
      cancelButtonColor: '#528b46',
      confirmButtonText: 'REIMPRESSÃO',
      cancelButtonText: 'RENOVAÇÃO'
    }).then((result) => {
      if (result.value)
        imprimeData(e, id, 'S')
      else
        imprimeData(e, id, 'R')
    })
  }

  function handleDelete(e, id, table) {
    e.preventDefault()
    let comando = '/' + table + '/delete/' + id
    if ((props.administrar) || (props.regName == 'pacientes') || (props.regName == 'contas_receber') || (props.regName == 'plano_hospedagem'))
      comando = '/' + table + '/delete?pk=' + id + '&usu=' + getUserCode()

    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {

        api.post(comando).then(r => {
          console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function autorizarNF(e, id) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma autorizar a nota?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {

        api.post('/nota_fiscal/autorizar?pk_ntf=' + id).then(r => {
          console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Nota autorizada',
              text: "Nota autorizada com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Nota não foi autorizada! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function imprimirNF(e, id) {
    e.preventDefault();
    api.get('/nota_fiscal/imprimir?id=' + id).then(async r => {
      window.open(api.backendUrl + '/static/' + id + '.pdf', '_blank');
    })
  }

  function enviarEmail(e, id) {
    e.preventDefault();
    api.get('/tomador?id_nota=' + id).then(async r => {
      setEmail(r.data[0].email)
      setIdNota(id)
      setShowModalEmail(true)
    })
  }

  function confirmaEmail(e) {
    e.preventDefault();
    if (!email) {
      swal.fire({
        customClass: {
          container: 'my-swal'
        },

        title: 'Erro!',
        text: "Informe o e-mail!",
        icon: 'warning',
      })
    } else {
      swal.fire({
        customClass: {
          container: 'my-swal'
        },

        title: 'ATENÇÃO',
        text: "Confirma enviar nota fiscal para o e-mail " + email + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {

          api.post('/nota_fiscal/email?id=' + idNota + '&email=' + email).then(async r => {
            swal.fire({
              customClass: {
                container: 'my-swal'
              },

              title: 'E-mail enviado',
              text: "E-mail enviado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                setShowModalEmail(false)
                window.location.reload()
              }
            })
          })
        }
      })
    }
  }


  function cancelarNota(e, id) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Confirma cancelar a nota fiscal?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {

        api.post('/nota_fiscal/cancelar?id=' + id).then(async r => {
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {

            swal.fire({
              customClass: {
                container: 'my-swal'
              },

              title: 'Nota fiscal cancelada',
              text: "Nota fiscal cancelada com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {

            api.post('/nota_fiscal/cancelar?id=' + id).then(async r => {
              if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {

                swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },

                  title: 'Nota fiscal cancelada',
                  text: "Nota fiscal cancelada com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.reload()
                  }
                })
              } else
                swal.fire({
                  title: 'Erro!',
                  text: "Nota não foi cancelada! " + r.data.substr(6, r.data.length),
                  icon: 'warning',
                })

            })

          }
        })
      }
    })
  }

  function liquidar(e, id, pkName) {
    e.preventDefault()
    if (pkName == 'pk_tit') {
      api.get('/contas_pagar?pk_tit=' + id).then(async r => {
        if (r.data[0].pendente <= 0) {
          swal.fire('Registro já liquidado', 'Registro não está pendente!', 'warning')
        } else {
          window.location.href = "/primavera/contas_pagar/liquidar/?codigo=" + id
        }
      })
    } else {
      api.get('/contas_receber?pk_ctr=' + id).then(async r => {
        if (r.data[0].pendente <= 0) {
          swal.fire('Registro já liquidado', 'Registro não está pendente!', 'warning')
        } else {
          window.location.href = "/primavera/contas_receber/liquidar/?codigo=" + id
        }
      })
    }
  }

  function reabrir(e, id, pkName) {
    e.preventDefault()
    if (pkName == 'pk_tit') {
      window.location.href = "/primavera/contas_pagar/reabrir?codigo=" + id
    } else {
      window.location.href = "/primavera/contas_receber/reabrir?codigo=" + id
    }

  }


  function retiradaInventario(e, id) {
    e.preventDefault()
    window.location.href = "/primavera/inventario/retirada?codigo=" + id

  }

  function programacao(e, id) {
    e.preventDefault()
    window.location.href = "/primavera/contas_pagar/programacao?codigo=" + id

  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      if (props.setSomaSelecao) {
        const somaSelecteds = data.map((n) => n['valor']);
        const reducer = (accumulator, curr) => accumulator + curr;
        props.setSomaSelecao(mMoeda(somaSelecteds.reduce(reducer)));

      }
      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

    if (props.setSomaSelecao)
      props.setSomaSelecao('')
  };

  const handleClick = (event, name, estornado, valor, pendente) => {
    // if ((props.mostraCheck) && (estornado !== 'C') && (pendente !== 0)) {
    if (props.mostraCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        selected.slice(0, -1)
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1),);
      }

      setSelected(newSelected);
      if (props.setSelected)
        props.setSelected(newSelected)

      if (props.setSomaSelecao) {
        if (newSelected.length > 0) {
          api.get('/itens_cobranca/soma?pk_icb=' + newSelected).then(async r => {
            props.setSomaSelecao(r.data[0].soma)
          })
        } else
          props.setSomaSelecao('')
      }
    }
  };

  const handleClickDetail = (event, name) => {
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  const handleChange = async (e) => {
    switch (e.target.name) {
      case 'numdoc':
        setNumdoc(e.target.value);
        break
      case 'valor':
        setValor(e.target.value);
        break
      case 'parcela':
        setParcela(e.target.value);
        break
      case 'vcto':
        setVcto(e.target.value);
        break
      case 'obs':
        setObs(e.target.value);
        break
      case 'data':
        setDataAdm(e.target.value);
        break
      case 'data_pre':
        setDataAdm(e.target.value);
        break

      case 'email':
        setEmail(e.target.value);
        break

    }
  };

  const handleBaixarResumoEvolucao = async (e, id) => {
    e.preventDefault();
    let baixou = await api.downloadEvolucao(id);
    if (baixou) {
      props.setSigned(id);
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Ocorreu um erro ao assinar/baixar o Resumo de Evoluções!",
        icon: 'error',
      })
    }
  }

  const handleToggleAppView = async (e, id, app) => {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja " + (app === 'S' ? 'desabilitar' : 'habilitar') + " a visualização deste resumo de evoluções no app?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/resumo_evolucoes/app/' + id).then(r => {
          console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Visualização alterada',
              text: "A visualização foi alterada com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  const administrarMedicamento = async (e, pk_adm) => {
    e.preventDefault();
    api.get('/administracoes?pk_adm=' + pk_adm).then(r => {
      console.log(r.data[0].dt_previsto);
      setDataPre(moment(r.data[0].dt_previsto).format('YYYY-MM-DDTHH:mm'));
      setDataAdm(moment(r.data[0].dt_previsto).format('YYYY-MM-DDTHH:mm'));

    })
    setShowModalAdm(true)
    setPk_Adm(pk_adm)
  }

  const handleProrrogacao = (e, id) => {
    e.preventDefault();
    window.location.href = "/primavera/internacao/prorrogacao/?codigo=" + id

  }

  const handleProrrogacaoPrescricao = (e, id) => {
    e.preventDefault();
    window.location.href = "/primavera/prescricao_medica/registro?pkAnterior=" + id

  }

  const gerarCobranca = async (e, pk_mit, data_mov, fk_pac, quantidade, fk_est, fk_mat, fk_mdc, observacoes, fk_usu) => {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma gerar cobrança?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/movimentacao_estoque/geraCobranca/', { pk_mit, data_mov, fk_pac, quantidade, fk_est, fk_mat, fk_mdc, observacoes, fk_usu }).then(r => {
          // console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Cobrança gerada',
              text: "Cobrança gerada com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Cobrança não foi gerada! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleAtendeTodos(e, id) {
    e.preventDefault()
    window.location.href = "/primavera/requisicoes/atender/?codigo=" + id
  }

  return (

    <div>



      <div >
        <Dialog aria-labelledby="simple-dialog-title" open={showModalEmail} fullWidth={false} maxWidth={'lg'} >

          <div style={{ padding: '20px', paddingTop: '40px' }}>
            <FrTextInput
              maxLength='300'
              value={email}
              onChange={handleChange}
              name='email'
              id='email'
              color='#c0c0c0'
              label='E-mails (separar e-mails por vírgula)'
              variant='outlined'
              size='small'
              style={{ width: 300 }}
            />
          </div>

          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv" >
              <button style={{ height: '40px' }} onClick={confirmaEmail}> Enviar E-mail </button>
              <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>



      <div >
        <Dialog aria-labelledby="simple-dialog-title" open={showModalAdm} fullWidth={false} maxWidth={'lg'} >

          <div style={{ padding: '20px', paddingTop: '40px' }}>
            <FrDatePicker
              time='S'
              name="data_pre"
              id="data_pre"
              color="#528b46"
              label="Data/Hora da Previsão"
              variant="outlined"
              size="small"
              style={{ width: 220 }}
              value={dataPre}
              onChange={handleChange}
            />

            <br />

            <FrDatePicker
              time='S'
              name="data"
              id="data"
              color="#528b46"
              label="Data/Hora da Administração"
              variant="outlined"
              size="small"
              style={{ width: 220 }}
              value={dataAdm}
              onChange={handleChange}
            />
          </div>

          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv" >
              <button style={{ height: '40px' }} onClick={confirmaAdm}> Confirmar Administração </button>
              <button style={{ height: '40px' }} onClick={confirmaNaoAdm}> Não Administrado </button>
              <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>


      <div hidden={!showModal}>
        <Modal.Dialog className="Modal">
          <div>
            <Modal.Body className="ModalBg" >
              <FrTextInput
                maxLength='15'
                value={numdoc}
                onChange={handleChange}
                name='numdoc'
                id='numdoc'
                color='#c0c0c0'
                label='Nº Documento'
                variant='outlined'
                size='small'
                style={{ width: 90 }}
              />

              <FrTextInput
                maxLength='3'
                value={parcela || ''}
                onChange={handleChange}
                name='parcela'
                id='parcela'
                color='#c0c0c0'
                label='Parcela'
                variant='outlined'
                size='small'
                style={{ width: 60 }}
              />


              <FrDatePicker

                value={vcto}
                onChange={handleChange}
                name="vcto"
                id="vcto"
                color="#528b46"
                label="Data Vencimento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />

              <FrTextInput
                maxLength='150'
                value={valor || ''}
                onChange={handleChange}
                name='valor'
                id='valor'
                color='#c0c0c0'
                label='Valor'
                variant='outlined'
                size='small'
                style={{ width: 100 }}
              />
              <br />
              <FrTextInput
                maxLength='500'
                value={obs || ''}
                onChange={handleChange}
                name="obs"
                id="obs"
                color="#528b46"
                label="Observações"
                variant="filled"
                size="small"
                style={{ width: 450 }}
                multiline
                rows="4"
                rowsMax="4"
                fullWidth
              />


            </Modal.Body>
          </div>
          <Modal.Footer>
            <div className="regButtonsDiv">
              <button onClick={confirmaModal}> Confirmar </button>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </Modal.Footer>
        </Modal.Dialog>
      </div>

      <div hidden={showModal}>
        <ThemeProvider theme={theme}>
          {(props.data.length === 0) ? (
            <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}
              {props.mensagemSemRegistro ? props.mensagemSemRegistro : ''}

            </div>
          ) : (
            <Paper className={classes.root}>
              <TableContainer className={props.f2 ? classes.containerF2 : classes.container}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>

                      {(!props.mostraCheck) ? (<></>) :

                        <StyledTableCell padding="checkbox">
                          <FrCheckBox
                            indeterminate={selected.length > 0 && selected.length < data.length}
                            checked={data.length > 0 && selected.length === data.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </StyledTableCell>
                      }


                      {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                      {((!props.readOnly) || (props.administrar)) ? (
                        <StyledTableCell
                          style={{ minWidth: widthOptions, width: widthOptions }}
                          align={'center'}
                        >
                          Opções
                        </StyledTableCell>) : (<></>)}
                      {columns.map((column) => (
                        (column.hidden) ? <></> :
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={createSortHandler((column.order) ? column.order : column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data.length > 0) ?
                      stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const isItemSelected = isSelected(row[props.codeName]);
                        const isItemOpened = isOpened(row[props.codeName]);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        if (props.detail && (props.regName === 'objetos_pessoais')) {
                          return (
                            <>
                              <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                                {(!props.mostraCheck) ? (<></>) :


                                  <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                                    <FrCheckBox
                                      checked={isItemSelected}
                                      inputProps={{ 'aria-labelledby': labelId }}

                                    />
                                  </TableCell>

                                }

                                <TableCell className='tableCell'>
                                  <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                    {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                                {!props.readOnly ? (
                                  <TableCell >
                                    {(!row['data_saida']) && (!row['fk_bcr']) && (!row['fk_bti']) ?
                                      <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                        <Icon className="tableEdit" icon={ic_mode_edit} size={14} />
                                      </Link> : (<></>)}
                                    {(props.inventario == "S") && (!row['data_saida']) ? (<Icon className="tableDelete" icon={retweet} size={16} onClick={(e) => retiradaInventario(e, row[props.codeName])} />) : (<></>)}
                                    {(props.enableDel == "S") && (!row['fk_bcr']) && (!row['fk_bti']) ? <Icon className="tableDelete" icon={ic_delete} size={14} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} /> : (<></>)}

                                  </TableCell>
                                ) : (<></>)}
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  if (column.hidden)
                                    return (<></>)
                                  else {
                                    if (column.image) {
                                      return (
                                        <TableCell className='tableCell' key={column.id} align={column.align}>
                                          <image src={value ? api.backendUrl + '/static/upload/inventario/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                        </TableCell>
                                      )
                                    }
                                    else {
                                      return (
                                        <TableCell className='tableCell' key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number' ? column.format(value) : value}


                                        </TableCell>
                                      );
                                    }
                                  }
                                })}
                              </TableRow>
                              <TableRow>
                                <FrInventarioDetail codeName={props.codeName} isOpen={isItemOpened} detail={row[props.codeName]} />
                              </TableRow>
                            </>
                          );

                        } else if (props.detail && !(props.regName === 'objetos_pessoais')) {
                          return (
                            <>
                              <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                                {(!props.mostraCheck) ? (<></>) :


                                  <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                                    <FrCheckBox
                                      checked={isItemSelected}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </TableCell>

                                }

                                <TableCell className='tableCell'>
                                  <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                    {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                                {!props.readOnly ? (
                                  <TableCell >
                                    <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                      <Icon className="tableEdit" icon={ic_mode_edit} size={14} />
                                    </Link>
                                    <Icon className="tableDelete" icon={ic_delete} size={14} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                    {(props.regName == "requisicoes") && (row['strstatus'] == 'Pendente') ? <Icon className="tableDelete" icon={ic_fact_check_outline} size={14} onClick={(e) => handleAtendeTodos(e, row[props.codeName])} /> : (<></>)}

                                  </TableCell>
                                ) : (<></>)}
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  if (column.hidden)
                                    return (<></>)
                                  else {
                                    if (column.image) {
                                      return (
                                        <TableCell className='tableCell' key={column.id} align={column.align}>
                                          <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                        </TableCell>
                                      )
                                    }

                                    else {
                                      return (
                                        <TableCell className='tableCell' key={column.id} align={column.align} >
                                          {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                      );
                                    }
                                  }
                                })}
                              </TableRow>
                              <TableRow>
                                <TableDetail titulo={(props.regName == 'estoques') ? 'MOVIMENTAÇÕES' : ''} codeName={props.codeName} isOpen={isItemOpened} detail={row[props.codeName]} />
                              </TableRow>
                              {(props.regName == 'estoques') ? (<TableRow>
                                <TableDetail titulo={'LOTES'} codeName={'pk_est1'} isOpen={isItemOpened} detail={row[props.codeName]} /></TableRow>) : <></>}

                            </>
                          );

                        } else {
                          return (
                            <TableRow onClick={(event) => handleClick(event, row[props.codeName], row['estornado'], row['valor'], row['pendente'])} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>

                              {(!props.mostraCheck) ? (<></>) :
                                <TableCell padding="checkbox">
                                  <FrCheckBox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  // hidden={row['estornado'] == 'C' || row['pendente'] <= 0}
                                  />
                                </TableCell>

                              }

                              {((!props.readOnly)) ? (
                                <TableCell >

                                  {(((props.administrar == "S") && (!isMobile)) || (!props.administrar)) &&
                                    (props.regName !== "baixa_titulo") && (props.regName !== "temptit") && (props.regName !== "resumo_evolucoes") && (props.regName !== "baixa_ctr")
                                    && (props.regName !== "nota_fiscal") && (!row['fk_bcr']) && (!row['fk_bti']) ?
                                    <Tooltip title={'Editar registro'}>
                                      <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                        <Icon className="tableEdit" icon={ic_mode_edit} size={14} />
                                      </Link>
                                    </Tooltip>
                                    : (<></>)}

                                  {(props.regName === "nota_fiscal") && (row['autorizada'] !== 'S') && (!row['data_cancelamento']) ?
                                    <Tooltip title={'Editar registro'}>
                                      <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                        <Icon className="tableEdit" icon={ic_mode_edit} size={14} />
                                      </Link>
                                    </Tooltip>
                                    : (<></>)}

                                  {(props.regName === "nota_fiscal") && (row['autorizada'] !== 'S') ?
                                    <Tooltip title={'Autorizar'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableEdit" icon={ic_done} size={14} onClick={(e) => autorizarNF(e, row[props.codeName])} />
                                      </div>
                                    </Tooltip>
                                    : <></>}

                                  {(props.regName === "nota_fiscal") && (row['autorizada'] == 'S') && (!row['data_cancelamento']) ?
                                    <Tooltip title={'Imprimir'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableEdit" icon={ic_local_printshop} size={14} onClick={(e) => imprimirNF(e, row['id_plugnotas'])} />
                                      </div>
                                    </Tooltip>

                                    : <></>}

                                  {(props.regName === "nota_fiscal") && (row['autorizada'] == 'S') && (!row['data_cancelamento']) ?
                                    <Tooltip title={'Enviar por E-mail'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableEdit" icon={mail} size={14} onClick={(e) => enviarEmail(e, row['id_plugnotas'])} />
                                      </div>
                                    </Tooltip>

                                    : <></>}

                                  {(props.regName === "nota_fiscal") && (row['autorizada'] == 'S') && (!row['data_cancelamento']) ?
                                    <Tooltip title={'Cancelar Nota'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableDeleteRed" icon={deleteIconic} size={18} onClick={(e) => cancelarNota(e, row['id_plugnotas'])} />
                                      </div>
                                    </Tooltip>

                                    : <></>}

                                  {(props.regName === "resumo_evolucoes") && !props.residente ?
                                    <>
                                      <Tooltip title={'Editar registro'}>
                                        <div style={{ display: 'inline' }}>
                                          <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                            <Icon className="tableEdit" icon={ic_mode_edit} size={14} />
                                          </Link>
                                        </div>
                                      </Tooltip>
                                      <Tooltip title={row?.status === 'A' ? 'Baixar em PDF' : 'Assinar documento PDF'}>
                                        <div style={{ display: 'inline' }}>
                                          <Icon className="tableDelete" icon={(row?.status === 'A' ? ic_picture_as_pdf : userCheck)} size={14} onClick={e => handleBaixarResumoEvolucao(e, row[props.codeName])} />
                                        </div>
                                      </Tooltip>
                                      <Tooltip title={row?.app === 'N' ? 'Liberar visualização no App' : 'Retirar visualização no App'}>
                                        <div style={{ display: 'inline' }}>
                                          <Icon className="tableDelete" icon={(row?.app === 'N' ? ic_visibility : ic_visibility_off)} size={14} onClick={e => handleToggleAppView(e, row[props.codeName], row?.app)} />
                                        </div>
                                      </Tooltip>


                                    </>
                                    : (<></>)}


                                  {(props.regName === "resumo_evolucoes") && props.residente ?
                                    <>
                                      <Tooltip title={'Visualizar registro'}>
                                        <div style={{ display: 'inline' }}>
                                          <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                            <Icon className="tableEdit" icon={ic_visibility} size={14} />
                                          </Link>
                                        </div>
                                      </Tooltip>
                                    </>
                                    : (<></>)}

                                  {(props.regName == "temptit") ?

                                    <Icon className="tableDelete" icon={ic_mode_edit} size={14} onClick={(e) => handleModal(e, row)} />
                                    : (<></>)}

                                  {(props.enableDel == 'S') && (props.regName !== 'nota_fiscal')&& (!row['fk_bcr']) && (!row['fk_bti']) ? (
                                    <Tooltip title={'Excluir registro'}>
                                      <div style={{ display: 'inline' }}>

                                        <Icon className="tableDelete" icon={ic_delete} size={14} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                      </div>
                                    </Tooltip>
                                  )
                                    : (<></>)}


                                  {(props.enableDel == 'S') && (props.regName == 'nota_fiscal') && (row['autorizada'] !== 'S') ? (
                                    <Tooltip title={'Excluir registro'}>
                                      <div style={{ display: 'inline' }}>

                                        <Icon className="tableDelete" icon={ic_delete} size={14} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                      </div>
                                    </Tooltip>
                                  )
                                    : (<></>)}


                                  {(props.pdf) ? (<Icon className="tableDelete" icon={ic_local_printshop} size={14} onClick={(e) => pedeimprimeData(e, row[props.codeName])} />)
                                    : (<></>)}


                                  {(props.liquidar == "S") ? (
                                    <Tooltip title={'Liquidar'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableDelete" icon={checkCircle} size={14} onClick={(e) => liquidar(e, row[props.codeName], props.codeName)} />
                                      </div>
                                    </Tooltip>)
                                    : (<></>)}

                                  {(props.liquidar == "S") ? (
                                    <Tooltip title={'Reabrir'}>
                                      <div style={{ display: 'inline' }}>
                                        <Icon className="tableDelete" icon={retweet} size={14} onClick={(e) => reabrir(e, row[props.codeName], props.codeName)} />
                                      </div>
                                    </Tooltip>)
                                    : (<></>)}


                                  {(props.regName == "contas_pagar") ? (<Icon className="tableDelete" icon={time} size={14} onClick={(e) => programacao(e, row[props.codeName])} />)
                                    : (<></>)}


                                  {(props.regName === "usuarios" && (row[props.codeName] === props?.user)) ?
                                    // <Link to={"/primavera/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                    <Icon className="tableDelete" icon={ic_fingerprint} size={14} onClick={e => { e.preventDefault(); props.modalUsuario(row[props.codeName]) }} />
                                    // </Link>
                                    : (<></>)}


                                  {((props.administrar == "S")) ? (
                                    <Tooltip title={(!row['administrado']) ? 'Administrar' : (row['administrado'] == 'S') ? 'Administrado' : 'Não Administrado'}>
                                      <div style={{ display: 'inline' }}>
                                        {(!row['administrado']) ?
                                          <Icon className="tableDelete" icon={checkCircle} size={14} onClick={(e) => administrarMedicamento(e, row[props.codeName])} />
                                          :
                                          <Icon className={(row['administrado']) == 'S' ? "tableDelete" : "tableDeleteRed"} icon={(row['administrado']) == 'S' ? ic_done : slash} size={14} />
                                        }
                                      </div>
                                    </Tooltip>)
                                    : (<></>)}

                                  {((props.estornar == "S") && (row['estornado'] == 'N') && (row['fk_mit'] > 0) && (row['fk_tme'] !== 5)) ? (
                                    <Tooltip title={'Estornar Cobrança'}>
                                      <Link to={""}>

                                        <Icon className="tableDelete" icon={undo2} size={14} onClick={(e) => estornar(e, row['pk_icb'], row['fk_mit'])} />
                                      </Link>
                                    </Tooltip>)
                                    : (<></>)}

                                  {((props.regName == 'internacao') && (row['prorroga'])) ? <Icon className="tableDelete" icon={alarm} size={14} onClick={(e) => handleProrrogacao(e, row[props.codeName])} /> : <></>}

                                  {((props.regName == 'prescricao_medica') && (row['situacao'] == 'A')) ? <Icon className="tableDelete" icon={retweet} size={14} onClick={(e) => handleProrrogacaoPrescricao(e, row[props.codeName])} /> : <></>}


                                </TableCell>
                              ) : (<></>)}


                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.hidden)
                                  return (<></>)
                                else {
                                  if (column.image) {
                                    return (
                                      <TableCell className='tableCell' key={column.id} align={column.align}>
                                        <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                                      </TableCell>
                                    )
                                  }
                                  else if (column.isLink) {
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        <Link to={"/primavera/" + column.linkTo + "?" + column.searchValue + '=' + row[column.searchValue]}>
                                          {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </Link>
                                      </TableCell>
                                    )
                                  }

                                  else {
                                    return (
                                      <TableCell className='tableCell' key={column.id} align={column.align} style={{ backgroundColor: (row['ativo'] == 'N') || (row['data_cancelamento']) ? '#f08080' : (row['autorizada'] == 'S') ? '#68c700' : '', color: (row['estornado'] == 'S') ? 'red' : '' }}>
                                        {column.format && typeof value === 'number' ? column.format(value) : column.id == 'cpf' ? mCPF(value) : value}
                                      </TableCell>
                                    );
                                  }
                                }
                              })}
                            </TableRow>
                          );
                        }
                      }) : ''}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
