import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrDatePicker from '../components/FrDatePicker';
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from '../services/api'
import { paramsToObject, getFullAge, getUserCode } from '../utils/functions'
import { mHora } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTablePrescricao from '../components/FrTablePrescricao'
import $ from 'jquery'
import Dialog from '@material-ui/core/Dialog';
import FrameF2 from '../components/FrameF2'
import AutoComp from '../components/AutoComp'


const swal = withReactContent(Swal)
const moment = require("moment");

function Prescricao_Medica() {

  const [data, setData] = useState({})
  const [dataPih, setDataPih] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboPrf, setComboPrf] = useState([{ value: '', display: '' }])
  const [enableAge, setEnableAge] = useState(false)
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [data_inicio, setData_Inicio] = useState('')
  const [data_termino, setData_Termino] = useState('')
  const [fk_mdc, setFk_Mdc] = useState(0)
  const [fk_uni, setFk_Uni] = useState(0)
  const [fk_pmf, setFk_Pmf] = useState(0)
  const [fk_pmp, setFk_Pmp] = useState(0)
  const [nomemdc, setNomeMdc] = useState('')
  const [nomepmf, setNomePmf] = useState('')
  const [nomepmp, setNomePmp] = useState('')
  const [complemento, setComplemento] = useState('')
  const [dosagem, setDosagem] = useState('')
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)
  const [usuario, setUsuario] = useState('');
  const [fk_via, setFk_Via] = useState(0)
  const [nomevia, setNomeVia] = useState('')
  const [comboVia, setComboVia] = useState([{ value: '', display: '' }])

  const [dataPmi, setDataPmi] = useState([{ display: '' }])
  const [comboPmf, setComboPmf] = useState([{ value: '', display: '' }])
  const [comboPmp, setComboPmp] = useState([{ value: '', display: '' }])

  const horarios = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  const [fk_pri, setFk_Pri] = useState(0)
  const [comboPri, setComboPri] = useState([{ value: '', display: '' }])
  const [prf, setPrf] = useState('')

  const columns = [
    { id: 'nomemdc', numeric: false, label: 'Medicamento', align: 'left', minWidth: 100 },
    { id: 'dosagem', numeric: false, label: 'Dosagem', align: 'left', minWidth: 100 },
    { id: 'complemento', numeric: false, label: 'Complemento', align: 'left', minWidth: 100 },
    { id: 'nomevia', numeric: false, label: 'Via Adm.', align: 'left', minWidth: 100 },
    { id: 'nomepmf', numeric: false, label: 'Frequência', align: 'left', minWidth: 100 },
    { id: 'nomepmp', numeric: false, label: 'Período', align: 'left', minWidth: 100 },
    { id: 'format_data_inicio', numeric: false, label: 'Início', align: 'left', minWidth: 100 },
    { id: 'format_data_termino', numeric: false, label: 'Término', align: 'left', minWidth: 100 },
  ]

  const [erro, setErro] = useState(false);
  const [renovacao, setRenovacao] = useState(0)


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Prescrição Médica', 'Alteracao'))

      } else {
        setEnableEdit(await api.getAcessos('Prescrição Médica', 'Inclusao'))

      }

      setComboPac(await api.getComboData('pacientes', 'reg'))
      setComboPrf(await api.getComboDataWhere('profissionais', '?prescreve=S&ativo=S'))
      setComboMdc(await api.getComboData('medicamentos'))
      setComboPmf(await api.getComboData('prescricao_medica_frequencia'))
      setComboPmp(await api.getComboData('prescricao_medica_periodo'))
      setComboUni(await api.getComboData('unidades_medida'))
      setComboVia(await api.getComboData('via_administracao'))
      setComboPri(await api.getComboData('principio_ativo'))


      if ((query.codigo > 0) || (query.pkAnterior > 0)) {
        setUpdateType(query.codigo > 0)
        let codigo = query.codigo > 0 ? query.codigo : query.pkAnterior
        api.get('/prescricao_medica?pk_pmi=' + codigo).then(async r => {
          console.log(r.data[0])
          if (r.data[0]) {
            setRenovacao(r.data[0].pk_pmd)
            let pmd = r.data[0].pk_pmd

            await api.get('/prescricao_medica/pmi?pk_pmd=' + r.data[0].pk_pmd).then(async r => {
              if (r.data) {
                setDataPmi(r.data)
                await api.get('/prescricao_medica/pih?pk_pmd=' + pmd).then(r => {
                  if (r.data) {
                    setDataPih(r.data)
                  }
                })
              }
            })


            setComboPac(await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac))
            r.data[0].idade = getFullAge(r.data[0].data_nasc)
            setData(r.data[0])

            setPac(r.data[0].fk_pac)

            setEnableAge(true)

            api.get('/usuarios?pk_usu=' + r.data[0].fk_usu).then(res => {
              console.log(res.data[0])
              //setUsuario(res.data[0].nome)
            })

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })

      } else swal.close()
    }

    fetchData()

  }, [])

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };
      auxValues['fk_pac'] = pac;

      // if (pac > 0) {
      //   api.get('/pacientes?pk_pac=' + pac).then(r => {
      //     auxValues['idade'] = getFullAge(r.data[0].data_nasc)
      //     setEnableAge(true)
      //   })
      // }

      setData(auxValues);

    }
    fetchPac()
  }, [pac])

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };
      auxValues['fk_prf'] = prf;
      setData(auxValues);
    }
    fetchPac()
  }, [prf])

  function handleAge(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };
      api.get('/pacientes?pk_pac=' + e.target.value).then(r => {
        auxValues['idade'] = getFullAge(r.data[0].data_nasc)
        setData(auxValues)
        setEnableAge(true)
      })
    }
  }


  function consistData(data) {
    let required = [
      'fk_pac',
      'fk_prf'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    let alertaAnterior = renovacao > 0 ? ' Todos os itens da Prescrição anterior serão inativados.Confirma?' : ''

    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?' + alertaAnterior,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (!updateType) {
          let auth = JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 }))
          data.fk_usu = auth.user;
        }
        const auxValues = { ...data };
        let errors = consistData(auxValues)

        let formData = new FormData();
        try {
          formData.append('file', auxValues.arq, auxValues.receita)
        } catch {

        }

        auxValues.pk_pmi = dataPmi;
        auxValues.pk_pih = dataPih
        auxValues.user = getUserCode()
        auxValues.renovacao = renovacao
        formData.append('data', JSON.stringify(auxValues))

        if (dataPmi[0].nomemdc === '') {
          errors.push('Nenhum medicamento informado!')
        }


        if (errors.length === 0) {
          if (updateType) {
            api.post('/prescricao_medica/edit', formData).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/prescricao_medica/add', formData).then(r => {
              // console.log(r)
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    swal.fire({
                      title: 'ATENÇÃO',
                      text: "Deseja imprimir a receita? ",
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    }).then((result) => {
                      if (result.value) {
                        window.location.href = '/primavera/imprimeReceita?pk_pmd=' + r.data
                        // window.open(api.backendUrl + '/api/prescricao_medica/print?pk_pmd=' + r.data, '_blank');
                      } else

                        window.history.back()
                    })
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  //   useEffect(() => {
  //     const fetchPac = async () => {
  //       const auxValues = { ...data };
  //       auxValues['fk_pac'] = pac;
  // handleAge
  //       setData(auxValues);
  //     };
  //     fetchPac();
  //   }, [pac]);




  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        handleAge(e)
        break

      default: auxValues[e.target.name] = e.target.value;
    }


    if (e.target.name.substring(0, 4) == 'hora') {
      // alert(e.target.name)
      auxValues[e.target.name] = mHora(e.target.value);
      e.target.value = mHora(e.target.value)
    }

    setData(auxValues);
  };


  const selectFile = (e) => {
    e.preventDefault();
    $('#html_btn').click();
  }


  const handleSelectedFile = (e) => {
    let file = e.target.files[0];
    console.log(file)
    let auxValues = { ...data }
    if (file) {
      auxValues.arq = file
      auxValues.receita = file.name
    } else {
      auxValues.arq = null
      auxValues.receita = 'Nenhum arquivo selecionado'
    }
    setData(auxValues)
  }


  const handleChangeModal = (e) => {
    if (e.target.name == 'data_inicio')
      setData_Inicio(e.target.value)
    if (e.target.name == 'data_termino')
      setData_Termino(e.target.value)
    if (e.target.name == 'fk_mdc') {
      setFk_Mdc(e.target.value)
      api.get('/medicamentos?pk_mdc=' + e.target.value).then(r => {
        setNomeMdc(r.data[0].nome_comercial);
      })

    }
    if (e.target.name == 'fk_via') {
      setFk_Via(e.target.value)
      api.get('/via_administracao?pk_via=' + e.target.value).then(r => {
        setNomeVia(r.data[0].descricao);
      })

    }
    if (e.target.name == 'fk_pri')
      setFk_Pri(e.target.value)

    if (e.target.name == 'fk_uni')
      setFk_Uni(e.target.value)
    if (e.target.name == 'fk_pmf') {
      setFk_Pmf(e.target.value)
      api.get('/prescricao_medica_frequencia?pk_pmf=' + e.target.value).then(r => {
        setNomePmf(r.data[0].descricao);
      })

    }
    if (e.target.name == 'fk_pmp') {
      setFk_Pmp(e.target.value)
      api.get('/prescricao_medica_periodo?pk_pmp=' + e.target.value).then(r => {
        setNomePmp(r.data[0].descricao);
      })

    }
    if (e.target.name == 'complemento')
      setComplemento(e.target.value)
    if (e.target.name == 'dosagem')
      setDosagem(e.target.value)

  };

  function mostraModal(e) {
    e.preventDefault()
    setData_Inicio(moment(new Date()).format('YYYY-MM-DDTHH:mm'));
    setData_Termino(null)
    setFk_Mdc(null)
    setFk_Via(null)
    setFk_Uni(null)
    setFk_Pmf(null)
    setFk_Pmp(null)
    setFk_Pri(null)

    setUpdateModal(false)
    setComplemento(null)
    setDosagem(null)

    const auxValues = { ...data };
    auxValues.hora1 = ''
    auxValues.hora2 = ''
    auxValues.hora3 = ''
    auxValues.hora4 = ''
    auxValues.hora5 = ''
    auxValues.hora6 = ''
    auxValues.hora7 = ''
    auxValues.hora8 = ''
    auxValues.hora9 = ''
    auxValues.hora10 = ''
    auxValues.hora11 = ''
    auxValues.hora12 = ''
    auxValues.hora13 = ''
    auxValues.hora14 = ''
    auxValues.hora15 = ''
    auxValues.hora16 = ''
    auxValues.hora17 = ''
    auxValues.hora18 = ''
    auxValues.hora19 = ''
    auxValues.hora20 = ''
    auxValues.hora21 = ''
    auxValues.hora22 = ''
    auxValues.hora23 = ''
    auxValues.hora24 = ''

    setData(auxValues)


    setModal(true)

  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault()

    if ((!fk_mdc) || (!fk_pmf) || (!fk_pmp) || (!data_inicio) || (!dosagem) || (!fk_uni) || (!fk_via)) {
      setErro(true)
      let errors = []

      if (!fk_mdc)
        errors.push('medicamento')
      if (!fk_pmf)
        errors.push('frequência')
      if (!fk_pmp)
        errors.push('período')
      if (!data_inicio)
        errors.push('data início')
      if (!fk_uni)
        errors.push('unidade')
      if (!dosagem)
        errors.push('dosagem')
      if (!fk_via)
        errors.push('via de administração')

      swal.fire({
        title: 'Erro!',
        text: "Os campos " + errors.join(',') + " devem ser informados!",
        icon: 'warning',
      }).then((result) => {
        if (result.value) {
          setErro(false)
        }
      })

    } else
      if ((fk_pmf == 6) && (!data.hora1)) {
        setErro(true)
        swal.fire({
          title: 'Erro!',
          text: "Nenhum horário informado!",
          icon: 'warning',
        }).then((result) => {
          if (result.value) {
            setErro(false)
          }
        })
      }
      else {
        api.get('/consiste_prescricao_medica?pk_pmi=' + pkModal + '&fk_pac=' + data.fk_pac + '&fk_mdc=' + fk_mdc + '&data_inicio=' + data_inicio).then(r => {

          if (r.data.length > 0) {
            setErro(true)

            swal.fire({
              title: 'Erro!',
              text: "Já existe uma prescrição médica ativa com este medicamento para este paciente!",
              icon: 'warning',
            }).then((result) => {
              if (result.value) {
                setErro(false)
              }
            })

          } else {

            if (!updatemodal)
              incluiPmi(e)
            else
              updatePmi(e)

            closeModal(e)

          }
        })
      }
  }

  function incluiPmi(e) {
    e.preventDefault();
    let pk = 1

    // alert(data.hora1)
    let duplicidade = false
    if (dataPmi[0].nomemdc !== '') {
      var i;
      for (i = 0; i < dataPmi.length; i++) {
        if ((fk_mdc > 0) && (dataPmi[i].fk_mdc == fk_mdc) && (dataPmi[i].dosagem == dosagem)) {
          duplicidade = true
        }
      }
    }


    if (!duplicidade) {
      if ((dataPmi[0].fk_mdc === '') || (dataPmi[0].display === '')) {
        setDataPmi([{
          data_inicio: (data_inicio) ? data_inicio : null,
          data_termino: (data_termino) ? data_termino : null,
          format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY HH:mm') : '',
          format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY HH:mm') : '',
          fk_mdc: fk_mdc,
          nomemdc: nomemdc,
          fk_via: fk_via,
          nomevia: nomevia,
          fk_pmp: fk_pmp,
          fk_pmf: fk_pmf,
          fk_uni: fk_uni,
          nomepmp: nomepmp,
          nomepmf: nomepmf,
          dosagem: dosagem,
          complemento: complemento,
          pk: pk,
          hora1: data.hora1,
          hora2: data.hora2,
          hora3: data.hora3,
          hora4: data.hora4,
          hora5: data.hora5,
          hora6: data.hora6,
          hora7: data.hora7,
          hora8: data.hora8,
          hora9: data.hora9,
          hora10: data.hora10,
          hora11: data.hora11,
          hora12: data.hora12,
          hora13: data.hora13,
          hora14: data.hora14,
          hora15: data.hora15,
          hora16: data.hora16,
          hora17: data.hora17,
          hora18: data.hora18,
          hora19: data.hora19,
          hora20: data.hora20,
          hora21: data.hora21,
          hora22: data.hora22,
          hora23: data.hora23,
          hora24: data.hora24,
          fk_pri: fk_pri
        }])

        incluiPhi(pk)
      } else {

        let item = [{
          data_inicio: dataPmi[0].data_inicio,
          data_termino: dataPmi[0].data_termino,
          format_data_inicio: dataPmi[0].format_data_inicio,
          format_data_termino: dataPmi[0].format_data_termino,
          fk_mdc: dataPmi[0].fk_mdc,
          nomemdc: dataPmi[0].nomemdc,
          fk_via: dataPmi[0].fk_via,
          nomevia: dataPmi[0].nomevia,
          fk_pmp: dataPmi[0].fk_pmp,
          fk_pmf: dataPmi[0].fk_pmf,
          fk_uni: dataPmi[0].fk_uni,
          nomepmp: dataPmi[0].nomepmp,
          nomepmf: dataPmi[0].nomepmf,
          dosagem: dataPmi[0].dosagem,
          complemento: dataPmi[0].complemento,
          pk: pk,
          hora1: dataPmi[0].hora1,
          hora2: dataPmi[0].hora2,
          hora3: dataPmi[0].hora3,
          hora4: dataPmi[0].hora4,
          hora5: dataPmi[0].hora5,
          hora6: dataPmi[0].hora6,
          hora7: dataPmi[0].hora7,
          hora8: dataPmi[0].hora8,
          hora9: dataPmi[0].hora9,
          hora10: dataPmi[0].hora10,
          hora11: dataPmi[0].hora11,
          hora12: dataPmi[0].hora12,
          hora13: dataPmi[0].hora13,
          hora14: dataPmi[0].hora14,
          hora15: dataPmi[0].hora15,
          hora16: dataPmi[0].hora16,
          hora17: dataPmi[0].hora17,
          hora18: dataPmi[0].hora18,
          hora19: dataPmi[0].hora19,
          hora20: dataPmi[0].hora20,
          hora21: dataPmi[0].hora21,
          hora22: dataPmi[0].hora22,
          hora23: dataPmi[0].hora23,
          hora24: dataPmi[0].hora24,
          fk_pri: dataPmi[0].fk_pri
        }]
        pk++
        var i;
        for (i = 1; i < dataPmi.length; i++) {
          item.push({
            data_inicio: dataPmi[i].data_inicio,
            data_termino: dataPmi[i].data_termino,
            format_data_inicio: dataPmi[i].format_data_inicio,
            format_data_termino: dataPmi[i].format_data_termino,
            fk_mdc: dataPmi[i].fk_mdc,
            nomemdc: dataPmi[i].nomemdc,
            fk_via: dataPmi[i].fk_via,
            nomevia: dataPmi[i].nomevia,
            fk_pmp: dataPmi[i].fk_pmp,
            fk_pmf: dataPmi[i].fk_pmf,
            fk_uni: dataPmi[i].fk_uni,
            nomepmp: dataPmi[i].nomepmp,
            nomepmf: dataPmi[i].nomepmf,
            dosagem: dataPmi[i].dosagem,
            complemento: dataPmi[i].complemento,
            pk: pk,
            hora1: dataPmi[i].hora1,
            hora2: dataPmi[i].hora2,
            hora3: dataPmi[i].hora3,
            hora4: dataPmi[i].hora4,
            hora5: dataPmi[i].hora5,
            hora6: dataPmi[i].hora6,
            hora7: dataPmi[i].hora7,
            hora8: dataPmi[i].hora8,
            hora9: dataPmi[i].hora9,
            hora10: dataPmi[i].hora10,
            hora11: dataPmi[i].hora11,
            hora12: dataPmi[i].hora12,
            hora13: dataPmi[i].hora13,
            hora14: dataPmi[i].hora14,
            hora15: dataPmi[i].hora15,
            hora16: dataPmi[i].hora16,
            hora17: dataPmi[i].hora17,
            hora18: dataPmi[i].hora18,
            hora19: dataPmi[i].hora19,
            hora20: dataPmi[i].hora20,
            hora21: dataPmi[i].hora21,
            hora22: dataPmi[i].hora22,
            hora23: dataPmi[i].hora23,
            hora24: dataPmi[i].hora24,
            fk_pri: dataPmi[i].fk_pri

          })
          pk++
        }
        item.push({
          data_inicio: (data_inicio) ? data_inicio : null,
          data_termino: (data_termino) ? data_termino : null,
          format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY HH:mm') : '',
          format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY HH:mm') : '',
          fk_mdc: fk_mdc,
          nomemdc: nomemdc,
          fk_via: fk_via,
          nomevia: nomevia,
          fk_pmp: fk_pmp,
          fk_pmf: fk_pmf,
          fk_uni: fk_uni,
          nomepmp: nomepmp,
          nomepmf: nomepmf,
          dosagem: dosagem,
          complemento: complemento,
          pk: pk,
          hora1: data.hora1,
          hora2: data.hora2,
          hora3: data.hora3,
          hora4: data.hora4,
          hora5: data.hora5,
          hora6: data.hora6,
          hora7: data.hora7,
          hora8: data.hora8,
          hora9: data.hora9,
          hora10: data.hora10,
          hora11: data.hora11,
          hora12: data.hora12,
          hora13: data.hora13,
          hora14: data.hora14,
          hora15: data.hora15,
          hora16: data.hora16,
          hora17: data.hora17,
          hora18: data.hora18,
          hora19: data.hora19,
          hora20: data.hora20,
          hora21: data.hora21,
          hora22: data.hora22,
          hora23: data.hora23,
          hora24: data.hora24,
          fk_pri: fk_pri
        })
        setDataPmi(item)
        incluiPhi(pk)
        pk++

      }
    } else {
      setErro(true)

      swal.fire({
        title: 'Erro!',
        text: "Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: 'warning',
      }).then((result) => {
        if (result.value) {
          setErro(false)
        }
      })

    }
  }

  function updatePmi(e) {
    e.preventDefault();

    let item = []
    let i

    let duplicidade = false
    for (i = 0; i < dataPmi.length; i++) {
      if ((dataPmi[i].pk !== pkModal) && (fk_mdc > 0) && (dataPmi[i].fk_mdc == fk_mdc) && (dataPmi[i].dosagem == dosagem)) {
        duplicidade = true
      }
    }

    if (!duplicidade) {
      for (i = 0; i < dataPmi.length; i++) {
        if (dataPmi[i].pk == pkModal) {

          let phi = []

          if (data.hora1) {
            phi.push({ fk_pmi: pkModal, hora: data.hora1 })

            if (data.hora2)
              phi.push({ fk_pmi: pkModal, hora: data.hora2 })

            if (data.hora3)
              phi.push({ fk_pmi: pkModal, hora: data.hora3 })

            if (data.hora4)
              phi.push({ fk_pmi: pkModal, hora: data.hora4 })

            if (data.hora5)
              phi.push({ fk_pmi: pkModal, hora: data.hora5 })

            if (data.hora6)
              phi.push({ fk_pmi: pkModal, hora: data.hora6 })

            if (data.hora7)
              phi.push({ fk_pmi: pkModal, hora: data.hora7 })

            if (data.hora8)
              phi.push({ fk_pmi: pkModal, hora: data.hora8 })

            if (data.hora9)
              phi.push({ fk_pmi: pkModal, hora: data.hora9 })

            if (data.hora10)
              phi.push({ fk_pmi: pkModal, hora: data.hora10 })

            if (data.hora11)
              phi.push({ fk_pmi: pkModal, hora: data.hora11 })

            if (data.hora12)
              phi.push({ fk_pmi: pkModal, hora: data.hora12 })

            if (data.hora13)
              phi.push({ fk_pmi: pkModal, hora: data.hora13 })

            if (data.hora14)
              phi.push({ fk_pmi: pkModal, hora: data.hora14 })

            if (data.hora15)
              phi.push({ fk_pmi: pkModal, hora: data.hora15 })

            if (data.hora16)
              phi.push({ fk_pmi: pkModal, hora: data.hora16 })

            if (data.hora17)
              phi.push({ fk_pmi: pkModal, hora: data.hora17 })

            if (data.hora18)
              phi.push({ fk_pmi: pkModal, hora: data.hora18 })

            if (data.hora19)
              phi.push({ fk_pmi: pkModal, hora: data.hora19 })

            if (data.hora20)
              phi.push({ fk_pmi: pkModal, hora: data.hora20 })

            if (data.hora21)
              phi.push({ fk_pmi: pkModal, hora: data.hora21 })

            if (data.hora22)
              phi.push({ fk_pmi: pkModal, hora: data.hora22 })

            if (data.hora23)
              phi.push({ fk_pmi: pkModal, hora: data.hora23 })

            if (data.hora24)
              phi.push({ fk_pmi: pkModal, hora: data.hora24 })

            setDataPih(phi)

          }

          item.push({
            data_inicio: (data_inicio) ? data_inicio : null,
            data_termino: (data_termino) ? data_termino : null,
            format_data_inicio: (data_inicio) ? moment(data_inicio).format('DD/MM/YYYY HH:mm') : '',
            format_data_termino: (data_termino) ? moment(data_termino).format('DD/MM/YYYY HH:mm') : '',
            fk_mdc: fk_mdc,
            nomemdc: nomemdc,
            fk_via: fk_via,
            nomevia: nomevia,
            fk_pmp: fk_pmp,
            fk_pmf: fk_pmf,
            fk_uni: fk_uni,
            nomepmp: nomepmp,
            nomepmf: nomepmf,
            dosagem: dosagem,
            complemento: complemento,
            pk: pkModal,
            hora1: data.hora1,
            hora2: data.hora2,
            hora3: data.hora3,
            hora4: data.hora4,
            hora5: data.hora5,
            hora6: data.hora6,
            hora7: data.hora7,
            hora8: data.hora8,
            hora9: data.hora9,
            hora10: data.hora10,
            hora11: data.hora11,
            hora12: data.hora12,
            hora13: data.hora13,
            hora14: data.hora14,
            hora15: data.hora15,
            hora16: data.hora16,
            hora17: data.hora17,
            hora18: data.hora18,
            hora19: data.hora19,
            hora20: data.hora20,
            hora21: data.hora21,
            hora22: data.hora22,
            hora23: data.hora23,
            hora24: data.hora24,
            fk_pri: fk_pri
          })
        } else {
          item.push({
            data_inicio: (dataPmi[i].data_inicio) ? dataPmi[i].data_inicio : null,
            data_termino: (dataPmi[i].data_termino) ? dataPmi[i].data_termino : null,
            format_data_inicio: (dataPmi[i].data_inicio) ? moment(dataPmi[i].data_inicio).format('DD/MM/YYYY HH:mm') : '',
            format_data_termino: (dataPmi[i].data_termino) ? moment(dataPmi[i].data_termino).format('DD/MM/YYYY HH:mm') : '',
            fk_mdc: dataPmi[i].fk_mdc,
            nomemdc: dataPmi[i].nomemdc,
            fk_via: dataPmi[i].fk_via,
            nomevia: dataPmi[i].nomevia,
            fk_pmp: dataPmi[i].fk_pmp,
            fk_pmf: dataPmi[i].fk_pmf,
            fk_uni: dataPmi[i].fk_uni,
            nomepmp: dataPmi[i].nomepmp,
            nomepmf: dataPmi[i].nomepmf,
            dosagem: dataPmi[i].dosagem,
            complemento: dataPmi[i].complemento,
            pk: dataPmi[i].pk,
            hora1: dataPmi[i].hora1,
            hora2: dataPmi[i].hora2,
            hora3: dataPmi[i].hora3,
            hora4: dataPmi[i].hora4,
            hora5: dataPmi[i].hora5,
            hora6: dataPmi[i].hora6,
            hora7: dataPmi[i].hora7,
            hora8: dataPmi[i].hora8,
            hora9: dataPmi[i].hora9,
            hora10: dataPmi[i].hora10,
            hora11: dataPmi[i].hora11,
            hora12: dataPmi[i].hora12,
            hora13: dataPmi[i].hora13,
            hora14: dataPmi[i].hora14,
            hora15: dataPmi[i].hora15,
            hora16: dataPmi[i].hora16,
            hora17: dataPmi[i].hora17,
            hora18: dataPmi[i].hora18,
            hora19: dataPmi[i].hora19,
            hora20: dataPmi[i].hora20,
            hora21: dataPmi[i].hora21,
            hora22: dataPmi[i].hora22,
            hora23: dataPmi[i].hora23,
            hora24: dataPmi[i].hora24,
            fk_pri: dataPmi[i].fk_pri
          })
        }

        if (i + 1 == dataPmi.length)
          setDataPmi(item)
      }
    }
  }


  useEffect(() => {
    const fetchTermino = async () => {
      let auxData = 0
      if ((fk_pmp) && (fk_pmf) && (data_inicio)) {
        api.get('/prescricao_medica_periodo?pk_pmp=' + fk_pmp).then(r => {
          if (r.data[0].intervalo_dias > 0)
            auxData = moment(data_inicio).add(r.data[0].intervalo_dias, "days").format("YYYY-MM-DDTHH:mm")

          setData_Termino(auxData)

        })

      }
    }
    fetchTermino()
  }, [fk_pmf, fk_pmp, data_inicio])


  useEffect(() => {
    const fetchNomes = async () => {
      if (fk_pmp)
        api.get('/prescricao_medica_periodo?pk_pmp=' + fk_pmp).then(r => {
          setNomePmp(r.data[0].descricao);
        })

      if (fk_pmf)
        api.get('/prescricao_medica_frequencia?pk_pmf=' + fk_pmf).then(r => {
          setNomePmf(r.data[0].descricao);
        })

      if (fk_mdc)
        api.get('/medicamentos?pk_mdc=' + fk_mdc).then(r => {
          setNomeMdc(r.data[0].nome_comercial);
        })

      if (fk_via)
        api.get('/via_administracao?pk_via=' + fk_via).then(r => {
          setNomeVia(r.data[0].descricao);
        })

    }
    fetchNomes()
  }, [fk_pmf, fk_pmp, fk_mdc, fk_via])


  function incluiPhi(pk) {
    let phi = []
    for (var i = 0; i < dataPih.length; i++) {
      phi.push({ fk_pmi: dataPih[i].fk_pmi, hora: dataPih[i].hora })
    }

    // alert(data.hora1)
    if (data.hora1) {
      phi.push({ fk_pmi: pk, hora: data.hora1 })

      if (data.hora2)
        phi.push({ fk_pmi: pk, hora: data.hora2 })

      if (data.hora3)
        phi.push({ fk_pmi: pk, hora: data.hora3 })

      if (data.hora4)
        phi.push({ fk_pmi: pk, hora: data.hora4 })

      if (data.hora5)
        phi.push({ fk_pmi: pk, hora: data.hora5 })

      if (data.hora6)
        phi.push({ fk_pmi: pk, hora: data.hora6 })

      if (data.hora7)
        phi.push({ fk_pmi: pk, hora: data.hora7 })

      if (data.hora8)
        phi.push({ fk_pmi: pk, hora: data.hora8 })

      if (data.hora9)
        phi.push({ fk_pmi: pk, hora: data.hora9 })

      if (data.hora10)
        phi.push({ fk_pmi: pk, hora: data.hora10 })

      if (data.hora11)
        phi.push({ fk_pmi: pk, hora: data.hora11 })

      if (data.hora12)
        phi.push({ fk_pmi: pk, hora: data.hora12 })

      if (data.hora13)
        phi.push({ fk_pmi: pk, hora: data.hora13 })

      if (data.hora14)
        phi.push({ fk_pmi: pk, hora: data.hora14 })

      if (data.hora15)
        phi.push({ fk_pmi: pk, hora: data.hora15 })

      if (data.hora16)
        phi.push({ fk_pmi: pk, hora: data.hora16 })

      if (data.hora17)
        phi.push({ fk_pmi: pk, hora: data.hora17 })

      if (data.hora18)
        phi.push({ fk_pmi: pk, hora: data.hora18 })

      if (data.hora19)
        phi.push({ fk_pmi: pk, hora: data.hora19 })

      if (data.hora20)
        phi.push({ fk_pmi: pk, hora: data.hora20 })

      if (data.hora21)
        phi.push({ fk_pmi: pk, hora: data.hora21 })

      if (data.hora22)
        phi.push({ fk_pmi: pk, hora: data.hora22 })

      if (data.hora23)
        phi.push({ fk_pmi: pk, hora: data.hora23 })

      if (data.hora24)
        phi.push({ fk_pmi: pk, hora: data.hora24 })

      setDataPih(phi)

    }
  }


  useEffect(() => {
    const fetchMedicamentos = async () => {
      if (fk_pri > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Medicamentos..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboMdc(await api.getComboDataWhere("medicamentos", '?ativo=S&fk_pri=' + fk_pri));
        swal.close()
      } else
        setComboMdc(await api.getComboData("medicamentos"));

    }

    fetchMedicamentos()
  }, [fk_pri])


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Prescrição Médica</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <div class="row">
            <div class="column" style={{ marginLeft: '20px' }}>

              <AutoComp
                value={data.fk_pac || ""}
                name="fk_pac"
                id="fk_pac"
                label="Residente"
                data={comboPac}
                width={450}
                setValue={setPac}
                required
                disabled={updateType}
              />

              {/* <label hidden={!enableAge}>{data.idade || ''}</label> */}

              <br />

              <AutoComp
                value={data.fk_prf || ''}
                name="fk_prf"
                id="fk_prf"
                label='Profissional'
                data={comboPrf}
                width={450}
                setValue={setPrf}
                disabled={updateType}
              />
            </div>


            <div class="column" hidden={!updateType} style={{ marginLeft: '150px' }}>
              <FrTextInput
                maxLength='150'
                value={usuario || ''}
                onChange={handleChange}
                name='usuario'
                id='usuario'
                color='#c0c0c0'
                label='Usuário'
                variant='outlined'
                size='small'
                style={{ width: 220 }}
                onBlur={handleAge}
                disabled={true}
              />

              <br />

              <FrDatePicker
                time='S'
                value={(moment(data.data_cad).format('YYYY-MM-DDTHH:mm') || '')}
                onChange={handleChange}
                name="data_cad"
                id="data_cad"
                color="#58b46"
                label="Data/Hora de Lançamento"
                variant="outlined"
                size="small"
                style={{ width: 220 }}
                disabled={true}
              />

            </div>
          </div>

          <hr width="100%" size="50" color=" #528b46" />


          <div className="grupoTitulo">
            Medicamentos
          </div>
          <div className="grupoEdits">
            <button hidden={updateType} onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div className="manutReg" >
              <FrTablePrescricao
                setData={setData}
                setFk_Via={setFk_Via}
                dataPrescricao={data}
                readonly={updateType} setDataPih={setDataPih} dataPih={dataPih} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setData_Inicio={setData_Inicio}
                setData_Termino={setData_Termino} setFk_Mdc={setFk_Mdc} setFk_Uni={setFk_Uni} setFk_Pri={setFk_Pri} setFk_Pmf={setFk_Pmf} setFk_Pmp={setFk_Pmp} editar={true} setDataGrid={setDataPmi} setDosagem={setDosagem} setComplemento={setComplemento} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={dataPmi} />

              <Dialog aria-labelledby="simple-dialog-title" open={(modal && !erro)} fullWidth={true} maxWidth={'lg'}>
                <div style={{ padding: '20px', paddingTop: '40px' }}>

                  <div class="row" style={{ marginLeft: '2px' }}>
                    <div class="column">
                      <AutoComp
                        label={'Principio Ativo'}
                        data={comboPri}
                        name="fk_pri"
                        id="fk_pri"
                        value={fk_pri || ''}
                        width={300}
                        setValue={setFk_Pri}
                      />

                    </div>
                    <div class="column" >
                      <FrameF2 tipoModal={'Principio_Ativo'} data={data} pri={fk_pri} setPri={setFk_Pri} ></FrameF2>
                    </div>


                    <div class="column" style={{ marginLeft: '5px' }}>

                      <AutoComp
                        label={'Medicamento'}
                        data={comboMdc}
                        name="fk_mdc"
                        id="fk_mdc"
                        value={fk_mdc || ''}
                        width={300}
                        setValue={setFk_Mdc}
                      />

                    </div>
                    <div class="column" >

                      <FrameF2 tipoModal={'Medicamento'} data={data} mdc={fk_mdc} setMdc={setFk_Mdc} ></FrameF2>
                    </div>


                  </div>

                  <FrTextInput
                    maxLength='30'
                    name="dosagem"
                    id="dosagem"
                    color="#528b46"
                    label="Dosagem"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: '85px' }}
                    value={dosagem || ''}
                    onChange={handleChangeModal}
                    disabled={updateType}
                  />

                  <AutoComp
                    value={fk_uni || ''}
                    name="fk_uni"
                    id="fk_uni"
                    label='Unidade'
                    data={comboUni}
                    width={150}
                    setValue={setFk_Uni}
                  />

                  <AutoComp
                    name="fk_via"
                    id="fk_via"
                    label='Via Administração'
                    data={comboVia}
                    value={fk_via || ''}
                    width={180}
                    setValue={setFk_Via}
                  />

                  <FrTextInput
                    maxLength='80'
                    name="complemento"
                    id="complemento"
                    color="#528b46"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: '200px' }}
                    value={complemento || ''}
                    onChange={handleChangeModal}
                    disabled={updateType}
                  />

                  <AutoComp
                    value={fk_pmf || ''}
                    name="fk_pmf"
                    id="fk_pmf"
                    label='Frequência'
                    data={comboPmf}
                    width={200}
                    setValue={setFk_Pmf}
                  />

                  <AutoComp
                    value={fk_pmp || ''}
                    name="fk_pmp"
                    id="fk_pmp"
                    label='Período'
                    data={comboPmp}
                    width={200}
                    setValue={setFk_Pmp}
                  />

                  <FrDatePicker
                    maxLength='10'
                    time='S'
                    value={(data_inicio || '')}
                    onChange={handleChangeModal}
                    name="data_inicio"
                    id="data_inicio"
                    color="#528b46"
                    label="Data Início"
                    variant="outlined"
                    size="small"
                    style={{ width: 215 }}
                  />

                  <FrDatePicker
                    maxLength='10'
                    time='S'
                    value={(data_termino || '')}
                    onChange={handleChangeModal}
                    name="data_termino"
                    id="data_termino"
                    color="#528b46"
                    label="Data Término"
                    variant="outlined"
                    size="small"
                    // disabled
                    style={{ width: 215 }}
                  />


                  <div hidden={fk_pmf != 6}>
                    <br />
                    <br />

                    <div class='col-sm-3'>
                      <strong>Informe os Horários</strong>
                      <hr width="100%" size="50" color=" #528b46" />

                      {horarios.map((horario, index) => (

                        <FrTextInput
                          maxLength='5'
                          name={"hora" + (index + 1)}
                          id={"hora" + (index + 1)}
                          color="#528b46"
                          variant="outlined"
                          size="small"
                          style={{ width: '60px' }}
                          value={data['hora' + (index + 1)] || ''}
                          onChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="regButtonsDiv">
                    <button onClick={handleSubmitModal}>Salvar</button>
                    <button onClick={closeModal}>Cancelar</button>
                  </div>

                </div>
              </Dialog>
            </div>

          </div>


          <div style={{ paddingTop: '10px' }}>
            <input
              type="file"
              name="arquivo"
              id="html_btn"
              accept=".pdf"
              onChange={handleSelectedFile}
            />
            <FrTextInput
              value={data.receita || ''}
              onChange={handleChange}
              name="receita"
              id="receita"
              color="#528b46"
              label="Receita"
              variant="outlined"
              size="small"
              disabled
              style={{ width: 300 }}
            // onClick={handleClick}
            />

            <button type="file" className="incluiFileButton" onClick={selectFile}>
              Receita
            </button>
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Prescricao_Medica;
