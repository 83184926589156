import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrTableReg from '../components/FrTableReg'
import FrLoading from '../components/FrLoading'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'
import moment from 'moment';

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Compromissos(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)

  moment.locale()

  const columns = [
    { id: 'hora', numeric: false, label: 'Hora', align: 'left', minWidth: 50 },
    { id: 'paciente', numeric: true, label: 'Residente', align: 'left', minWidth: 250 },
    { id: 'rotina', numeric: false, label: 'Rotina', align: 'left', minWidth: 150 },
    { id: 'observacoes', numeric: true, label: 'Obs.', align: 'left', minWidth: 350 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    setFiltered(true)
    swal.fire({
      customClass: {
        container: 'my-swal'
      },
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/agenda/compInd?data=' + props.date).then(r => {
      console.log(r.data)
      setData(r.data)
      swal.close()
    })
  }

  return (
    <div className="Manut">
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Compromissos Individuais - {moment(props.date).format('DD/MM/yyyy')}</h3>
        </div>
        <br />
        <div className="tableDiv">
          <FrTableReg
            // readonly={true}
            regName="Compromissos"
            columns={columns}
            searched={filtered}
            codeName="pk_age"
            page={''}
            data={data}
            enableEdit={false}
            enableDel={true}
            orderBy={'format_hora'}
          />
        </div>
      </div>
    </div>
  );
}

export default Compromissos;
