import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { ic_local_pharmacy } from 'react-icons-kit/md/ic_local_pharmacy'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { Scrollbars } from 'react-custom-scrollbars';
import { clipboard } from 'react-icons-kit/ionicons/clipboard';
import { ic_keyboard_arrow_right_twotone } from 'react-icons-kit/md/ic_keyboard_arrow_right_twotone'

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import api from '../../services/api'
import icon from '../../imgs/icon.png';
import logo from '../../imgs/logo.png';


function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [animationStart, setAnimationStart] = useState(false)
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openAdmissao, setOpenAdmissao] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openLocalizacao, setOpenLocalizacao] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openInternacoes, setOpenInternacoes] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [openFinanceiro2, setOpenFinanceiro2] = useState(false)
    const [openAdministracao, setOpenAdministracao] = useState(false)
    const [openEstoques, setOpenEstoques] = useState(false)
    const [openOperEstoque, setopenOperEstoque] = useState(false)
    const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
    const customColor = '#528b46'

    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenAdmissao(false)
            setOpenSistema(false)
            setOpenAdministracao(false);

        }
        setAnimationStart(true)
        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenCadastros(!openCadastros)
        setOpenAdmissao(false)
        setOpenSistema(false)
        setOpenAdministracao(false)

        setOpenFinanceiros(false)
        setOpenGerais(false)
        setOpenAuxiliares(false)
        setOpenInternacoes(false)
        setOpenLocalizacao(false)
        setOpenEstoques(false)
    }

    function abreAdmissao(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAdmissao(!openAdmissao)
        setOpenCadastros(false)
        setOpenSistema(false)
        setOpenAdministracao(false)


        setOpenFinanceiro2(false)
        setopenOperEstoque(false)

    }

    function abreAdministracao(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAdministracao(!openAdministracao)
        setOpenAdmissao(false)
        setOpenCadastros(false)
        setOpenSistema(false)

    }

    function abreSistema(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenSistema(!openSistema)
        setOpenCadastros(false)
        setOpenAdmissao(false)
        setOpenAdministracao(false)
    }


    function abreSubFinanceiros(e) {
        e.preventDefault();
        setOpenFinanceiros(!openFinanceiros)
        setOpenGerais(false)
        setOpenInternacoes(false)
        setOpenLocalizacao(false)
        setOpenAuxiliares(false)
        setOpenEstoques(false)

    }

    function abreSubGerais(e) {
        e.preventDefault();
        setOpenFinanceiros(false)
        setOpenGerais(!openGerais)
        setOpenInternacoes(false)
        setOpenLocalizacao(false)
        setOpenEstoques(false)
        setOpenAuxiliares(false)
    }


    function abreSubAuxiliares(e) {
        e.preventDefault();
        setOpenFinanceiros(false)
        setOpenGerais(false)
        setOpenAuxiliares(!openAuxiliares)
        setOpenInternacoes(false)
        setOpenLocalizacao(false)
        setOpenEstoques(false)
    }

    function abreSubInternacoes(e) {
        e.preventDefault();
        setOpenFinanceiros(false)
        setOpenGerais(false)
        setOpenAuxiliares(false)
        setOpenInternacoes(!openInternacoes)
        setOpenLocalizacao(false)
        setOpenEstoques(false)
    }

    function abreSubLocalizacao(e) {
        e.preventDefault();
        setOpenFinanceiros(false)
        setOpenGerais(false)
        setOpenAuxiliares(false)
        setOpenInternacoes(false)
        setOpenLocalizacao(!openLocalizacao)
        setOpenEstoques(false)
    }

    function abreSubEstoques(e) {
        e.preventDefault();
        setOpenFinanceiros(false)
        setOpenGerais(false)
        setOpenAuxiliares(false)
        setOpenInternacoes(false)
        setOpenLocalizacao(false)
        setOpenEstoques(!openEstoques)
    }


    function abreSubOperFinanceiros(e) {
        e.preventDefault();
        setOpenFinanceiro2(!openFinanceiro2)
        setopenOperEstoque(false)

    }

    function abreSubOperEstoque(e) {
        e.preventDefault();
        setOpenFinanceiro2(false)
        setopenOperEstoque(!openOperEstoque)

    }


    function openData() {
        let url = 'https://analytics.zoho.com/open-view/2256913000004946967'
        window.open(url)
    }

    return (
        (auth.residente > 0) ? (<></>) :
            (<div className={"Navbar" + (hide ? '-hide' : '')}>
                <button onClick={toggleNavBarHide} className="toggle_navbar">
                    {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
                </button>

                <Tooltip title="Tela Principal">
                    <Nav.Link href="/primavera/home">
                        {hide ? <img alt='Expandir' src={icon} className="fr_icon_navbar" /> : <img alt='Encolher' src={logo} className="fr_logo_navbar" />}
                    </Nav.Link>
                </Tooltip>
                <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
                    <Nav justify defaultActiveKey="/primavera/home" className="flex-column">
                        {(auth.residente > 0) ? (<></>) :

                            <>  <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")} style={{ backgroundColor: customColor }} >

                                <Icon icon={ic_assignment} size={20} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                            </button>
                                <Collapse in={openCadastros}>

                                    <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                        <Nav.Link href="/primavera/pacientes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Residentes'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/plano_hospedagem" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Planos de Hospedagem'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/plano_trabalho" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Planos de Trabalho'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/profissionais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Profissionais'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/fornecedores" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Fornecedores/Funcionários'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/tomador" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Tomadores'}</strong>}
                                        </Nav.Link>

                                        <button onClick={abreSubFinanceiros} className={'buttonItem'} style={{ backgroundColor: customColor }} >

                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Financeiros'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />
                                        </button>

                                        <Collapse in={openFinanceiros}>
                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/tipo_pagamento" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Pagamento'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/forma_cobranca" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Formas de Cobrança'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_cobranca" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Cobrança'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_item_cobranca" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Itens de Cobrança'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/classes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Classes de Despesas'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/subclasses" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'SubClasses de Despesas'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/formas_pagamento" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Formas de Pagamento'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/bancos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Bancos'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/contas_bancarias" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Contas Bancárias'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_nota" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Notas'}</strong>}
                                                </Nav.Link>

                                            </div>

                                        </Collapse>


                                        <button onClick={abreSubGerais} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Gerais'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />

                                        </button>

                                        <Collapse in={openGerais}>

                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/estados" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Estados'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/cidades" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Cidades'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/parentesco" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Grau de Parentesco'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/profissoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Profissões'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_profissional" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Profissionais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/conselho_profissional" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Conselhos Profissionais'}</strong>}
                                                </Nav.Link>

                                            </div>

                                        </Collapse>




                                        <button onClick={abreSubAuxiliares} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Auxiliares'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />

                                        </button>

                                        <Collapse in={openAuxiliares}>

                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >


                                                <Nav.Link href="/primavera/queixas" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Queixas'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/cid10" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'CID 10'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/doencas" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Doenças'}</strong>}
                                                </Nav.Link>


                                                <Nav.Link href="/primavera/qualidade_sono" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Qualidade do Sono'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/eliminacoes_intestinais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Eliminações Intestinais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/eliminacoes_urinarias" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Eliminações Urinárias'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/grupo_preocupacoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Grupo de Preocupações'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/preocupacoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Preocupações'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/grupo_medicamentos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Grupos de Medicamentos'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/unidades_medida" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Unidades de Medida'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_receita_medica" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Receita Médica'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/laboratorios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Laboratórios'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/medicamentos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Medicamentos'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/prescricao_medica_periodo" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Período Medicação'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/prescricao_medica_frequencia" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Frequência Medicação'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/via_administracao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Vias de Administração'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/convenios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Convênios'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/hospitais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Hospitais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/via_alimentacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Vias de Alimentação'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/principio_ativo" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Príncípios Ativos'}</strong>}
                                                </Nav.Link>

                                            </div>

                                        </Collapse>

                                        <button onClick={abreSubEstoques} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Estoques'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />

                                        </button>


                                        <Collapse in={openEstoques}>
                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/grupo_materiais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Grupo de Materiais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/fabricantes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Fabricantes'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/materiais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Materiais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_mov_estoque" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Movimentação'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/lotes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Lotes de Medicamentos'}</strong>}
                                                </Nav.Link>

                                            </div>
                                        </Collapse>




                                        <button onClick={abreSubInternacoes} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Internações'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />
                                        </button>

                                        <Collapse in={openInternacoes}>

                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/status_internacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Status Internação'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/tipo_hospedagem" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Hospedagem'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/acomodacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tipos de Acomodação'}</strong>}
                                                </Nav.Link>


                                                <Nav.Link href="/primavera/rotinas" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Rotinas'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/servicos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Serviços'}</strong>}
                                                </Nav.Link>


                                                <Nav.Link href="/primavera/itens_evolucao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Itens de Evolução'}</strong>}
                                                </Nav.Link>

                                            </div>

                                        </Collapse>



                                        <button onClick={abreSubLocalizacao} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Localizações'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />

                                        </button>


                                        <Collapse in={openLocalizacao}>
                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/setor" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Setores'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/andar" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Andares'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/ala" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Alas'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/predio" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Prédios'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/unidade" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Unidades'}</strong>}
                                                </Nav.Link>

                                            </div>
                                        </Collapse>



                                    </div>
                                </Collapse>

                                <button onClick={abreAdmissao} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openAdmissao ? " Open" : "")} style={{ backgroundColor: customColor }} >

                                    <Icon icon={ic_local_pharmacy} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Operacional'}</strong>}
                                </button>
                                <Collapse in={openAdmissao}>
                                    <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                        <Nav.Link href="/primavera/resumo_evolucoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Resumo Evoluções'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/exporta_evolucao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Exportar Evoluções'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/internacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Internação'}</strong>}
                                        </Nav.Link>


                                        <Nav.Link href="/primavera/admissao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Admissão'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/checkin" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Check In'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/checkout" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Check Out'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/agendas" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Agendas'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/prescricao_medica" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Prescrição Médica'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/administracoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Administração Medicamentos'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/home" onClick={openData} className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Dados'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/transferencias" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Transferência de Unidade'}</strong>}
                                        </Nav.Link>

                                        <button onClick={abreSubOperFinanceiros} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Financeiro'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />
                                        </button>


                                        <Collapse in={openFinanceiro2}>
                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >

                                                <Nav.Link href="/primavera/itens_cobranca" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Itens de Cobrança'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/contas_receber" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Contas a Receber'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/contas_pagar" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Contas a Pagar'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/nota_fiscal" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Notas Fiscais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/mov_bco" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Movimentações Bancárias'}</strong>}
                                                </Nav.Link>

                                            </div>
                                        </Collapse>

                                        <button onClick={abreSubOperEstoque} className={'buttonItem'} style={{ backgroundColor: customColor }} >
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Estoque'}</strong>}
                                            <Icon icon={ic_keyboard_arrow_right_twotone} size={20} />
                                        </button>


                                        <Collapse in={openOperEstoque}>
                                            <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >
                                                <Nav.Link href="/primavera/objetos_pessoais" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Objetos Pessoais'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/inventario" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Inventário'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/prescricoes/view" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Geração de Requisições'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/requisicoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Requisições de Estoque'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/estoques" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Estoques'}</strong>}
                                                </Nav.Link>

                                                <Nav.Link href="/primavera/movimentacao_estoque" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Movimentação de Estoque'}</strong>}
                                                </Nav.Link>


                                                <Nav.Link href="/primavera/tabela_precos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                    {hide ? '' : <strong style={{ paddingLeft: '55px' }}>{'Tabela de Preços'}</strong>}
                                                </Nav.Link>

                                            </div>
                                        </Collapse>


                                    </div>
                                </Collapse>


                                <button onClick={abreAdministracao} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")} style={{ backgroundColor: customColor }} >
                                    <Icon icon={clipboard} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Relatórios'}</strong>}
                                </button>
                                <Collapse in={openAdministracao}>
                                    <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >
                                        <Nav.Link href="/primavera/relContas_Receber" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Relatório Contas Receber'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/relContas_Pagar" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Relatório Contas Pagar'}</strong>}
                                        </Nav.Link>

                                    </div>
                                </Collapse>

                                <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")} style={{ backgroundColor: customColor }} >
                                    <Icon icon={ic_desktop_mac} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                                </button>
                                <Collapse in={openSistema}>
                                    <div className="toggleMenu" style={{ border: '2px solid ' + customColor }} >
                                        <Nav.Link href="/primavera/certificado" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Certificado'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/grupo_usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Grupo de Usuários'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Usuários'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/cardapios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Cardápio'}</strong>}
                                        </Nav.Link>

                                        <Nav.Link href="/primavera/importacaoSimpro" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                            {hide ? '' : <strong style={{ paddingLeft: '35px' }}>{'Importação Simpro'}</strong>}
                                        </Nav.Link>

                                    </div>
                                </Collapse>


                            </>}
                    </Nav>
                </Scrollbars>

            </div>)
    );
}

export default NavBar;