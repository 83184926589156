import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Itens_Evolucao() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [pk_ni2, setPK_NI2] = useState(0)
  const [comboApresentacao, setComboApresentacao] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.pk > 0) {
        setPK_NI2(query.pk)

        const auxValues = { ...data };

        api.get('/itens_evolucao?pk_ni2=' + query.pk).then(r => {
          if (r.data) {
            auxValues['descricao1'] = r.data[0].descricao1
            auxValues['descricao2'] = r.data[0].descricao
            setData(auxValues)
          }
        })

      }
      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Alteracao'))

      } else {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Inclusao'))

      }

      setComboTipo([
        {
          "value": "S",
          "display": "Check",
        },
        {
          "value": "N",
          "display": "Informação Escrita",
        },])

      setComboApresentacao([
        {
          "value": "",
          "display": "Não Apresentar",
        },
        {
          "value": "L",
          "display": "Lista",
        },
        {
          "value": "T",
          "display": "Texto",
        },

      ])


      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/itens_evolucao?pk_ni3=' + query.codigo).then(r => {
          if (r.data) {
            setData(r.data[0])
            console.log(r.data)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])



  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/itens_evolucao/nivel3/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            auxValues.fk_ni2 = pk_ni2
            api.post('/itens_evolucao/nivel3/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Item de Evolução</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='50'
            value={data.descricao1 || ''}
            onChange={handleChange}
            name="descricao1"
            id="descricao1"
            color="#528b46"
            label="Descrição Nível 1"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 330 }}
          />

          <div style={{ paddingLeft: 30 }}>

            <FrTextInput
              maxLength='50'
              value={data.descricao2 || ''}
              onChange={handleChange}
              name="descricao2"
              id="descricao2"
              color="#528b46"
              label="Descrição Nível 2"
              variant="outlined"
              size="small"
              disabled
              style={{ width: 330 }}
            />

          </div>

          <div style={{ paddingLeft: 60 }}>

            <FrTextInput
              maxLength='50'
              value={data.descricao || ''}
              onChange={handleChange}
              name="descricao"
              id="descricao"
              color="#528b46"
              label="Descrição Nível 3"
              variant="outlined"
              size="small"
              required
              style={{ width: 330 }}
            />

            <FrSelect
              value={data.checked || ''}
              onChange={handleChange}
              name="checked"
              id="checked"
              label='Tipo de Informação'
              data={comboTipo}
              style={{ width: 150 }}
            />


            <FrSelect
              value={data.ativo || ''}
              onChange={handleChange}
              name="ativo"
              id="ativo"
              data={comboAtivo}
              label='Situação'
              style={{ width: 150 }}
            />
            <br />
            <br />

            <strong>Apresentação para Família</strong>
            <hr width="100%" size="50" color=" #528b46" />

            <FrSelect
              value={data.apresentacao || ''}
              onChange={handleChange}
              name="apresentacao"
              id="apresentacao"
              label='Tipo de Apresentação'
              data={comboApresentacao}
              style={{ width: 170 }}
            />

            <FrTextInput
              maxLength='150'
              value={data.texto_apresentacao || ''}
              onChange={handleChange}
              name="texto_apresentacao"
              id="texto_apresentacao"
              color="#528b46"
              label="Texto Apresentação"
              variant="outlined"
              size="small"
              required
              style={{ width: 430 }}
            />

          </div>


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Evolucao;
