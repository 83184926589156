import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrMultipleSelect from '../components/FrMultipleSelect'
import FrameF2 from '../components/FrameF2'
import FrTextInput from '../components/FrTextInput'
import { ic_attach_money } from 'react-icons-kit/md/ic_attach_money'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, setMultiSelectFilter } from '../utils/functions'
import Dialog from '@material-ui/core/Dialog';
import { ic_request_page_outline } from 'react-icons-kit/md/ic_request_page_outline'
import { MDBContainer, MDBIframe } from "mdbreact";

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Itens_Cobranca() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboTcb, setComboTcb] = useState([{ value: '', display: '' }])
  const [comboFcb, setComboFcb] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [dataTcb, setDataTcb] = useState([])
  const [dataTic, setDataTic] = useState([])
  const [selected, setSelected] = useState([])
  const [comboTic, setComboTic] = useState([{ value: '', display: '' }])
  const [showModal, setShowModal] = React.useState(false);
  const [vcto, setVcto] = React.useState('');
  const [valor, setValor] = React.useState('');
  const [obs, setObs] = React.useState('');
  const [perc_desconto, setper_desconto] = React.useState('');
  const [valor_desconto, setvalor_desconto] = React.useState('');
  const [fcb, setFcb] = React.useState('');
  const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  const [fk_pac, setfk_pac] = React.useState('');
  const [somaIcb, setSomaIcb] = React.useState('');
  const [somaSelecao, setSomaSelecao] = React.useState('');
  const [arquivo, setArquivo] = React.useState("");
  const [showExtrato, setShowExtrato] = React.useState(false);

  const columns = [
    { id: 'pk_icb', numeric: false, label: 'Nº Cobrança', align: 'left', minWidth: 200 },
    { id: 'nometic', numeric: false, label: 'Tipo', align: 'left', minWidth: 200 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data_emissao' },
    { id: 'format_data_previsao_vcto', numeric: false, label: 'Previsão Vcto', align: 'left', minWidth: 100, order: 'data_previsao_vcto' },
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 200 },
    { id: 'fk_ctr', numeric: false, label: 'Título', align: 'left', minWidth: 80 },
    { id: 'format_valor', numeric: true, label: 'Valor', align: 'left', minWidth: 80 },
    { id: 'estornado', numeric: true, label: 'Estornado', align: 'left', minWidth: 80 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Itens de Cobrança'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        setComboFcb(await api.getComboData('forma_cobranca'))


        let dataTic = await api.getComboData('tipo_item_cobranca')
        setComboTic(dataTic)

        let dataTcb = await api.getComboData('tipo_cobranca')
        setComboTcb(dataTcb)


        setComboSituacao([
          {
            "value": " and icb.fk_ctr is null and icb.estornado<>'C'",
            "display": "Pendentes",
          },
          {
            "value": " and icb.fk_ctr is null and icb.estornado='C'",
            "display": "Cancelados",
          },
          {
            "value": " and icb.fk_ctr is not null",
            "display": "Cobrados",
          },])


        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        if (query.fk_pac)
          setComboPac(await api.getComboData('pacientes', 'pac' + query.fk_pac))
        else
          setComboPac(await api.getComboData('pacientes', 'reg'))


        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    queryObject.fk_tcb = JSON.stringify(dataTcb || [])
    queryObject.fk_tic = JSON.stringify(dataTic || [])

    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/itens_cobranca?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/itens_cobranca', { params: queryObject }).then(r => {
        setData(r.data)
        if (r.data[0])
          setSomaIcb(r.data[0].somaIcb)
        else
          setSomaIcb('')
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }


  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
    setShowExtrato(false)
  }

  function confirmaModal(e) {
    e.preventDefault()
    if ((vcto) && (fcb)) {
      let user = auth.user

      swal.fire({
        title: 'ATENÇÃO',
        text: "Confirma geração da cobrança para os registros marcados?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          api.post('/itens_cobranca/geracaoCobranca', { selected, vcto, valor_desconto, obs, fcb, user, fk_pac }).then(r => {
            swal.fire({
              title: 'Cobrança gerada',
              text: "Cobrança gerada com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          })
        }
      })

      setShowModal(false)
    } else {
      setShowModal(false)
      swal.fire('Campos obrigatórios!', 'Obrigatório informar vencimento e forma de pagamento!', 'warning')
    }
  }


  function geraCobranca(e) {
    e.preventDefault()
    if (selected.length == 0)
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para geração da cobrança.', 'warning')
    else {
      let total = 0
      let codPac
      let erro = false

      data.map((registro) => {

        if (selected.indexOf(registro.pk_icb) !== -1) {
          total = total + registro.valor
          if (!codPac)
            codPac = registro.fk_pac

          setfk_pac(registro.fk_pac)
          if (registro.fk_pac !== codPac)
            erro = true
        }
      })

      if (!erro) {
        setValor(total.toFixed(2))
        setObs('')
        setper_desconto(0)
        setvalor_desconto(0)
        setVcto('')
        setFcb(0)
        setShowModal(true)
      } else
        swal.fire('Erro na seleção de registros!', 'Registros selecionados devem ser do mesmo residente.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    setPac('')
    setDataTcb([])
    setDataTic([])
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/itens_cobranca');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      case 'fk_tcb':
        auxValues['fk_tcb'] = e.target.value;
        setDataTcb(e.target.value)
        break

      case 'fk_tic':
        auxValues['fk_tic'] = e.target.value;
        setDataTic(e.target.value)
        break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };


  const handleChangeModal = (e) => {
    if (e.target.name == 'perc_desconto') {
      setper_desconto(e.target.value)
      setvalor_desconto(((valor * e.target.value) / 100).toFixed(2))
    }

    if (e.target.name == 'fcb')
      setFcb(e.target.value)

    if (e.target.name == 'vcto')
      setVcto(e.target.value)

    if (e.target.name == 'obs')
      setObs(e.target.value)

  };

  useEffect(() => {
    const fetchPac = async () => {
      setComboPac(await api.getComboData('pacientes', 'pac' + pac))

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setFilter(auxValues);

      }

    }
    fetchPac()
  }, [pac])

  function imprimeData(e) {
    e.preventDefault()
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para impressão do extrato.', 'warning')
    } else {
      setArquivo(api.backendUrl + '/api/itens_cobranca/extrato_cobranca?pks=' + selected)
      setShowExtrato(true)
    }
  }



  return (

    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showExtrato} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      <div className="Manut">
        <NavBar />
        <div className="ManutContent">
          <div className="defaultHeader">
            <h3>Itens de Cobrança</h3>
            <UserOptions />
          </div>
          <br />
          <div className="filtersDiv">

            <form id="searchForm" onSubmit={getData}>


              <div class="container-fluid">
                <div class="row" >

                  <div class="column">

                    <FrSelect
                      value={filter.fk_pac || ''}
                      onChange={handleChange}
                      name="fk_pac"
                      label='Residente'
                      id="fk_pac"
                      data={comboPac}
                      style={{ width: 450 }}
                      required
                    />
                  </div>
                  <div class="column">

                    <FrameF2 data={data} pac={pac} setPac={setPac} tipoModal={'Residente'} ></FrameF2>
                  </div>

                </div>
              </div>


              <div>

                <FrDatePicker
                  value={(filter.dataini_emissao || '').split('T')[0]}
                  name="dataini_emissao"
                  id="dataini_emissao"
                  color="#528b46"
                  label="Período de Emissão"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}
                />


                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.datafim_emissao || '').split('T')[0]}
                  name="datafim_emissao"
                  id="datafim_emissao"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}

                />

                <FrDatePicker
                  value={(filter.dataini_cobranca || '').split('T')[0]}
                  name="dataini_cobranca"
                  id="dataini_cobranca"
                  color="#528b46"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}

                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.datafim_cobranca || '').split('T')[0]}
                  name="datafim_cobranca"
                  id="datafim_cobranca"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}

                />
                <br />
                <FrDatePicker
                  value={(filter.dataini_liquidacao || '').split('T')[0]}
                  name="dataini_liquidacao"
                  id="dataini_liquidacao"
                  label="Período de Liquidação"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}

                />
                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>
                <FrDatePicker
                  value={(filter.datafim_liquidacao || '').split('T')[0]}
                  onChange={handleChange}
                  name="datafim_liquidacao"
                  id="datafim_liquidacao"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                />

                <FrMultipleSelect
                  value={dataTcb}
                  name="fk_tcb"
                  id="fk_tcb"
                  label='Tipo de Cobrança'
                  data={comboTcb}
                  style={{ width: 250 }}
                  onChange={handleChange}

                />

                <FrSelect
                  value={filter.situacao || ''}
                  name="situacao"
                  id="situacao"
                  label='Situação'
                  data={comboSituacao}
                  style={{ width: 150 }}
                  onChange={handleChange}

                />

                <FrMultipleSelect
                  value={dataTic}
                  style={{ width: 250 }}
                  name="fk_tic"
                  id="fk_tic"
                  label='Tipo'
                  data={comboTic}
                  onChange={handleChange}
                />


                <FrTextInput
                  maxLength='150'
                  value={filter.fk_ctr}
                  onChange={handleChange}
                  name='fk_ctr'
                  id='fk_ctr'
                  color='#c0c0c0'
                  label='Nº Título'
                  variant='outlined'
                  size='small'
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <button onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>
                <button onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
                {enableAdd !== 'S' ? '' :
                  <Link to="/primavera/itens_cobranca/registro">
                    <button >
                      <Icon icon={ic_add_box} size={18} />
                      Novo Registro
                    </button>
                  </Link>}

                <button onClick={geraCobranca}>
                  <Icon icon={ic_attach_money} size={18} />
                  Gerar Cobrança
                </button>

                <button hidden={auth.residente > 0} onClick={imprimeData}>
                  <Icon icon={ic_request_page_outline} size={18} />
                  Imprimir Pré Extrato
                </button>

                <label hidden={!somaIcb} style={{ paddingLeft: '10px' }}>Total Geral:</label>
                <label hidden={!somaIcb} style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}>{somaIcb}</label>

                <label hidden={!somaSelecao} style={{ paddingLeft: '10px' }}>Total Selecionado:</label>
                <label hidden={!somaSelecao} style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}>{somaSelecao}</label>

              </div>

              <div>
                <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >
                  <div style={{ padding: '20px', paddingTop: '40px' }}>

                    <div className="defaultHeader">

                      <h3>Geração de Cobrança</h3>
                    </div>
                    <br />

                    <FrTextInput
                      maxLength='150'
                      value={valor || ''}
                      name='valor'
                      id='valor'
                      color='#c0c0c0'
                      label='Total Apurado'
                      variant='outlined'
                      size='small'
                      style={{ width: 100 }}
                    />
                    <FrTextInput
                      maxLength='150'
                      value={perc_desconto || ''}
                      onChange={handleChangeModal}
                      name='perc_desconto'
                      id='perc_desconto'
                      color='#c0c0c0'
                      label='% Desconto'
                      variant='outlined'
                      size='small'
                      style={{ width: 100 }}
                    />
                    <FrTextInput
                      maxLength='150'
                      value={valor_desconto || ''}
                      name='valor_desconto'
                      id='valor_desconto'
                      color='#c0c0c0'
                      label='Valor Desconto'
                      variant='outlined'
                      size='small'
                      style={{ width: 110 }}
                    />
                    <br />
                    <FrDatePicker
                      value={vcto}
                      onChange={handleChangeModal}
                      name="vcto"
                      id="vcto"
                      color="#528b46"
                      label="Data Vencimento"
                      variant="outlined"
                      size="small"
                      style={{ width: 190 }}
                    />
                    <FrSelect
                      value={fcb || ''}
                      onChange={handleChangeModal}
                      name="fcb"
                      id="fcb"
                      data={comboFcb}
                      style={{ width: 200 }}
                    />

                    <br />
                    <FrTextInput
                      maxLength='500'
                      value={obs || ''}
                      onChange={handleChangeModal}
                      name="obs"
                      id="obs"
                      color="#528b46"
                      label="Observações"
                      variant="filled"
                      size="small"
                      style={{ width: 450 }}
                      multiline
                      rows="4"
                      rowsMax="4"
                      fullWidth
                    />
                  </div>
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv" >
                      <button onClick={confirmaModal}> Confirmar </button>
                      <button onClick={escondeModal}> Sair </button>
                    </div>
                  </div>
                </Dialog>
              </div>

            </form>
          </div>

          <br />
          <div className="tableDiv">
            <FrTable somaSelecao={somaSelecao} setSomaSelecao={setSomaSelecao} widthOptions={130} estornar={'S'} mostraCheck={true} regName="itens_cobranca" columns={columns} searched={filtered} codeName="pk_icb" page={'itens_cobranca'} data={data} enableEdit={enableEdit} enableDel={enableDel} setSelected={setSelected} selected={selected} />
          </div>
          <FooterDelphus />
        </div>
      </div >
    </div>
  );
}

export default Itens_Cobranca;
