import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";


const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiInputLabel-formControl': {
      top: '-2px'
    },
    '& label.Mui-focused': {
      color: '#528b46',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#528b46',
      borderRadius: '1'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#528b46',
        borderRadius: '1'
      },
      '&:hover fieldset': {
        borderColor: '#528b46',
        borderRadius: '1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#528b46',
        borderRadius: '1'
      },
      height: '30px',
    },

  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginRight: '6px',
    marginBottom: '10px',
  },
}));

export default function CustomizedInputs(props) {
  const classes = useStyles();

  return (
    <>
      <CssTextField
        className={classes.margin}
        label={props.label}
        variant={props.variant}
        id={props.id}
        size={props.size}
        name={props.name}
        autoFocus={false}
        inputProps={{
          maxLength: props.maxLength,
          pattern: props.pattern,
        }}
        InputProps={{
          endAdornment: (
            (props.unclear) ? <></> :
              <IconButton style={{ background: 'transparent' }} onClick={() => props.onChange({ target: { name: props.name, value: null } })}>
                <ClearIcon />
              </IconButton>
          )
        }}
        InputLabelProps={{
          shrink: true
        }}
        {...props}
        // type="date"
        type={(props.time === 'S') ? "datetime-local" : "date"}
      />
    </>
  );
}