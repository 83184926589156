import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import RegMedicamentos from './RegMedicamentos'
import Dialog from '@material-ui/core/Dialog';
import FrTableReg from '../components/FrTableReg'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Medicamentos(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboGpm, setComboGpm] = useState([{ value: '', display: '' }])
  const [comboLab, setComboLab] = useState([{ value: '', display: '' }])
  const [comboPri, setComboPri] = useState([{ value: '', display: '' }])
  const [showModal, setShowModal] = useState(false)
  const [pk_mdc, setpk_Mdc] = useState('')

  const columns = [
    { id: 'nome_comercial', numeric: false, label: 'Nome Comercial', align: 'left', minWidth: 150 },
    { id: 'nomepri', numeric: false, label: 'Princípio Ativo', align: 'left', minWidth: 150 },
    { id: 'nomelab', numeric: false, label: 'Laboratório', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Medicamentos'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if ((consulta !== 'S') && (!props.modal)) {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          customClass: {
            container: 'my-swal'
          },

          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboGpm(await api.getComboData('grupo_medicamentos'))
        setComboLab(await api.getComboData('laboratorios'))
        setComboPri(await api.getComboData('principio_ativo'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.buscar = true
    if (props.fk_pac)
      queryObject.fk_pac_estoque = props.fk_pac || 0

    setFiltered(true)
    if (!props.modal) {
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/medicamentos?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }

    if (!props.modal) {
      api.get('/medicamentos', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      const auxValues = { ...filter };
      auxValues.fk_pac_estoque = props.fk_pac;
      setFilter(auxValues);
      api.get('/medicamentos', { params: auxValues }).then(r => {
        setData(r.data)
        swal.close()
      })
    }
  }

  function clearForm(e) {
    e.preventDefault();
    if (!props.modal)
      window.history.replaceState({ filtered: false }, 'filter', '/primavera/medicamentos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  function mostraModal(e) {
    e.preventDefault();
    setpk_Mdc(0)
    setShowModal(true)
  }
  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {!props.modal ? <NavBar /> : <></>}
      <div className={props.modal ? "ManutContentModal" : "ManutContent"}>
        <div className="defaultHeader">
          <h3>Medicamentos</h3>
          {!props.modal ? <UserOptions /> : <></>}
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.nome_comercial || ''}
                style={{ width: 300 }}
                name="nome_comercial"
                id="nome_comercial"
                color="#528b46"
                label="Nome Comercial"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrTextInput
                value={filter.nome_generico || ''}
                style={{ width: 300 }}
                name="nome_generico"
                id="nome_generico"
                color="#528b46"
                label="Nome Genérico"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <br />
              <FrSelect
                value={filter.pk_gpm || ''}
                style={{ width: 300 }}
                name="pk_gpm"
                id="pk_gpm"
                label='Grupo de Medicamento'
                data={comboGpm}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.pk_lab || ''}
                style={{ width: 300 }}
                name="pk_lab"
                id="pk_lab"
                label='Laboratório'
                data={comboLab}
                onChange={handleChange}
              />


              <FrSelect
                value={filter.pk_pri || ''}
                style={{ width: 300 }}
                name="pk_pri"
                id="pk_pri"
                label='Princípio Ativo'
                data={comboPri}
                onChange={handleChange}
              />

            </div>

            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' || props.modal ?
                <button onClick={mostraModal}>
                  <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>

                :
                <Link to="/primavera/medicamentos/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">


          {props.modal ?
            <FrTableReg setShowModal={setShowModal} setpk_Mdc={setpk_Mdc} editar={true} f2={true} mdc={props.mdc} setMdc={props.setMdc}
              regName="medicamentos" columns={columns}
              searched={filtered} codeName="pk_mdc" page={'medicamentos'} data={data} enableEdit={enableEdit} enableDel={enableDel} /> :

            <FrTable regName="medicamentos" columns={columns} searched={filtered} codeName="pk_mdc" page={'medicamentos'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
          }
          <div style={{ backgroundColor: 'red', width: '60px', padding: '5px', marginTop: '5px' }}>
            Inativos
          </div>
        </div>

        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>

          <RegMedicamentos pk_mdc={pk_mdc} editar={true} setShowModal={setShowModal} modal={true}></RegMedicamentos>
        </Dialog>

        {!props.modal ? <FooterDelphus /> : <></>}

      </div>
    </div>
  );
}

export default Medicamentos;
