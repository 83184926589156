import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'
import FrTable from '../components/FrTable'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Inventario() {
  const [data, setData] = useState([{ value: '', display: '' }])
  const [comboResidente, setComboResidente] = useState([{ value: '', display: '' }])




  useEffect(() => {
    const fetchData = async () => {
      let pacientes = await api.getComboData('pacientes')
      pacientes.unshift({ display: 'Residencial Primavera', value: 'null' })
      setComboResidente(pacientes)


    }
    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fileName',
      'datamov',
      'fk_pac'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'datamov') fieldName = 'DATA'
      if (curr === 'fileName') fieldName = 'ARQUIVO'
      if (curr === 'fk_pac') fieldName = 'RESIDENTE'

      if (!data[curr]) return errors.push(fieldName)
    })


    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja realizar a importação do arquivo?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.fk_usu = getUserCode()
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          swal.fire({
            html: <FrLoading text="Enviando Arquivo..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          let formData = new FormData();
          formData.append('file', auxValues.arq, auxValues.fileName)
          formData.append('data', JSON.stringify(auxValues))

          api.post('/movimentacao_estoque/inventario', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(async r => {
            if (r.data.substr(0, 5) !== 'Erro!') {
              swal.fire({
                title: 'Concluído!',
                text: 'Importação Concluída!',
                icon: 'success',
              }).then(() => {
                window.location.reload()
              })

            } else
              swal.fire({
                title: 'Erro!',
                text: r.data,
                icon: 'warning',
              })
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja cancelar a importação?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'tipo_importacao':
        auxValues[e.target.name] = e.target.value
        if (e.target.value === 'S') auxValues.fk_qua = null
        break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const selectFile = (e) => {
    e.preventDefault();
    $('#html_btn').click();
  }

  const handleSelectedFile = (e) => {
    let file = e.target.files[0];
    console.log(file)
    let auxValues = { ...data }
    if (file) {
      auxValues.arq = file
      auxValues.fileName = file.name
    } else {
      auxValues.arq = null
      auxValues.fileName = 'Nenhum arquivo selecionado'
    }
    setData(auxValues)
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Importação de Inventário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <FrSelect
            value={data.fk_pac || ''}
            onChange={handleChange}
            name="fk_pac"
            id="fk_pac"
            label='Residente'
            data={comboResidente}
            style={{ width: 300 }}
            required
          />
          <FrDatePicker
            value={(data.datamov || '').split('T')[0]}
            name="datamov"
            id="datamov"
            color="#528b46"
            label="Data Movimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            onChange={handleChange}
          />


          <br />
          <input
            type="file"
            name="arquivo"
            id="html_btn"
            accept=".xls, .xlsx"
            onChange={handleSelectedFile}
          />
          <FrTextInput
            value={data.fileName || ''}
            onChange={handleChange}
            name="fileName"
            id="fileName"
            color="#49573B"
            label="Arquivo"
            variant="outlined"
            size="small"
            disabled
            required
            style={{ width: 300 }}
          />
          <button type="file" className="selectFileButton" onClick={selectFile}>
            Selecionar Arquivo
          </button>
        </form>
        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Importar</button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>


        <FooterDelphus />
      </div>
    </div>
  );
}

export default Inventario;
