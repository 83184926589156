import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Tooltip from '@material-ui/core/Tooltip';
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrImageEdit from '../components/FrImageEdit'

import api from '../services/api'
import consultaCep from '../services/consultaCep'
import { getAge, paramsToObject, validarCPF, onlyNumbers, removeSpecialChar, dateSql, getUserCode } from '../utils/functions'
import { mCPF, mTel, mCEP } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import Tabs from '../components/Tabs';
import FrDatePicker from '../components/FrDatePicker';

const swal = withReactContent(Swal)

function Pacientes() {
  const [data, setData] = useState({})
  const [erroCpf, setErroCpf] = useState(false)
  const [erroCep, setErroCep] = useState(false)
  const [updateType, setUpdateType] = useState(false)
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboCidResp, setComboCidResp] = useState([{ value: '', display: '' }])
  const [comboEstados, setComboEstados] = useState([{ value: '', display: '' }])
  const [blockCidades, setBlockCidades] = useState(true)
  const [comboSexo, setComboSexo] = useState([{ value: '', display: '' }])
  const [comboEstCivil, setComboEstCivil] = useState([{ value: '', display: '' }])
  const [comboCidNat, setComboCidNat] = useState([{ value: '', display: '' }])
  const [comboPro, setComboPro] = useState([{ value: '', display: '' }])
  const [comboPar, setComboPar] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCvn, setComboCvn] = useState([{ value: '', display: '' }])
  const [comboHos, setComboHos] = useState([{ value: '', display: '' }])
  const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [residente, setResidente] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setResidente(auth.residente > 0);

      setComboSexo([
        {
          "value": "F",
          "display": "Feminino",
        },
        {
          "value": "M",
          "display": "Masculino",
        },])

      setComboEstCivil([

        {
          "value": "S",
          "display": "Solteiro(a)",
        },
        {
          "value": "C",
          "display": "Casado(a)",
        },

        {
          "value": "D",
          "display": "Divorciado(a)",
        },

        {
          "value": "V",
          "display": "Viúvo(a)",
        },

        {
          "value": "P",
          "display": "Separado(a)",
        },

      ])

      setComboCidNat(await api.getComboData('cidades'))
      setComboPro(await api.getComboData('profissoes'))
      setComboEstados(await api.getComboData('estados'))
      setComboPar(await api.getComboData('parentesco'))
      setComboCidResp(await api.getComboData('cidades'))
      setComboCvn(await api.getComboData('convenios'))
      setComboHos(await api.getComboData('hospitais'))


      setEnableEdit('S')//await api.getAcessos('Pacientes', 'Alteracao'))


      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/pacientes?pk_pac=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchCidades = async () => {
      if (data.fk_est > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Cidades..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboCidades(await api.getComboData('cidades', data.fk_est))
        swal.close()
        setBlockCidades(false)
      } else setBlockCidades(true)
    }

    fetchCidades()
  }, [data.fk_est])



  function handleEmail(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value.indexOf('@') === -1) {
        swal.fire('Email inválido', 'Informe um email válido!', 'warning')
      }
    }
  }


  useEffect(() => {
    const fetchCidades = async () => {
      if (data.fk_est_resp > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Cidades..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboCidResp(await api.getComboData('cidades', data.fk_est_resp))
        swal.close()
      }
    }

    fetchCidades()
  }, [data.fk_est_resp])


  function handleCpf(e) {
    e.preventDefault();
    if (e.target.value) {
      if (!validarCPF(e.target.value)) {
        setErroCpf(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else setErroCpf(false)
    }
  }

  function handleAge(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };
      auxValues['idade'] = getAge(e.target.value)
      // console.log(today.getDate())
      setData(auxValues)
    }
  }



  function handleCep(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {

        let endereco = 'endereco';
        let bairro = 'bairro';
        let fk_est = 'fk_est';
        let fk_cid = 'fk_cid';

        if (e.target.id !== 'cep') {
          endereco = 'endereco_responsavel';
          bairro = 'bairro_responsavel';
          fk_est = 'fk_est_resp';
          fk_cid = 'fk_cid_responsavel';
        }

        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
          console.log(r)
          if (r.data.erro) {
            setErroCep(true)
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            setErroCep(false)
            const auxValues = { ...data };
            auxValues[endereco] = r.data.logradouro;
            auxValues[bairro] = r.data.bairro;

            await api.get('/estados?uf=' + r.data.uf).then(r => {
              if (r.data.length > 0)
                auxValues[fk_est] = r.data[0].pk_est
            })

            await api.get('/cidades?nome=' + r.data.localidade).then(r => {
              if (r.data.length > 0)
                auxValues[fk_cid] = r.data[0].pk_cid
            })

            setData(auxValues)
          }
        }).catch(err => {
          setErroCep(true)
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }


  function consistData(data) {
    let required = [
      'cpf',
      'cpf_responsavel',
      'nome',
      'nome_responsavel',
      'fone_responsavel',
      'celular_responsavel',
      'cep',
      'cep_responsavel',
      'endereco',
      'endereco_responsavel',
      'numero',
      'numero_responsavel',
      'fk_cid',
      'fk_cid_responsavel',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_cid') fieldName = 'CIDADE'
      if (curr === 'fk_cid_responsavel') fieldName = 'CIDADE DO RESPONSÁVEL'

      if (!data[curr]) return errors.push(fieldName)
    })

    if (erroCep && (errors.indexOf('cep') === -1)) errors.push('cep')
    if (erroCpf && (errors.indexOf('cpf') === -1)) errors.push('cpf')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.cpf = onlyNumbers(auxValues.cpf)
        auxValues.cpf_responsavel = onlyNumbers(auxValues.cpf_responsavel)
        auxValues.fone_responsavel = onlyNumbers(auxValues.fone_responsavel)
        auxValues.fone = onlyNumbers(auxValues.fone)
        auxValues.cep = onlyNumbers(auxValues.cep)
        auxValues.cep_responsavel = onlyNumbers(auxValues.cep_responsavel)
        auxValues.data_nasc = dateSql(auxValues.data_nasc)
        if (auxValues.cvn_carteira_validade)
          auxValues.cvn_carteira_validade = dateSql(data.cvn_carteira_validade)

        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/pacientes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then(async (result) => {
                  window.history.back()
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            auxValues.fk_usu = getUserCode()
            api.post('/pacientes/add', auxValues).then(r => {
              console.log(r.data)
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  window.history.back()
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    if(residente){
      window.history.back()
    } else {
      swal.fire({
        title: 'ATENÇÃO',
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          window.history.back()
        }
      })
    }
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cpf':
      case 'cpf_responsavel':
        auxValues[e.target.name] = mCPF(e.target.value)
        break

      case 'fone':
      case 'fone_responsavel':
      case 'celular':
      case 'celular_responsavel':
        auxValues[e.target.name] = mTel(e.target.value)
        break

      case 'cep':
      case 'cep_responsavel':

        auxValues[e.target.name] = mCEP(e.target.value)
        break

      case 'nome':

      case 'nome_responsavel':
        auxValues[e.target.name] = removeSpecialChar(e.target.value);
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleImageChange = (image) => {
    const auxValues = { ...data };
    auxValues['foto'] = image
    setData(auxValues)
  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Residente</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <Tabs aba1_caption="IDENTIFICAÇÃO" aba1_hidden={false} Aba1={<div>

            <div class="row">

              <div class="column">

                <FrTextInput
                  error={erroCpf}
                  maxLength='18'
                  value={data.cpf || ''}
                  onChange={handleChange}
                  onBlur={handleCpf}
                  name='cpf'
                  id='cpf'
                  color='#528b46'
                  label='CPF'
                  variant='outlined'
                  size='small'
                  disabled={updateType}
                  required
                  style={{ width: 130 }}
                />

                <FrTextInput
                  maxLength='150'
                  value={data.nome || ''}
                  onChange={handleChange}
                  name='nome'
                  id='nome'
                  color='#528b46'
                  label='Nome'
                  variant='outlined'
                  size='small'
                  required
                  style={{ width: 500 }}
                />

                <br />
                <FrTextInput
                  maxLength='150'
                  value={data.rg || ''}
                  onChange={handleChange}
                  name='rg'
                  id='rg'
                  color='#528b46'
                  label='RG'
                  variant='outlined'
                  size='small'
                  style={{ width: 170 }}
                />
                <FrDatePicker
                  value={(data.data_nasc || '').split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleAge}
                  name="data_nasc"
                  id="data_nasc"
                  color="#528b46"
                  label="Data Nascimento"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                />
                <FrTextInput
                  maxLength='50'
                  value={data.idade || ''}
                  onChange={handleChange}
                  name='idade'
                  id='idade'
                  color='#528b46'
                  label='Idade'
                  variant='outlined'
                  size='small'
                  disabled
                  style={{ width: 50 }}
                />

                <FrSelect
                  value={data.sexo || ''}
                  onChange={handleChange}
                  name="sexo"
                  id="sexo"
                  label='Sexo'
                  data={comboSexo}
                  style={{ width: 100 }}
                />

                <FrSelect
                  value={data.estado_civil || ''}
                  onChange={handleChange}
                  name="estado_civil"
                  id="estado_civil"
                  label='Estado Civil'
                  data={comboEstCivil}
                  style={{ width: 130 }}
                />

                <br />
                <FrSelect
                  value={data.fk_cid_natural || ''}
                  onChange={handleChange}
                  name="fk_cid_natural"
                  id="fk_cid_natural"
                  label='Naturalidade'
                  data={comboCidNat}
                  style={{ width: 250 }}
                />


                <FrSelect
                  value={data.fk_pro || ''}
                  onChange={handleChange}
                  name="fk_pro"
                  id="fk_pro"
                  label='Profissão'
                  data={comboPro}
                  style={{ width: 250 }}
                />

                <br />

                <FrTextInput
                  maxLength='10'
                  value={data.cep || ''}
                  error={erroCep}
                  onChange={handleChange}
                  onBlur={handleCep}
                  name="cep"
                  id="cep"
                  color="#528b46"
                  label="CEP"
                  variant="outlined"
                  size="small"
                  style={{ width: 130 }}
                  required
                />
                <FrTextInput
                  maxLength='100'
                  value={data.endereco || ''}
                  onChange={handleChange}
                  fullWidth
                  name="endereco"
                  id="endereco"
                  color="#528b46"
                  label="Rua"
                  variant="outlined"
                  size="small"
                  style={{ width: 450 }}
                  required
                />
                <FrTextInput
                  maxLength='45'
                  value={data.numero || ''}
                  onChange={handleChange}
                  name="numero"
                  id="numero"
                  color="#528b46"
                  label="Número"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 80 }}
                />
                <FrTextInput
                  maxLength='45'
                  value={data.complemento || ''}
                  onChange={handleChange}
                  name="complemento"
                  id="complemento"
                  color="#528b46"
                  label="Complemento"
                  variant="outlined"
                  size="small"
                  style={{ width: 150 }}
                />
                <br />
                <FrTextInput
                  maxLength='45'
                  value={data.bairro || ''}
                  onChange={handleChange}
                  name="bairro"
                  id="bairro"
                  color="#528b46"
                  label="Bairro"
                  variant="outlined"
                  size="small"
                  style={{ width: 200 }}
                />

                <FrSelect
                  value={data.fk_est || ''}
                  onChange={handleChange}
                  name="fk_est"
                  id="fk_est"
                  label='Estado'
                  data={comboEstados}
                  style={{ width: 250 }}
                />
                <Tooltip title={blockCidades ? 'Informe um Estado antes de informar a Cidade!' : ''}>
                  <FrSelect
                    value={data.fk_cid || ''}
                    onChange={handleChange}
                    name="fk_cid"
                    id="fk_cid"
                    label='Cidade'
                    data={comboCidades}
                    style={{ width: 300 }}
                    disabled={blockCidades}
                    required
                  />
                </Tooltip>
                <br />
                <FrTextInput
                  maxLength='45'
                  value={data.fone || ''}
                  onChange={handleChange}
                  name="fone"
                  id="fone"
                  color="#528b46"
                  label="Fone"
                  variant="outlined"
                  size="small"
                  style={{ width: 200 }}
                />
                <FrTextInput
                  maxLength='45'
                  value={data.celular || ''}
                  onChange={handleChange}
                  name="celular"
                  id="celular"
                  color="#528b46"
                  label="Celular"
                  variant="outlined"
                  size="small"
                  style={{ width: 200 }}
                />

              </div>

              <div class="column">
                <div className="regButtonsTab" >
                  <FrImageEdit image={data.foto} pk_pac={(data.pk_pac) > 0 ? data.pk_pac : 999999} onImageChange={handleImageChange} />
                </div>
              </div>

            </div>

            <div class="row">

              <br />

              <strong>Responsável</strong>
              <hr width="100%" size="50" color=" #528b46" />
              <br />

              <FrTextInput
                error={erroCpf}
                maxLength='18'
                value={data.cpf_responsavel || ''}
                onChange={handleChange}
                onBlur={handleCpf}
                name='cpf_responsavel'
                id='cpf_responsavel'
                color='#528b46'
                label='CPF'
                variant='outlined'
                size='small'
                disabled={updateType}
                required
                style={{ width: 180 }}
              />
              <FrTextInput
                maxLength='150'
                value={data.nome_responsavel || ''}
                onChange={handleChange}
                name='nome_responsavel'
                id='nome_responsavel'
                color='#528b46'
                label='Nome'
                variant='outlined'
                size='small'
                required
                style={{ width: 650 }}
              />
              <br />
              <FrTextInput
                maxLength='150'
                value={data.rg_responsavel || ''}
                onChange={handleChange}
                name='rg_responsavel'
                id='rg_responsavel'
                color='#528b46'
                label='RG'
                variant='outlined'
                size='small'
                style={{ width: 300 }}
              />
              <FrSelect
                value={data.fk_par || ''}
                onChange={handleChange}
                name="fk_par"
                id="fk_par"
                label='Grau de Parentesco'
                data={comboPar}
                style={{ width: 250 }}
              />
              <br />

              <FrTextInput
                maxLength='10'
                value={data.cep_responsavel || ''}
                error={erroCep}
                onChange={handleChange}
                onBlur={handleCep}
                name="cep_responsavel"
                id="cep_responsavel"
                color="#528b46"
                label="CEP"
                variant="outlined"
                size="small"
                style={{ width: 130 }}
                required
              />
              <FrTextInput
                maxLength='100'
                value={data.endereco_responsavel || ''}
                onChange={handleChange}
                fullWidth
                name="endereco_responsavel"
                id="endereco_responsavel"
                color="#528b46"
                label="Rua"
                variant="outlined"
                size="small"
                style={{ width: 700 }}
                required
              />
              <FrTextInput
                maxLength='45'
                value={data.numero_responsavel || ''}
                onChange={handleChange}
                name="numero_responsavel"
                id="numero_responsavel"
                color="#528b46"
                label="Número"
                variant="outlined"
                size="small"
                required
                style={{ width: 100 }}
              />
              <FrTextInput
                maxLength='45'
                value={data.complemento_responsavel || ''}
                onChange={handleChange}
                name="complemento_responsavel"
                id="complemento_responsavel"
                color="#528b46"
                label="Complemento"
                variant="outlined"
                size="small"
                style={{ width: 200 }}
              />
              <br />
              <FrTextInput
                maxLength='45'
                value={data.bairro_responsavel || ''}
                onChange={handleChange}
                name="bairro_responsavel"
                id="bairro_responsavel"
                color="#528b46"
                label="Bairro"
                variant="outlined"
                size="small"
                style={{ width: 200 }}
              />

              <FrSelect
                value={data.fk_est_resp || ''}
                onChange={handleChange}
                name="fk_est_resp"
                id="fk_est_resp"
                label='Estado'
                data={comboEstados}
                style={{ width: 250 }}
              />
              <FrSelect
                value={data.fk_cid_responsavel || ''}
                onChange={handleChange}
                name="fk_cid_responsavel"
                id="fk_cid_responsavel"
                label='Cidade'
                data={comboCidResp}
                style={{ width: 300 }}
                required
              />
              <br />
              <FrTextInput
                maxLength='45'
                value={data.fone_responsavel || ''}
                onChange={handleChange}
                name="fone_responsavel"
                id="fone_responsavel"
                color="#528b46"
                label="Fone"
                variant="outlined"
                size="small"
                required
                style={{ width: 200 }}
              />
              <FrTextInput
                maxLength='45'
                value={data.celular_responsavel || ''}
                onChange={handleChange}
                name="celular_responsavel"
                id="celular_responsavel"
                color="#528b46"
                label="Celular"
                variant="outlined"
                size="small"
                required
                style={{ width: 200 }}
              />

              <FrTextInput
                maxLength='45'
                value={data.email_responsavel || ''}
                onChange={handleChange}
                onBlur={handleEmail}
                name="email_responsavel"
                id="email_responsavel"
                color="#528b46"
                label="Email"
                variant="outlined"
                size="small"
                style={{ width: 350 }}
              />

            </div>

            <br />

            <strong>Outras Informações</strong>
            <hr width="100%" size="50" color=" #528b46" />
            <br />

            <FrSelect
              value={data.fk_cvn || ''}
              onChange={handleChange}
              name="fk_cvn"
              id="fk_cvn"
              label='Convênio'
              data={comboCvn}
              style={{ width: 250 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.cvn_carteira_num || ''}
              onChange={handleChange}
              name="cvn_carteira_num"
              id="cvn_carteira_num"
              color="#528b46"
              label="Nº Carteira"
              variant="outlined"
              size="small"
              style={{ width: 200 }}
            />

            <FrDatePicker
              value={(data.cvn_carteira_validade || '').split('T')[0]}
              onChange={handleChange}
              name="cvn_carteira_validade"
              id="cvn_carteira_validade"
              color="#528b46"
              label="Data Validade"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.cartao_sus || ''}
              onChange={handleChange}
              name="cartao_sus"
              id="cartao_sus"
              color="#528b46"
              label="Cartão SUS"
              variant="outlined"
              size="small"
              style={{ width: 200 }}
            />

            <FrSelect
              value={data.fk_hos || ''}
              onChange={handleChange}
              name="fk_hos"
              id="fk_hos"
              label='Hospital de Referência'
              data={comboHos}
              style={{ width: 250 }}
            />

          </div>}

          >
          </Tabs>


        </form>
        <div className="regButtonsDiv">
          {residente ? <></> : (enableEdit === 'S' ? (<button onClick={handleSubmit}>Salvar</button>) : (<></>))}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Pacientes;
