import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, asyncForEach } from '../utils/functions'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import ReactExport from "react-data-export";
import { mMoeda, mMoedaNovo } from '../utils/masks';

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Mov_Bco() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboCbc, setComboCbc] = useState([{ value: '', display: '' }])
  const [saldoIni, setSaldoIni] = useState(0)
  const [saldoFim, setSaldoFim] = useState(0)
  const [totEntradas, setEntradas] = useState(0)
  const [totSaidas, setSaidas] = useState(0)


  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const columns = [
    { id: 'format_data', numeric: false, label: 'Data Movto', align: 'left', minWidth: 80, order: 'data' },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 80, order: 'valor' },
    { id: 'cred_deb', numeric: false, label: 'C/D', align: 'left', minWidth: 50 },
    { id: 'historico', numeric: true, label: 'Histórico', align: 'left', minWidth: 80 },
    { id: 'format_saldo', numeric: false, label: 'Saldo', align: 'left', minWidth: 80, order: 'saldo_final' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Movimentações Bancárias'
      let consulta = await api.getAcessos(tela, 'Consulta')
      setComboCbc(await api.getComboData('contas_bancarias'))

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar == true) {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    if (filter.fk_cbc) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/mov_bco?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/mov_bco', { params: queryObject }).then(r => {
        setData(r.data)
        let entradas = 0;
        let saidas = 0
        if (r.data.length > 0)
          setSaldoIni(r.data[0].saldo_ant)
        else
          swal.close()
        asyncForEach(r.data, async (mbc, index) => {
          if (mbc.cred_deb == 'C')
            entradas = entradas + mbc.valor
          else
            saidas = saidas + mbc.valor



          if (index + 1 == r.data.length) {
            setEntradas(entradas)
            setSaidas(saidas)
            setSaldoFim(mbc.saldo_final)
            swal.close()
          }
        })

      })

    } else {
      swal.fire('Nenhum filtro informado!', 'Informe a conta.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/mov_bco');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;

    setFilter(auxValues);
  };


  return (

    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Movimentações Bancárias</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">

          <form id="searchForm" onSubmit={getData} >
            <div>
              <FrSelect
                value={filter.fk_cbc || ''}
                name="fk_cbc"
                id="fk_cbc"
                label='Conta'
                data={comboCbc}
                style={{ width: 250 }}
                onChange={handleChange}
              />


              <FrDatePicker
                value={(filter.dataini_emissao || '').split('T')[0]}
                name="dataini_emissao"
                id="dataini_emissao"
                color="#528b46"
                label="Período de Movimento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.datafim_emissao || '').split('T')[0]}
                name="datafim_emissao"
                id="datafim_emissao"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/primavera/mov_bco/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
              <ExcelFile element={<button onClick={console.log(data)}>
                <Icon icon={fileExcel} size={18} />
                Exportar</button>} filename="Movimentações Bancárias">
                <ExcelSheet data={data} name="Relatório Contas Receber">
                  <ExcelColumn label="Data Movto" value="format_data" />
                  <ExcelColumn label="Valor" value="format_valor" />
                  <ExcelColumn label="C/D" value="cred_deb" />
                  <ExcelColumn label="Histórico" value="historico" />
                  <ExcelColumn label="Saldo" value="format_saldo" />
                </ExcelSheet>
              </ExcelFile>

              <br />
              <label style={{ paddingLeft: '10px' }}>Saldo Inicial:</label>
              <label style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}> {mMoedaNovo(saldoIni)}</label>
              <label style={{ paddingLeft: '10px' }}>Entradas:</label>
              <label style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}>{mMoedaNovo(totEntradas)}</label>
              <label style={{ paddingLeft: '10px' }}>Saídas:</label>
              <label style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}>{mMoedaNovo(totSaidas)}</label>
              <label style={{ paddingLeft: '10px' }}>Saldo Final:</label>
              <label style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: 'large' }}> {mMoedaNovo(saldoFim)}</label>


            </div>
          </form>
        </div>
        {(<br />)}
        <div className={"tableDiv"}>
          <FrTable startOrderBy={'data'} startOrder={'asc'} mostraCheck={false} regName="mov_bco" columns={columns} searched={filtered} codeName="pk_mbc" page={'mov_bco'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div >
    </div >
  )
}

export default Mov_Bco;
