import React, { useState, useEffect } from 'react';
import './index.css';
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import Residente from '../../pages/ModalBuscaResidente'
import Profissional from '../../pages/ModalBuscaProfissional'
// import Medicamento from '../../pages/ModalBuscaMedicamento'
import Material from '../../pages/ModalBuscaMaterial';
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import Fornecedor from '../../pages/Fornecedores'
import Compromissos from '../../pages/ModalBuscaAgenda';
import '../../pages/styles/DefaultMainScreen.css';
import { ic_person } from 'react-icons-kit/md/ic_person'
import api from '../../services/api';
import Tomador from '../../pages/Tomador';
import Principio_Ativo from '../../pages/Principio_Ativo'
import Medicamento from '../../pages/Medicamentos'


function FrameF2(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [showModal2, setShowModal2] = React.useState(false);
    const [tipoModal, setTipoModal] = useState(props.tipoModal);
    const [pac, setPac] = useState(0);
    const [prf, setPrf] = useState(0);
    const [mdc, setMdc] = useState(0);
    const [mat, setMat] = useState(0);
    const [cad, setCad] = useState(0);
    const [date, setDate] = useState(props.date)
    const [arquivo, setArquivo] = useState();
    const [pri, setPri] = useState(0);

    let auth = JSON.parse(localStorage['authPrimavera']);


    function mostraModal(e) {
        e.preventDefault()
        console.log(props.tipoModal)
        setShowModal(true)

    }

    useEffect(() => {
        console.log(date, props);
        setDate(props.date)
    }, [date]);


    useEffect(() => {
        const fetchPac = async () => {
            if (pac > 0) {
                props.setPac(pac)
                setShowModal(false)
            }
        }
        fetchPac()
    }, [pac])

    useEffect(() => {
        const fetchPrf = async () => {
            if (prf > 0) {
                props.setPrf(prf)
                setShowModal(false)
            }
        }
        fetchPrf()
    }, [prf])

    useEffect(() => {
        const fetchMdc = async () => {
            if (mdc > 0) {
                props.setMdc(mdc)
                setShowModal(false)
            }
        }
        fetchMdc()
    }, [mdc])

    useEffect(() => {
        const fetchMat = async () => {
            if (mat > 0) {
                props.setMat(mat)
                setShowModal(false)
            }
        }
        fetchMat()
    }, [mat])

    useEffect(() => {
        const fetchCad = async () => {
            if (cad > 0) {
                props.setCad(cad)
                setShowModal(false)
            }
        }
        fetchCad()
    }, [cad])



    useEffect(() => {
        const fetchPri = async () => {
            if (pri > 0) {
                props.setPri(pri)
                setShowModal(false)
            }
        }
        fetchPri()
    }, [pri])

    function escondeModal(e) {
        e.preventDefault()
        setShowModal(false)
    }

    function escondeModal2(e) {
        e.preventDefault()
        setShowModal2(false)
    }

    const imprimeCompromissos = async () => {
        setShowModal(false)
        setArquivo(await api.backendUrl + '/api/agenda/imprimeComp?data=' + props.date);
        setShowModal2(true);
    }

    return (
        <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>

                {(tipoModal === 'Residente') || (tipoModal === '') ? <Residente pac={pac} setPac={setPac}></Residente> :
                    (tipoModal === 'Agenda') ? <Compromissos date={props.date} /> :
                        (tipoModal === 'Medicamento') ? <Medicamento modal={true} mdc={mdc} setMdc={setMdc} fk_pac={props.fk_pac}></Medicamento>
                            :
                            (tipoModal === 'Material') ? <Material mat={mat} setMat={setMat}></Material>
                                :
                                (tipoModal === 'Fornecedor') ? <Fornecedor modal={true} cad={cad} setCad={setCad}></Fornecedor>
                                    :
                                    (tipoModal === 'Tomador') ? <Tomador modal={true} cad={cad} setCad={setCad}></Tomador>

                                        :

                                        (tipoModal === 'Principio_Ativo') ? <Principio_Ativo modal={true} pri={pri} setPri={setPri}></Principio_Ativo>
                                            :

                                            <Profissional prf={prf} setPrf={setPrf}></Profissional>}





                <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                        {tipoModal == 'Agenda' ? <button onClick={imprimeCompromissos}>Imprimir</button> : <></>}
                        <button onClick={escondeModal}> Sair </button>
                    </div>
                </div>
            </Dialog>
            {(tipoModal === 'Agenda') ?
                <button hidden={auth.residente > 0} className="buttonAgenda" onClick={mostraModal}>
                    <Icon icon={ic_person} size={18} />
                    Comp. Individuais
                </button>
                :
                <button hidden={(showModal)} className="buttonF2" style={{ width: '35px' }} onClick={mostraModal}>
                    <Icon icon={ic_search} size={20} />
                </button>
            }
            {tipoModal == 'Agenda' ?
                <div>
                    <Dialog aria-labelledby="simple-dialog-title" open={showModal2} fullWidth={true} maxWidth={'lg'}>
                        <div className="FrModalFooter">
                            <div className="FrModalButtonsDiv">
                                <MDBContainer className="text-center">
                                    <MDBIframe src={arquivo} />
                                </MDBContainer>
                                <button onClick={escondeModal2}> Sair </button>
                            </div>
                        </div>
                    </Dialog>
                </div>
                : <></>}
        </div>
    );
}

export default FrameF2;