import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import consultaCep from '../services/consultaCep'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'

import api from '../services/api'
import { onlyNumbers, paramsToObject } from '../utils/functions'
import { mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'


const swal = withReactContent(Swal)

function Tomador(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])


  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_cad ? props.pk_cad : query.codigo

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Tomadores', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Tomadores', 'Inclusao'))
      }

      setComboCid(await api.getComboData('cidades'))
      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/tomador?pk_tom=' + codigo).then(r => {
          if (r.data[0]) {
            r.data[0].cpf = mCPF(r.data[0].cpf)
            setData(r.data[0])
          } else {
            swal.fire({
              customClass: {
                container: 'my-swal'
              },
              title: 'Erro!',
              text: 'Código informado não encontrado!',
              icon: 'warning',
            })
              .then((result) => {
                if (result.value) {
                  if (!props.modal)
                    window.history.back()
                }
              })
          }
        })
      }
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'cpf',
      'nome',
      'bairro',
      'cep',
      'endereco',
      'numero',
      'fk_cid'

    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr == 'fk_cid')
        fieldName = 'Cidade'

      if (!data[curr]) return errors.push(fieldName)

    })

    return errors
  }


  async function temCpf(cpf, pk_tom) {
    if (!pk_tom)
      pk_tom = 0

    let errors = []

    await api.get('/tomador?codTom=' + pk_tom + '&cpf=' + cpf).then(r => {
      if (r.data.length > 0)
        errors.push('Já existe cadastro de tomador com este CPF!')

      return errors
    })
  }



  function handleCep(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {

        let endereco = 'endereco';
        let bairro = 'bairro';
        let fk_est = 'fk_est';
        let fk_cid = 'fk_cid';

        if (e.target.id !== 'cep') {
          endereco = 'endereco_responsavel';
          bairro = 'bairro_responsavel';
          fk_est = 'fk_est_resp';
          fk_cid = 'fk_cid_responsavel';
        }

        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
          console.log(r)
          if (r.data.erro) {
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            const auxValues = { ...data };
            auxValues[endereco] = r.data.logradouro;
            auxValues[bairro] = r.data.bairro;

            await api.get('/estados?uf=' + r.data.uf).then(r => {
              if (r.data.length > 0)
                auxValues[fk_est] = r.data[0].pk_est
            })

            await api.get('/cidades?nome=' + r.data.localidade).then(r => {
              if (r.data.length > 0)
                auxValues[fk_cid] = r.data[0].pk_cid
            })

            setData(auxValues)
          }
        }).catch(err => {
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }


  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.cpf = onlyNumbers(auxValues.cpf)
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          api.get('/tomador?codTom=' + auxValues.pk_tom + '&cpf=' + auxValues.cpf).then(r => {
            if (r.data[0]) {
              swal.fire(
                {
                  customClass: {
                    container: 'my-swal'
                  },
                  title: 'Erro!',
                  text: 'Já existe cadastro de tomador com este CPF!',
                  icon: 'warning',
                })
            }

            else {

              if (updateType) {
                api.post('/tomador/edit', auxValues).then(r => {
                  if (r.status === 200) {
                    swal.fire({
                      customClass: {
                        container: 'my-swal'
                      },
                      title: 'Registro Alterado',
                      text: "O registro foi alterado com sucesso!",
                      icon: 'success',
                    }).then((result) => {
                      if (result) {
                        if (props.modal)
                          props.setShowModal(false)

                        else
                          window.history.back()
                      }
                    })
                  } else {
                    swal.fire({
                      customClass: {
                        container: 'my-swal'
                      },
                      title: 'Erro!',
                      text: "O registro não foi alterado!",
                      icon: 'warning',
                    })
                  }
                })
              } else {
                api.post('/tomador/add', auxValues).then(r => {
                  if (r.status === 200) {
                    swal.fire(
                      {
                        customClass: {
                          container: 'my-swal'
                        },
                        title: 'Registro Inserido',
                        text: "O registro foi inserido com sucesso!",
                        icon: 'success',
                      }).then((result) => {
                        if (result.value) {
                          if (props.modal) {
                            if (props.setShowModal)
                              props.setShowModal(false)
                          } else
                            window.history.back()
                        }
                      })
                  } else {
                    swal.fire(
                      {
                        customClass: {
                          container: 'my-swal'
                        },
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                      })
                  }
                })
              }
            }
          })
        } else {
          swal.fire(
            {
              customClass: {
                container: 'my-swal'
              },
              title: 'Erro!',
              text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })

        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value);
        break


      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">

      {!props.modal ? <NavBar /> : <></>}
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Tomador</h3>
          {!props.modal ? <UserOptions /> : <></>}
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Situação'
            data={comboAtivo}
            style={{ width: 80 }}
            hidden={!updateType}
          />

          <br />
          <FrTextInput
            maxLength='100'
            value={data.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            color="#528b46"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: 430 }}
          />


          <FrTextInput
            maxLength='14'
            value={data.cpf || ''}
            onChange={handleChange}
            name="cpf"
            id="cpf"
            color="#528b46"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
          />

          <br />


          <FrTextInput
            maxLength='8'
            value={data.cep || ''}
            onChange={handleChange}
            name="cep"
            id="cep"
            color="#528b46"
            label="CEP"
            variant="outlined"
            size="small"
            onBlur={handleCep}
            style={{ width: 100 }}
          />


          <FrTextInput
            maxLength='100'
            value={data.endereco || ''}
            onChange={handleChange}
            name="endereco"
            id="endereco"
            color="#528b46"
            label="Endereço"
            variant="outlined"
            size="small"
            style={{ width: 400 }}
          />


          <FrTextInput
            maxLength='100'
            value={data.numero || ''}
            onChange={handleChange}
            name="numero"
            id="numero"
            color="#528b46"
            label="Nº"
            variant="outlined"
            size="small"
            style={{ width: 100 }}
          />


          <FrTextInput
            maxLength='50'
            value={data.complemento || ''}
            onChange={handleChange}
            name="complemento"
            id="complemento"
            color="#528b46"
            label="Complemento"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.bairro || ''}
            onChange={handleChange}
            name="bairro"
            id="bairro"
            color="#528b46"
            label="Bairro"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrSelect
            value={data.fk_cid || ''}
            onChange={handleChange}
            name="fk_cid"
            id="fk_cid"
            label='Cidade'
            data={comboCid}
            style={{ width: 200 }}
          />


          <br />

          <FrTextInput
            maxLength='100'
            value={data.fone || ''}
            onChange={handleChange}
            name="fone"
            id="fone"
            color="#528b46"
            label="Fone"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.celular || ''}
            onChange={handleChange}
            name="celular"
            id="celular"
            color="#528b46"
            label="Celular"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.email || ''}
            onChange={handleChange}
            name="email"
            id="email"
            color="#528b46"
            label="E-mail"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Tomador;
