import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { logIn } from 'react-icons-kit/feather/logIn'
import { logOut } from 'react-icons-kit/feather/logOut'
import { ic_home } from 'react-icons-kit/md/ic_home'
import { calendar } from 'react-icons-kit/fa/calendar'
import { MDBIcon } from 'mdbreact';
import { book } from 'react-icons-kit/fa/book'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import { ic_playlist_add_check } from 'react-icons-kit/md/ic_playlist_add_check'
import { ic_list } from 'react-icons-kit/md/ic_list'
import api from '../services/api'
import { ic_local_hotel } from 'react-icons-kit/md/ic_local_hotel'
import { statsBars2 } from 'react-icons-kit/icomoon/statsBars2'
import { refresh } from 'react-icons-kit/fa/refresh'
import { database } from 'react-icons-kit/fa/database'
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { calculator } from 'react-icons-kit/icomoon/calculator'
import { fileText } from 'react-icons-kit/icomoon/fileText'
import { cutlery } from 'react-icons-kit/fa/cutlery';
import { eyedropper } from 'react-icons-kit/icomoon/eyedropper'
import { addressBook } from 'react-icons-kit/icomoon/addressBook'
import { barcode } from 'react-icons-kit/icomoon/barcode'

function Home() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [enablePac, setEnablePac] = useState('N')
  const [enableAdmissao, setEnableAdmissao] = useState('N')
  const [enablePrescricao, setEnablePrescricao] = useState('N')
  const [enableAgenda, setEnableAgenda] = useState('N')
  const [enableChkIn, setEnableChkIn] = useState('N')
  const [enableChkOut, setEnableChkOut] = useState('N')
  const [enableCheckList, setEnableCheckList] = useState('N')
  const [enableEvolucao, setEnableEvolucao] = useState('N')
  const [enableInternacao, setEnableInternacao] = useState('N')
  const [enableCtr, setEnableCtr] = useState('N')
  const [enableCtp, setEnableCtp] = useState('N')
  const [enableIcb, setEnableIcb] = useState('N')
  const [residente, setResidente] = useState(false)
  const [data, setData] = useState([]);
  const [enableEst, setEnableEst] = useState('N')
  const [residentesAtivos, setResidentesAtivos] = useState('')
  const [enableNtf, setEnableNtf] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      if (auth.residente > 0) {
        setEnableAgenda('S')
        setEnableCtr('S')
        setEnableEst(await api.getMostraEstoque(auth.residente))

      } else {
        api.get('/pacientes/qtdAtivos').then(r => {
          if (r.data.length > 0) {
            setResidentesAtivos(r.data[0].residentes)
          }
        })
        if (data.length == 0)
          setData(await api.getAcessosConsultas())

        Object.keys(data).forEach(async key => {
          if (data[key].tela == 'Pacientes')
            setEnablePac(data[key].consulta)

          if (data[key].tela == 'Internação')
            setEnableInternacao(data[key].consulta)

          if (data[key].tela == 'Admissão')
            setEnableAdmissao(data[key].consulta)

          if (data[key].tela == 'Prescrição Médica')
            setEnablePrescricao(data[key].consulta)

          if (data[key].tela == 'Agenda')
            setEnableAgenda(data[key].consulta)

          if (data[key].tela == 'CheckIn')
            setEnableChkIn(data[key].consulta)

          if (data[key].tela == 'CheckOut')
            setEnableChkOut(data[key].consulta)

          if (data[key].tela == 'CheckList')
            setEnableCheckList(data[key].consulta)

          if (data[key].tela == 'Consulta Evolução')
            setEnableEvolucao(data[key].consulta)

          if (data[key].tela == 'Contas a Pagar')
            setEnableCtp(data[key].consulta)

          if (data[key].tela == 'Contas a Receber')
            setEnableCtr(data[key].consulta)

          if (data[key].tela == 'Itens de Cobrança')
            setEnableIcb(data[key].consulta)

          if (data[key].tela == 'Movimentação de Estoques')
            setEnableIcb(data[key].consulta)

          if (data[key].tela == 'Resumo Evoluções')
            setEnableIcb(data[key].consulta)

          if (data[key].tela == 'Estoques') {
            setEnableEst(data[key].consulta)
          }

          if (data[key].tela == 'Notas Fiscais') {
            setEnableNtf(data[key].consulta)
          }

        });
      }
    }
    fetchData()
  }, [data])


  function openData() {
    let url = 'https://analytics.zoho.com/open-view/2256913000004946967'
    window.open(url)
  }


  return (
    <div className={"Home" + (residente ? '-app' : '')}>
      <NavBar />

      <div className={"HomeContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>Seja bem vindo(a), <strong>{auth.name}</strong>. Seu último login foi em {auth.ultimo_login}.<label className='labelHeader'> Residentes Ativos:<label className='labelResidentes'>{residentesAtivos}</label></label></h3>)}
          <UserOptions />
        </div>

        <div className="atalhos">
          <div className='gridAtalhos' hidden={auth.residente > 0}>
            {(enablePac == 'S') ?
              <div class="coluna">
                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/pacientes" className="atalho" id="button">
                    <Icon icon={iosPeople} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Residentes'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>
            }

            {(enableInternacao == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/internacao" className="atalho" id="button">
                    <Icon icon={ic_local_hotel} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Internação'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>
            }
            {(enableAdmissao == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/admissao" className="atalho" id="button">
                    <Icon icon={ic_home} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Admissão'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableChkIn == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/checkin" className="atalho" id="button">
                    <Icon icon={logIn} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Check In'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableChkOut == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/checkout" className="atalho" id="button">
                    <Icon icon={logOut} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Check Out'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enablePrescricao == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/prescricao_medica?situacao=Ativas" className="atalho" id="button">
                    <MDBIcon icon="prescription-bottle-alt" size="3x" />
                    <strong>{'Prescrição Médica'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableAgenda == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/agenda" className="atalho" id="button">
                    <Icon icon={calendar} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Agenda'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableEvolucao == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/consulta_evolucao" className="atalho" id="button">
                    <Icon icon={statsBars2} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Evolução'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableCtp == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/contas_pagar" className="atalho" id="button">
                    <Icon icon={calculator} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Contas a Pagar'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableCtr == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/contas_receber" className="atalho" id="button">
                    <Icon icon={coinDollar} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{(auth.residente > 0) ? 'Financeiro' : 'Contas a Receber'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableIcb == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/itens_cobranca" className="atalho" id="button">
                    <Icon icon={ic_list} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Itens de Cobrança'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableIcb == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/movimentacao_estoque" className="atalho" id="button">
                    <Icon icon={refresh} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Movimentação de Estoques'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableIcb == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/resumo_evolucoes" className="atalho" id="button">
                    <Icon icon={fileText} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Resumo de Evoluções'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableIcb == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/home" className="atalho" onClick={openData} id="button">
                    <Icon icon={database} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Dados'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableEst == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/estoques" className="atalho" id="button">
                    <Icon icon={eyedropper} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Estoques'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}

            {(enableNtf == 'S') ?
              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/nota_fiscal" className="atalho" id="button">
                    <Icon icon={barcode} size={(auth.residente > 0) ? 30 : 40} />
                    <strong>{'Notas Fiscais'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>
              : <></>}


          </div>

          <div className='gridAtalhos' hidden={auth.residente == 0}>
            <div class="coluna">

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href={"/primavera/pacientes/registro_familiar?codigo=" + auth.residente} className="atalho" id="button">
                  <Icon icon={calendar} size={(auth.residente > 0) ? 30 : 40} />
                  <strong>{'Cadastro'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>


            <div class="coluna">

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/primavera/agenda" className="atalho" id="button">
                  <Icon icon={calendar} size={(auth.residente > 0) ? 30 : 40} />
                  <strong>{'Agenda'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>

            <div class="coluna">

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/primavera/contas_receber" className="atalho" id="button">
                  <Icon icon={coinDollar} size={(auth.residente > 0) ? 30 : 40} />
                  <strong>{(auth.residente > 0) ? 'Financeiro' : 'Contas a Receber'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>

            {(enableEst == 'S') ?

              <div class="coluna">

                <Tooltip title="Segure Ctrl para abrir em nova aba">
                  <Nav.Link href="/primavera/estoques" className="atalho" id="button">
                    <MDBIcon icon="prescription-bottle-alt" size='2x' />
                    <strong>{'Estoque'}</strong>
                  </Nav.Link>
                </Tooltip>
              </div>

              : <></>}

            <div class="coluna">
              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/primavera/resumo_evolucoes" className="atalho" id="button">
                  <Icon icon={book} size={(auth.residente > 0) ? 30 : 40} />
                  <strong>{'Diário'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>


            <div class="coluna">
              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/primavera/cardapios" className="atalho" id="button">
                  <Icon icon={cutlery} size={(auth.residente > 0) ? 30 : 40} />
                  <strong>{'Cardápio'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>

            <div class="coluna">

              <Tooltip title="Objetos Pessoais">
                <Nav.Link href="/primavera/objetos_pessoais" className='atalho' id='button'>
                  <Icon icon={addressBook} size={(auth.residente > 0) ? 30 : 40} />
                  <strong >{'Objetos Pessoais'}</strong>
                </Nav.Link>
              </Tooltip>
            </div>
          </div>


        </div>
        <FooterDelphus residente={auth.residente > 0} />
      </div>
    </div>
  );
}

export default Home;
