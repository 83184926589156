import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Icon } from 'react-icons-kit'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FrTableReg from '../components/FrTableReg'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboPrf, setComboPrf] = useState([{ value: '', display: '' }])
  const [res_Codigo, setRes_Codigo] = useState('')
  const [res_Nome, setRes_Nome] = useState('')
  const [dataRes, setDataRes] = useState([{ pk: "", codigo: "", nome: "" }])

  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)


  const columnsRes = [{ id: 'pk_pac', numeric: false, label: 'Codigo', align: 'left', minWidth: 100 },
  { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 400 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Usuários', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Usuários', 'Inclusao'))
      }

      setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
      setComboPac(await api.getComboData('pacientes'))
      setComboPrf(await api.getComboData('profissionais'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/usuarios?pk_usu=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            console.log(comboPrf)
            setData(r.data[0])
            api.get('/usuarios/pacientes?fk_usu=' + query.codigo).then(r => {

              console.log(r.data)
              if (r.data.length > 0)
                setDataRes(r.data)
            })
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'nome',
      'fk_gpu',
      'login',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_gpu') fieldName = 'GRUPO DE USUÁRIO'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        auxValues.dataRes = dataRes
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/usuarios/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/usuarios/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'altera_item_cobranca':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'libera_app':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'alerta_estoque':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'ativo':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleChangeModal = (e) => {
    if (e.target.name == 'resFk_pac') {
      setRes_Codigo(e.target.value)
      console.log(e.target.text)
    }
  }

  function mostraModal(e) {
    e.preventDefault()

    setRes_Codigo(0)
    setUpdateModal(false)
    setModal(true)
  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModalResidente(e) {
    e.preventDefault()
    if (res_Codigo) {
      if (!updatemodal)
        incluiRes(e)
      else
        updateRes(e)
      closeModal(e)
    } else
      swal.fire({
        title: 'Erro!',
        text: "Informe Nome!",
        icon: 'warning',
      })
  }

  async function incluiRes(e) {
    e.preventDefault();
    let pk = 1
    let nome = ''

    await api.get('/pacientes?pk_pac=' + res_Codigo).then(r => {
      if (r.data.length > 0)
        nome = (r.data[0].nome)
    })
    console.log(res_Nome)
    if (!dataRes[0].pk) {
      console.log(dataRes[0].pk)
      setDataRes([{
        codigo: res_Codigo,
        nome: nome,
        pk: pk
      }])
      console.log(dataRes)

    } else {

      let item = [{
        // CODIGO: dataRes[0].CODIGO,
        codigo: dataRes[0].codigo,
        nome: dataRes[0].nome,
        pk: pk

      }]
      pk++
      var i;
      for (i = 1; i < dataRes.length; i++) {
        item.push({
          // CODIGO: dataRes[i].CODIGO,
          codigo: dataRes[i].codigo,
          nome: dataRes[i].nome,
          pk: pk

        })
        pk++
      }
      item.push({
        // CODIGO: pla_codigo,
        codigo: res_Codigo,
        nome: nome,
        pk: pk
      })
      pk++
      setDataRes(item)

    }

    console.log(dataRes)
  }

  async function updateRes(e) {
    e.preventDefault();

    let item = []
    let nomeRes = ''
    let i
    await api.get('/pacientes?pk_pac=' + res_Codigo).then(r => {
      if (r.data.length > 0)
        nomeRes = (r.data[0].nome)
    })
    for (i = 0; i < dataRes.length; i++) {
      if (dataRes[i].pk == pkModal) {

        item.push({
          // CODIGO: pla_codigo,
          codigo: res_Codigo,
          nome: nomeRes,
          pk: pkModal,
        })
      } else {
        item.push({
          // CODIGO: dataRes[i].CODIGO,
          codigo: dataRes[i].res_Codigo,
          nome: dataRes[i].nome,
          pk: dataRes[i].pk,
        })
      }

      if (i + 1 == dataRes.length)
        setDataRes(item)
    }
  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Usuário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={data.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            color="#528b46"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  value={data.ativo || ''}
                  checked={data.ativo === 'S'}
                  name="ativo"
                  id="ativo"
                  size='small'
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={'Ativo'}
            />
          </div>
          <FrSelect
            value={data.fk_gpu || ''}
            onChange={handleChange}
            name="fk_gpu"
            id="fk_gpu"
            label='Grupo de Usuário'
            data={comboGrupoUsu}
            style={{ width: 250 }}
          />
          <br />

          <FrSelect
            value={data.fk_prf || ''}
            onChange={handleChange}
            name="fk_prf"
            id="fk_prf"
            label='Profissional'
            data={comboPrf}
            style={{ width: 400 }}
          />

          <br />

          <FormControlLabel
            control={
              <Checkbox
                value={data.alerta_estoque || ''}
                checked={data.alerta_estoque === 'S'}
                name='alerta_estoque'
                id='alerta_estoque'
                size='small'
                onChange={handleChange}
                color="primary"
              />
            }
            label="Alerta Estoque Mínimo"
          />


          <FormControlLabel
            control={
              <Checkbox
                value={data.libera_app || ''}
                checked={data.libera_app === 'S'}
                name='libera_app'
                id='libera_app'
                size='small'
                onChange={handleChange}
                color="primary"
              />
            }
            label="Libera App"
          />

          <FormControlLabel
            control={
              <Checkbox
                value={data.altera_item_cobranca || ''}
                checked={data.altera_item_cobranca == 'S'}
                name='altera_item_cobranca'
                id='altera_item_cobranca'
                size='small'
                onChange={handleChange}
                color="primary"
              />
            }
            label="Libera alteração de Item de Cobrança Automático"
          />


          <br />

          <br />
          <p>Informações para Login</p>
          <FrTextInput
            maxLength='200'
            value={data.login || ''}
            onChange={handleChange}
            name="login"
            id="login"
            color="#528b46"
            label="Login"
            variant="outlined"
            size="small"
            required
            style={{ width: 350 }}
            disabled={updateType}
          />


        </form>
        <button onClick={(e) => mostraModal(e)}>
          <Icon icon={ic_add_box} size={18} />
          Incluir
        </button>

        <div className="manutReg">
          <FrTableReg
            setPkModal={setPkModal}
            setUpdateModal={setUpdateModal}
            setModal={setModal}
            // setPla_Codigo={setPla_Codigo}
            setPla_Codigo_Cvn={setRes_Codigo}
            setPla_Nome={setRes_Nome}
            editar={true}
            setDataGrid={setDataRes}
            detail
            regName=""
            columns={columnsRes}
            searched={false}
            codeName="PK"
            page={""}
            data={dataRes}
          />

          <div className="register" hidden={!modal}>
            <div className="fields">

              <FrSelect
                value={res_Codigo || ''}
                onChange={handleChangeModal}
                name="resFk_pac"
                id="resFk_pac"
                label='Residente'
                data={comboPac}
                style={{ width: 400 }}
              />


              <div className="regButtonsDiv">
                <button onClick={handleSubmitModalResidente}>
                  Salvar
                </button>
                <button onClick={closeModal}>Cancelar</button>
              </div>
            </div>
          </div>
        </div>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
