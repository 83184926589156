import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./styles/Home.css";
import FrTextInput from "../components/FrTextInput";
import { paramsToObject, getUserCode } from "../utils/functions";
import api from "../services/api";
import FrImageEdit from "../components/FrImageEdit";
import FrSelect from "../components/FrSelect";

function PedeEvolucao() {
  const [pac, setPac] = useState("");
  const [data, setData] = useState([{ descricao: "" }]);
  const [pk_pac, setpkPac] = useState(0);
  const [pk_int, setpkInt] = useState(0);
  const [idade, setIdade] = useState(0);
  const [pho, setPho] = useState("");
  const [foto, setFoto] = useState("");
  const [unidade, setUnidade] = useState("");
  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);

  useEffect(() => {
    const fetchData = async () => {
      setComboPac(await api.getComboData("pacientes"));
    };
    fetchData();
  }, []);

  function handleCancel(e) {
    e.preventDefault();
    localStorage["authPrimavera"] = JSON.stringify({
      authenticated: false,
      user: 0,
    });
    window.location.href = "/primavera/login";
  }

  const handleChange = (e) => {
    setpkPac(e.target.value);

    if (e.target.value > 0) {
      api.get("/pacientes?pk_pac=" + e.target.value).then(async (r) => {
        setIdade(r.data[0].idade);
        setUnidade(r.data[0].unidade);
        setPho(r.data[0].nomepho);
        setFoto(r.data[0].foto);
        setPac(r.data[0].nome);
        setpkInt(r.data[0].pk_int);
        api
          .get("/itens_evolucao/pegaItens?pk_int=" + r.data[0].pk_int + '&fk_usu=' + getUserCode())
          .then((r) => {
            if (r.data) {
              setData(r.data);
            }
          });
      });
    }
  };

  useEffect(() => {
    const fetchPac = async () => {
      if (pk_pac > 0) {
        await api.get("/pacientes?pk_pac=" + pk_pac).then(async (r) => {
          setIdade(r.data[0].idade);
          setUnidade(r.data[0].unidade);
          setPho(r.data[0].nomepho);
          setFoto(r.data[0].foto);
          setPac(r.data[0].nome);
          setpkInt(r.data[0].pk_int);
          await api
            .get("/itens_evolucao/pegaItens?pk_int=" + r.data[0].pk_int)
            .then((r) => {
              if (r.data) {
                setData(r.data);
              }
            });
        });
      }
    };
    fetchPac(pk_pac);
  }, []);

  return (
    <div className="Home">
      <div className="HomeContent">
        <FrSelect
          value={pk_pac || ""}
          onChange={handleChange}
          name="fk_pac"
          id="fk_pac"
          label="Residente"
          data={comboPac}
          style={{ width: 350 }}
          // onBlur={handlePac}
          required
        />

        <div>
          <FrTextInput
            maxLength="150"
            value={idade || ""}
            name="idade"
            id="idade"
            color="#c0c0c0"
            label="Idade"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 80 }}
          />

          <FrTextInput
            maxLength="150"
            value={pho || ""}
            color="#c0c0c0"
            label="Plano de Hospedagem"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 200 }}
          />
        </div>
        <FrTextInput
          maxLength="150"
          value={unidade || ""}
          color="#c0c0c0"
          label="Unidade"
          variant="outlined"
          size="small"
          disabled
          style={{ width: 350 }}
        />

        <div className="fotoEvolucao">
          <FrImageEdit readOnly={true} image={foto} pk_pac={pk_pac} />
        </div>

        <div className="atalhos">
          {data.map((row, index) => {
            return (
              <>
                {row["descricao"] !== "" ? (
                  <Nav.Link
                    href={
                      "/primavera/evolucao?pk_ni1=" +
                      row["pk_ni1"] +
                      "&pk_pac=" +
                      pk_pac +
                      "&nomepac=" +
                      pac +
                      "&pk_int=" +
                      pk_int
                    }
                    className="atalho"
                  >
                    <strong>{row["descricao"]}</strong>
                  </Nav.Link>
                ) : (
                  <></>
                )}

              </>
            );
          })}

          {pk_pac > 0 ? (
            <Nav.Link
              href={"/primavera/administracoes?fk_pac=" + pk_pac}
              className="atalho"
            >
              <strong>{'ADMINISTRAÇÕES MEDICAMENTOS'}</strong>
            </Nav.Link>
          ) : (
            <></>
          )}


          <Nav.Link onClick={handleCancel} className="atalho">
            <strong>{"Sair"}</strong>
          </Nav.Link>
        </div>
      </div>
    </div>
  );
}

export default PedeEvolucao;
