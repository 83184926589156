import Login from '../pages/Login';
import Home from '../pages/Home';
import Cidades from '../pages/Cidades';
import RegCidades from '../pages/RegCidades';
import Estados from '../pages/Estados';
import RegEstados from '../pages/RegEstados';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Usuarios from '../pages/Usuarios';
import RegUsuarios from '../pages/RegUsuarios';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import Queixas from '../pages/Queixas';
import RegQueixas from '../pages/RegQueixas';
import CID10 from '../pages/CID10';
import RegCID10 from '../pages/RegCID10';
import Doencas from '../pages/Doencas';
import RegDoencas from '../pages/RegDoencas';
import Parentesco from '../pages/Parentesco';
import RegParentesco from '../pages/RegParentesco';
import Profissoes from '../pages/Profissoes';
import RegProfissoes from '../pages/RegProfissoes';
import Pacientes from '../pages/Pacientes';
import RegPacientes from '../pages/RegPacientes';
import Tipo_Hospedagem from '../pages/Tipo_Hospedagem';
import RegTipo_Hospedagem from '../pages/RegTipo_Hospedagem';
import Tipo_Pagamento from '../pages/Tipo_Pagamento';
import RegTipo_Pagamento from '../pages/RegTipo_Pagamento';
import Acomodacao from '../pages/Acomodacao';
import RegAcomodacao from '../pages/RegAcomodacao';
import Servicos from '../pages/Servicos';
import RegServicos from '../pages/RegServicos';
import Plano_Hospedagem from '../pages/Plano_Hospedagem';
import RegPlano_Hospedagem from '../pages/RegPlano_Hospedagem';
import Admissao from '../pages/Admissao';
import AdmissaoManut from '../pages/AdmissaoManut';
import Qualidade_Sono from '../pages/Qualidade_Sono';
import RegQualidade_Sono from '../pages/RegQualidade_Sono';
import Eliminacoes_Intestinais from '../pages/Eliminacoes_Intestinais';
import RegEliminacoes_Intestinais from '../pages/RegEliminacoes_Intestinais';
import Eliminacoes_Urinarias from '../pages/Eliminacoes_Urinarias';
import RegEliminacoes_Urinarias from '../pages/RegEliminacoes_Urinarias';
import Grupo_Preocupacoes from '../pages/Grupo_Preocupacoes';
import RegGrupo_Preocupacoes from '../pages/RegGrupo_Preocupacoes';
import Preocupacoes from '../pages/Preocupacoes';
import RegPreocupacoes from '../pages/RegPreocupacoes';
import Grupo_Medicamentos from '../pages/Grupo_Medicamentos';
import RegGrupo_Medicamentos from '../pages/RegGrupo_Medicamentos';
import Unidades_Medida from '../pages/Unidades_Medida';
import RegUnidades_Medida from '../pages/RegUnidades_Medida';
import Tipo_Receita_Medica from '../pages/Tipo_Receita_Medica';
import RegTipo_Receita_Medica from '../pages/RegTipo_Receita_Medica';
import Laboratorios from '../pages/Laboratorios';
import RegLaboratorios from '../pages/RegLaboratorios';
import Medicamentos from '../pages/Medicamentos';
import RegMedicamentos from '../pages/RegMedicamentos';
import Agendas from '../pages/Agenda';
import Tipo_Profissional from '../pages/Tipo_Profissional';
import RegTipo_Profissional from '../pages/RegTipo_Profissional';
import Conselho_Profissional from '../pages/Conselho_Profissional';
import RegConselho_Profissional from '../pages/RegConselho_Profissional';
import Profissionais from '../pages/Profissionais';
import RegProfissionais from '../pages/RegProfissionais';
import Prescricao_Medica_Periodo from '../pages/Prescricao_Medica_Periodo';
import RegPrescricao_Medica_Periodo from '../pages/RegPrescricao_Medica_Periodo';
import Prescricao_Medica_Frequencia from '../pages/Prescricao_Medica_Frequencia';
import RegPrescricao_Medica_Frequencia from '../pages/RegPrescricao_Medica_Frequencia';
import Prescricao_Medica from '../pages/Prescricao_MedicaNova';
import RegPrescricao_Medica from '../pages/RegPrescricao_Medica';
import Internacao from '../pages/Internacao';
import RegInternacao from '../pages/RegInternacao';
import Status_Internacao from '../pages/Status_Internacao';
import RegStatus_Internacao from '../pages/RegStatus_Internacao';
import Rotinas from '../pages/Rotinas';
import RegRotinas from '../pages/RegRotinas';
import Plano_Trabalho from '../pages/Plano_Trabalho';
import RegPlano_Trabalho from '../pages/RegPlano_Trabalho';
import Agenda from '../pages/Agenda';
import CheckList from '../pages/CheckList';
import Setor from '../pages/Setor';
import RegSetor from '../pages/RegSetor';
import Ala from '../pages/Ala';
import RegAla from '../pages/RegAla';
import Andar from '../pages/Andar';
import RegAndar from '../pages/RegAndar';
import Predio from '../pages/Predio';
import RegPredio from '../pages/RegPredio';
import Unidade from '../pages/Unidade';
import RegUnidade from '../pages/RegUnidade';
import Forma_Cobranca from '../pages/Forma_Cobranca';
import RegForma_Cobranca from '../pages/RegForma_Cobranca';
import Tipo_Cobranca from '../pages/Tipo_Cobranca';
import RegTipo_Cobranca from '../pages/RegTipo_Cobranca';
import Itens_Cobranca from '../pages/Itens_Cobranca';
import RegItens_Cobranca from '../pages/RegItens_Cobranca';
import Contas_Receber from '../pages/Contas_Receber';
import RegContas_Receber from '../pages/RegContas_Receber';
import CheckIn_Out from '../pages/CheckIn_Out';
import Itens_Evolucao from '../pages/Itens_Evolucao';
import RegItens_Evolucao from '../pages/RegItens_Evolucao';
import RegNivel2 from '../pages/RegNivel2';
import RegNivel3 from '../pages/RegNivel3';
import PedeEvolucao from '../pages/PedeEvolucao';
import Evolucao from '../pages/Evolucao';
import NovoRegItens_Evolucao from '../pages/NovoRegItens_Evolucao';
import Consulta_Evolucao from '../pages/Consulta_Evolucao';
import Detalha_Evolucao from '../pages/Detalha_Evolucao';
import Mostra_Evolucao from '../pages/Mostra_Evolucao';
import Contas_Pagar from '../pages/Contas_Pagar';
import RegContas_Pagar from '../pages/RegContas_Pagar';
import Classes from '../pages/Classes';
import RegClasses from '../pages/RegClasses';
import SubClasses from '../pages/Subclasses';
import RegSubClasses from '../pages/RegSubClasses';
import Fornecedores from '../pages/Fornecedores';
import RegFornecedores from '../pages/RegFornecedores';
import LiquidarTit from '../pages/LiquidarTit';
import Bancos from '../pages/Bancos';
import RegBancos from '../pages/RegBancos';
import Formas_Pagamento from '../pages/Formas_Pagamento';
import RegFormas_Pagamento from '../pages/RegFormas_Pagamento';
import Contas_Bancarias from '../pages/Contas_Bancarias';
import RegContas_Bancarias from '../pages/RegContas_Bancarias';
import ReabrirTit from '../pages/ReabrirTit';
import LiquidacaoLoteTit from '../pages/LiquidacaoLoteTit';
import ProgramacaoTit from '../pages/ProgramacaoTit';
import ReabrirCtr from '../pages/ReabrirCtr';
import LiquidacaoLoteCtr from '../pages/LiquidacaoLoteCtr';
import LiquidarCtr from '../pages/LiquidarCtr';
import RegAgenda from '../pages/RegAgenda';
import MostraAlertas from '../pages/MostraAlertas';
import Tipo_Item_Cobranca from '../pages/Tipo_Item_Cobranca';
import RegTipo_Item_Cobranca from '../pages/RegTipo_Item_Cobranca';
import Grupo_Materiais from '../pages/Grupo_Materiais';
import RegGrupo_Materiais from '../pages/RegGrupo_Materiais';
import Fabricantes from '../pages/Fabricantes';
import RegFabricantes from '../pages/RegFabricantes';
import Tipo_Mov_Estoque from '../pages/Tipo_Mov_Estoque';
import RegTipo_Mov_Estoque from '../pages/RegTipo_Mov_Estoque';
import Materiais from '../pages/Materiais';
import RegMateriais from '../pages/RegMateriais';
import Estoques from '../pages/Estoques';
import RegEstoques from '../pages/RegEstoques';
import Movimentacao_Estoque from '../pages/Movimentacao_Estoque';
import RegMovimentacao_Estoque from '../pages/RegMovimentacao_Estoque';
import ConMovimentacao_Estoque from '../pages/ConMovimentacao_Estoque';
import Inventario from '../pages/Inventario';
import RegInventario from '../pages/RegInventario';
import RetiradaInventario from '../pages/RetiradaInventario';
import Resumo_Evolucoes from '../pages/Resumo_Evolucoes';
import RegResumo_Evolucoes from '../pages/RegResumo_Evolucoes';
import Lotes from '../pages/Lotes';
import RegLotes from '../pages/RegLotes';
import Prescricoes from '../pages/Prescricoes';
import Requisicoes from '../pages/Requisicoes';
import RegRequisicoes from '../pages/RegRequisicoes';
import PedeEvolucaoGenerica from '../pages/PedeEvolucaoGenerica';
import Certificado from '../pages/Certificado'
import Convenios from '../pages/Convenios';
import RegConvenios from '../pages/RegConvenios';
import Hospitais from '../pages/Hospitais';
import RegHospitais from '../pages/RegHospitais';
import Via_Alimentacao from '../pages/Via_Alimentacao';
import RegVia_Alimentacao from '../pages/RegVia_Alimentacao';
import Administracoes from '../pages/Administracoes';
import RegAdministracoes from '../pages/RegAdministracoes';
import RegNovoAdministracoes from '../pages/RegNovoAdministracoes';
import Tabela_Precos from '../pages/Tabela_Precos';
import RegTabela_Precos from '../pages/RegTabela_Precos';
import RegPacienteFamiliar from '../pages/RegPacienteFamiliar';
import Exporta_Evolucao from '../pages/Exporta_Evolucao';
import RelContas_Receber from '../pages/RelContas_Receber';
import RelContas_Pagar from '../pages/RelContas_Pagar';
import MostraAlertasEstoques from '../pages/MostraAlertasEstoques';
import Inventario2 from '../pages/Inventario2';
import Cardapio from '../pages/Cardapio';
import MostraAlertasAniversarios from '../pages/MostraAlertasAniversarios';
import MostraAlertasProrrogacoes from '../pages/MostraAlertasProrrogacoes';
import Internacao_Prorrogacao from '../pages/Internacao_Prorrogacao';
import Transferencias from '../pages/Transferencias';
import RegTransferencias from '../pages/RegTransferencias';
import Via_Administracao from '../pages/Via_Administracao';
import RegVia_Administracao from '../pages/RegVia_Administracao';
import ImportacaoSimpro from '../pages/ImportacaoSimpro';
import EmissaoNotas from '../pages/EmissaoNotas';
import Tipo_Nota from '../pages/Tipo_Nota';
import RegTipo_Nota from '../pages/RegTipo_Nota';
import Tomador from '../pages/Tomador';
import RegTomador from '../pages/RegTomador';
import Nota_Fiscal from '../pages/Nota_Fiscal';
import RegNota_Fiscal from '../pages/RegNota_Fiscal';
import AtenderRequisicoes from '../pages/AtenderRequisicoes';
import Principio_Ativo from '../pages/Principio_Ativo';
import RegPrincipio_Ativo from '../pages/RegPrincipio_Ativo';
import EstoquesPri from '../pages/EstoquesPri';
import ImprimeReceita from '../pages/ImprimeReceita';
import Mov_Bco from '../pages/Mov_Bco';
import RegMov_Bco from '../pages/RegMov_Bco';

export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/cidades',
        component: Cidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cidades'
    },
    {
        path: '/cidades/registro',
        component: RegCidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estados',
        component: Estados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estados'
    },
    {
        path: '/estados/registro',
        component: RegEstados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Usuários'
    },
    {
        path: '/usuarios/registro',
        component: RegUsuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/queixas',
        component: Queixas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Queixas'
    },
    {
        path: '/queixas/registro',
        component: RegQueixas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/cid10',
        component: CID10,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CID 10'
    },
    {
        path: '/cid10/registro',
        component: RegCID10,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/doencas',
        component: Doencas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Doenças'
    },
    {
        path: '/doencas/registro',
        component: RegDoencas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/parentesco',
        component: Parentesco,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Parentesco'
    },
    {
        path: '/parentesco/registro',
        component: RegParentesco,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/profissoes',
        component: Profissoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Profissões'
    },
    {
        path: '/profissoes/registro',
        component: RegProfissoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/pacientes',
        component: Pacientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Pacientes'
    },
    {
        path: '/pacientes/registro',
        component: RegPacientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_pagamento',
        component: Tipo_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Pagamento'
    },
    {
        path: '/tipo_pagamento/registro',
        component: RegTipo_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_hospedagem',
        component: Tipo_Hospedagem,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Hospedagem'
    },
    {
        path: '/tipo_hospedagem/registro',
        component: RegTipo_Hospedagem,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/acomodacao',
        component: Acomodacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Acomodação'
    },
    {
        path: '/acomodacao/registro',
        component: RegAcomodacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/servicos',
        component: Servicos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Serviços'
    },
    {
        path: '/servicos/registro',
        component: RegServicos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/plano_hospedagem',
        component: Plano_Hospedagem,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Planos de Hospedagem'
    },
    {
        path: '/plano_hospedagem/registro',
        component: RegPlano_Hospedagem,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/admissao',
        component: AdmissaoManut,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Admissão'
    },
    {
        path: '/admissao/registro',
        component: Admissao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
        name: 'Admissão'
    },

    {
        path: '/qualidade_sono',
        component: Qualidade_Sono,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Qualidade do Sono'
    },
    {
        path: '/qualidade_sono/registro',
        component: RegQualidade_Sono,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/eliminacoes_intestinais',
        component: Eliminacoes_Intestinais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Eliminações Intestinais'
    },
    {
        path: '/eliminacoes_intestinais/registro',
        component: RegEliminacoes_Intestinais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/eliminacoes_urinarias',
        component: Eliminacoes_Urinarias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Eliminações Urinárias'
    },
    {
        path: '/eliminacoes_Urinarias/registro',
        component: RegEliminacoes_Urinarias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/grupo_preocupacoes',
        component: Grupo_Preocupacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupo de Preocupações'
    },
    {
        path: '/grupo_preocupacoes/registro',
        component: RegGrupo_Preocupacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/preocupacoes',
        component: Preocupacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Preocupações'
    },
    {
        path: '/preocupacoes/registro',
        component: RegPreocupacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/grupo_medicamentos',
        component: Grupo_Medicamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Medicamentos'
    },
    {
        path: '/grupo_medicamentos/registro',
        component: RegGrupo_Medicamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/unidades_medida',
        component: Unidades_Medida,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Unidades de Medida'
    },
    {
        path: '/unidades_medida/registro',
        component: RegUnidades_Medida,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_receita_medica',
        component: Tipo_Receita_Medica,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Receita Médica'
    },
    {
        path: '/tipo_receita_medica/registro',
        component: RegTipo_Receita_Medica,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/laboratorios',
        component: Laboratorios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Laboratórios'
    },
    {
        path: '/laboratorios/registro',
        component: RegLaboratorios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/medicamentos',
        component: Medicamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Medicamentos'
    },
    {
        path: '/medicamentos/registro',
        component: RegMedicamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/agendas',
        component: Agendas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/tipo_profissional',
        component: Tipo_Profissional,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Profissionais'
    },
    {
        path: '/tipo_profissional/registro',
        component: RegTipo_Profissional,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/conselho_profissional',
        component: Conselho_Profissional,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Conselhos Profissionais'
    },
    {
        path: '/conselho_profissional/registro',
        component: RegConselho_Profissional,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/profissionais',
        component: Profissionais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Profissionais'
    },
    {
        path: '/profissionais/registro',
        component: RegProfissionais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/prescricao_medica_periodo',
        component: Prescricao_Medica_Periodo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Período Medicação'
    },
    {
        path: '/prescricao_medica_periodo/registro',
        component: RegPrescricao_Medica_Periodo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/prescricao_medica_frequencia',
        component: Prescricao_Medica_Frequencia,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Frequência Medicação'
    },
    {
        path: '/prescricao_medica_frequencia/registro',
        component: RegPrescricao_Medica_Frequencia,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/prescricao_medica',
        component: Prescricao_Medica,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Prescrição Médica'
    },
    {
        path: '/prescricao_medica/registro',
        component: RegPrescricao_Medica,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/internacao',
        component: Internacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Internação'
    },
    {
        path: '/internacao/registro',
        component: RegInternacao,
        protected: true,
        exact: false,
        checkPermission: true,
        register: true
    },
    {
        path: '/status_internacao',
        component: Status_Internacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Status Internação'
    },
    {
        path: '/status_internacao/registro',
        component: RegStatus_Internacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/rotinas',
        component: Rotinas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Rotinas'
    },
    {
        path: '/rotinas/registro',
        component: RegRotinas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/plano_trabalho',
        component: Plano_Trabalho,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Plano de Trabalho'
    },
    {
        path: '/plano_trabalho/registro',
        component: RegPlano_Trabalho,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/agenda',
        component: Agenda,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Agenda'
    },

    {
        path: '/CheckList',
        component: CheckList,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CheckList'
    },

    {
        path: '/setor',
        component: Setor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Setores'
    },
    {
        path: '/setor/registro',
        component: RegSetor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/ala',
        component: Ala,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Alas'
    },
    {
        path: '/ala/registro',
        component: RegAla,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/andar',
        component: Andar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Andares'
    },
    {
        path: '/andar/registro',
        component: RegAndar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/predio',
        component: Predio,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Prédios'
    },
    {
        path: '/predio/registro',
        component: RegPredio,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/unidade',
        component: Unidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Unidades'
    },
    {
        path: '/unidade/registro',
        component: RegUnidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/forma_cobranca',
        component: Forma_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Formas de Cobrança'
    },
    {
        path: '/forma_cobranca/registro',
        component: RegForma_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_cobranca',
        component: Tipo_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Cobrança'
    },
    {
        path: '/tipo_cobranca/registro',
        component: RegTipo_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/itens_cobranca',
        component: Itens_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Itens de Cobrança'
    },
    {
        path: '/itens_cobranca/registro',
        component: RegItens_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_receber',
        component: Contas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas a Receber'
    },
    {
        path: '/contas_receber/registro',
        component: RegContas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/checkin',
        component: CheckIn_Out,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CheckIn'
    },
    {
        path: '/checkout',
        component: CheckIn_Out,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CheckOut'
    },

    {
        path: '/itens_evolucao',
        component: Itens_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Itens de Evolução'
    },
    {
        path: '/itens_evolucao/registro',
        component: RegItens_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/itens_evolucao/novo',
        component: NovoRegItens_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/itens_evolucao/nivel2/registro',
        component: RegNivel2,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/itens_evolucao/nivel3/registro',
        component: RegNivel3,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/pedeEvolucao',
        component: PedeEvolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/lancaEvolucao',
        component: PedeEvolucaoGenerica,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/Evolucao',
        component: Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/consulta_evolucao',
        component: Consulta_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Consulta Evolução'
    },

    {
        path: '/detalhaEvolucao',
        component: Detalha_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/mostraEvolucao',
        component: Mostra_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_pagar',
        component: Contas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas a Pagar'
    },
    {
        path: '/contas_pagar/registro',
        component: RegContas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/classes',
        component: Classes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classes de Despesas'
    },
    {
        path: '/classes/registro',
        component: RegClasses,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/subclasses',
        component: SubClasses,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Subclasses de Despesas'
    },
    {
        path: '/subclasses/registro',
        component: RegSubClasses,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/fornecedores',
        component: Fornecedores,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Fornecedores/Funcionários'
    },
    {
        path: '/fornecedores/registro',
        component: RegFornecedores,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_pagar/liquidar',
        component: LiquidarTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/bancos',
        component: Bancos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Bancos'
    },
    {
        path: '/bancos/registro',
        component: RegBancos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/formas_pagamento',
        component: Formas_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Formas de Pagamento'
    },
    {
        path: '/formas_pagamento/registro',
        component: RegFormas_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_bancarias',
        component: Contas_Bancarias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas Bancárias'
    },
    {
        path: '/contas_bancarias/registro',
        component: RegContas_Bancarias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_pagar/reabrir',
        component: ReabrirTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/contas_pagar/liquidacaoLote',
        component: LiquidacaoLoteTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_pagar/programacao',
        component: ProgramacaoTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_receber/liquidar',
        component: LiquidarCtr,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_receber/reabrir',
        component: ReabrirCtr,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/contas_receber/liquidacaoLote',
        component: LiquidacaoLoteCtr,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/agenda/registro',
        component: RegAgenda,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/alertas',
        component: MostraAlertas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_item_cobranca',
        component: Tipo_Item_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Itens de Cobrança'
    },
    {
        path: '/tipo_item_cobranca/registro',
        component: RegTipo_Item_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/grupo_materiais',
        component: Grupo_Materiais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Materiais'
    },
    {
        path: '/grupo_materiais/registro',
        component: RegGrupo_Materiais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/fabricantes',
        component: Fabricantes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Fabricantes'
    },
    {
        path: '/fabricantes/registro',
        component: RegFabricantes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tipo_mov_estoque',
        component: Tipo_Mov_Estoque,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Movimentação de Estoque'
    },
    {
        path: '/tipo_mov_estoque/registro',
        component: RegTipo_Mov_Estoque,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/materiais',
        component: Materiais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Materiais'
    },
    {
        path: '/materiais/registro',
        component: RegMateriais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estoques',
        component: Estoques,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estoques'
    },
    {
        path: '/estoques/registro',
        component: RegEstoques,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/movimentacao_estoque',
        component: Movimentacao_Estoque,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Movimentação de Estoques'
    },
    {
        path: '/movimentacao_estoque/registro',
        component: ConMovimentacao_Estoque,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/regmovimentacao_estoque/registro',
        component: RegMovimentacao_Estoque,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/objetos_pessoais',
        component: Inventario,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Objetos Pessoais'
    },
    {
        path: '/objetos_pessoais/registro',
        component: RegInventario,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/objetos_pessoais/retirada',
        component: RetiradaInventario,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/resumo_evolucoes',
        component: Resumo_Evolucoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Resumo Evoluções'
    },
    {
        path: '/resumo_evolucoes/registro',
        component: RegResumo_Evolucoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/lotes',
        component: Lotes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Lotes de Medicamentos'
    },
    {
        path: '/lotes/registro',
        component: RegLotes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/prescricoes/view',
        component: Prescricoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/requisicoes',
        component: Requisicoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Requisições de Estoque'
    },
    {
        path: '/requisicoes/registro',
        component: RegRequisicoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/certificado',
        component: Certificado,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false,
        name: 'Certificado'
    },


    {
        path: '/convenios',
        component: Convenios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Convênios'
    },
    {
        path: '/convenios/registro',
        component: RegConvenios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/hospitais',
        component: Hospitais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Hospitais'
    },
    {
        path: '/hospitais/registro',
        component: RegHospitais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/via_alimentacao',
        component: Via_Alimentacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Vias de Alimentação'
    },
    {
        path: '/via_alimentacao/registro',
        component: RegVia_Alimentacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/administracoes',
        component: Administracoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Administração de Medicamentos'
    },
    {
        path: '/administracoes/registro',
        component: RegAdministracoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/administracoes/novo',
        component: RegNovoAdministracoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/tabela_precos',
        component: Tabela_Precos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tabela de Preços'
    },
    {
        path: '/tabela_precos/registro',
        component: RegTabela_Precos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/pacientes/registro_familiar',
        component: RegPacienteFamiliar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/Exporta_Evolucao',
        component: Exporta_Evolucao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Exportar Evolução'
    },

    {
        path: '/RelContas_Receber',
        component: RelContas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Relatório Contas Receber'
    },

    {
        path: '/RelContas_Pagar',
        component: RelContas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Relatório Contas Pagar'
    },

    {
        path: '/alertasEstoques',
        component: MostraAlertasEstoques,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/inventario',
        component: Inventario2,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Inventário'
    },

    {
        path: '/cardapios',
        component: Cardapio,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cardápio'
    },

    {
        path: '/alertasAniversarios',
        component: MostraAlertasAniversarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/alertasProrrogacoes',
        component: MostraAlertasProrrogacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/internacao/prorrogacao',
        component: Internacao_Prorrogacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/transferencias',
        component: Transferencias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Transferência de Unidade'
    },
    {
        path: '/transferencias/registro',
        component: RegTransferencias,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/via_administracao',
        component: Via_Administracao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Vias de Administração'
    },
    {
        path: '/via_administracao/registro',
        component: RegVia_Administracao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/inventario/retirada',
        component: RetiradaInventario,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/importacaoSimpro',
        component: ImportacaoSimpro,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Importação Simpro'
    },

    {
        path: '/notas/emissao',
        component: EmissaoNotas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/tipo_nota',
        component: Tipo_Nota,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Nota'
    },
    {
        path: '/tipo_nota/registro',
        component: RegTipo_Nota,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/tomador',
        component: Tomador,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tomadores'
    },
    {
        path: '/tomador/registro',
        component: RegTomador,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/nota_fiscal',
        component: Nota_Fiscal,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Notas Fiscais'
    },
    {
        path: '/nota_fiscal/registro',
        component: RegNota_Fiscal,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/requisicoes/atender',
        component: AtenderRequisicoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/principio_ativo',
        component: Principio_Ativo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Princípios Ativos'
    },
    {
        path: '/principio_ativo/registro',
        component: RegPrincipio_Ativo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/estoquesPri',
        component: EstoquesPri,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
        name: 'Estoques'
    },


    {
        path: '/imprimeReceita',
        component: ImprimeReceita,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },


    {
        path: '/mov_bco',
        component: Mov_Bco,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Movimentações Bancárias'
    },
    {
        path: '/mov_bco/registro',
        component: RegMov_Bco,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

];

export const fallbackRoute = Home

