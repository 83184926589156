import axios from "axios";
import download from "downloadjs";

// let backendUrl = "http://localhost:21453"; //ambiente dev
// let backendUrl = "https://apps.delphus.inf.br:5000"; //ambiente teste
let backendUrl = "https://apps.delphus.inf.br:21454"; //ambiente produção


const auth = (JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));

const api = axios.create({
  baseURL: backendUrl + `/api/`,
});

// api.interceptors.request.use(function (config) {
//   const token = auth.access_token
//   console.log(config)
//   config.headers.token =  token;

//   return config;
// });

api.getComboData = async (table, parameter) => {
  return new Promise(async (resolve, reject) => {
    await api.get("/" + table + "/combo" + (parameter ? "/" + parameter : "")).then(async (r) => {
      resolve(r.data);
    });
  });
};

api.getComboDataWhere = async (table, where) => {
  return new Promise(async (resolve, reject) => {
    await api.get("/" + table + "/combo" + where).then(async (r) => {
      resolve(r.data);
    });
  });
};

api.getAcessos = async (tela, opcao) => {
  return new Promise(async (resolve, reject) => {
    let auth = JSON.parse(localStorage["authPrimavera"]);

    await api.get("/grupo_usuarios/permissoes?tela=" + tela + "&pk_usu=" + auth.user + "&acao=" + opcao).then(async (r) => {
      if (opcao === "Consulta") {
        try {
          // alert(r.data[0].Consulta)
          resolve(r.data[0].Consulta);
        } catch {
          resolve("N");
        }
      }

      if (opcao === "Inclusao") {
        try {
          resolve(r.data[0].Inclusao);
        } catch {
          resolve("N");
        }
      }

      if (opcao === "Alteracao") {
        try {
          resolve(r.data[0].Alteracao);
        } catch {
          resolve("N");
        }
      }

      if (opcao === "Exclusao") {
        try {
          resolve(r.data[0].Exclusao);
        } catch {
          resolve("N");
        }
      }

      // resolve(r.data)
    });
  });
};

api.getAcessosConsultas = async () => {
  return new Promise(async (resolve, reject) => {
    let auth = JSON.parse(localStorage["authPrimavera"]);
    await api
      .get("/grupo_usuarios/permissoes?pk_usu=" + auth.user)
      .then(async (r) => {
        console.log(r.data);
        resolve(r.data);
      });
  });
};

api.getPac = async () => {
  return new Promise(async (resolve, reject) => {
    let auth = JSON.parse(localStorage["authPrimavera"]);

    await api.get("/usuarios?pk_usu=" + auth.user).then(async (r) => {
      // console.log(r.data[0].fk_residente)
      resolve(r.data[0].fk_residente);
    });
  });
};

api.getMostraEstoque = async (residente) => {
  return new Promise(async (resolve, reject) => {

    await api.get("/pacientes?pk_pac=" + residente).then(async (r) => {
      resolve(r.data[0].mostra_estoque);
    });
  });
};

api.getListData = async (table, parameter) => {
  return new Promise(async (resolve, reject) => {
    console.log("Searching for parameter " + parameter);
    await api
      .get("/" + table + "/lista" + (parameter ? "/" + parameter : ""))
      .then(async (r) => {
        console.log(r.data);
        resolve(r.data);
      });
  });
};

api.getImage = async (residente, estoque, image) => {
  return new Promise(async (resolve, reject) => {
    console.log(
      backendUrl + "/static/upload/residente/" + residente + "/" + image
    );
    if (residente && image) {
      axios
        .get(backendUrl + "/static/upload/residente/" + residente + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/residente/" + residente + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else if (estoque && image) {
      axios
        .get(backendUrl + "/static/upload/estoques/" + estoque + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/estoques/" + estoque + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else resolve(backendUrl + "/static/upload/no-image.jpg");
  });
};

api.getImageRev = async (pk, image) => {
  return new Promise(async (resolve, reject) => {
    if (pk && image) {
      axios.get(backendUrl + "/static/upload/resumo_evolucoes/" + pk + "/" + image)
        .then((r) => {
          resolve(backendUrl + "/static/upload/resumo_evolucoes/" + pk + "/" + image);
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else
      resolve(backendUrl + "/static/upload/no-image.jpg")
  });
};

api.getImageInv = async (pk, image, pasta) => {
  return new Promise(async (resolve, reject) => {
    console.log(
      backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
    );
    if (image) {
      console.log(
        backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
      );
      axios
        .get(backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image)
        .then((r) => {
          resolve(
            backendUrl + "/static/upload/" + pasta + "/" + pk + "/" + image
          );
        })
        .catch((e) => resolve(backendUrl + "/static/upload/no-image.jpg"));
    } else resolve(backendUrl + "/static/upload/no-image.jpg");
  });
};

api.downloadEvolucao = async (id) => {
  return new Promise(async (resolve, reject) => {
    api
      .post("/resumo_evolucoes/pdf", { id }, { responseType: "blob" })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data?.type === "application/json") {
            resolve(true);
          } else {
            var blob = new Blob([response.data], {
              type: "application/pdf",
            });
            download(blob, "resumo_evolucao_" + id + ".pdf");
            resolve(true);
          }
        } else resolve(false);
      })
      .catch((e) => resolve(false));
  });
};

api.noImageUrl = backendUrl + "/static/upload/no-image.jpg";
api.backendUrl = backendUrl;

export default api;
