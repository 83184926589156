import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'
import AutoComp from '../components/AutoComp'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Movimentacao_Estoque() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [comboTme, setComboTme] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [mdc, setMdc] = useState('');
  const [mat, setMat] = useState('');
  const [tme, setTme] = useState('');

  const columns = [
    { id: 'format_data', numeric: false, label: 'Data Movimento', align: 'left', minWidth: 130, order: 'data_mov' },
    { id: 'descricao', numeric: false, label: 'Material / Medicamento', align: 'left', minWidth: 200 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 150 },
    { id: 'quantidade', numeric: false, label: 'Quantidade', align: 'left', minWidth: 150 },
    { id: 'nometme', numeric: false, label: 'Tipo de Movimentação', align: 'left', minWidth: 150 },
    { id: 'lote1', numeric: false, label: 'Nº do Lote', align: 'left', minWidth: 150 },
    { id: 'strOperacao', numeric: false, label: 'Operação', align: 'left', minWidth: 150 },
    { id: 'pk_icb', numeric: false, label: 'Nº Cobrança', align: 'left', minWidth: 50, cobranca: true },


  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Movimentação de Estoques'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboMdc(await api.getComboData('medicamentos'))
        setComboMat(await api.getComboData('materiais'))
        setComboTme(await api.getComboData('tipo_mov_estoque'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        if (query.fk_mdc)
          setMdc(query.fk_mdc)

        if (query.fk_mat)
          setMat(query.fk_mat)

        if (query.fk_tme)
          setTme(query.fk_tme)

        let pacientes
        if (query.fk_pac)
          pacientes = await api.getComboData('pacientes', 'pac' + query.fk_pac)
        else
          pacientes = await api.getComboData('pacientes', 'reg')

        pacientes.unshift({ label: 'Residencial Primavera', value: 'null' })
        setComboPac(pacientes)

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        await api.get('/movimentacao_estoque/reprocessa')

        swal.close()
        if (query.buscar) {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])



  useEffect(() => {
    const fetchPac = async () => {

      let pacientes = await api.getComboData('pacientes', 'pac' + pac)
      pacientes.unshift({ label: 'Residencial Primavera', value: 'null' })

      setComboPac(pacientes)

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;
        await api.get("/pacientes?pk_pac=" + pac).then((r) => {
          if (r.data[0])
            auxValues["nomepac"] = r.data[0].nome;
        });
      }
      setFilter(auxValues);

    }
    fetchPac()
  }, [pac])


  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_mdc"] = mdc;
      auxValues["nomemdc"] = ''
      if (mdc > 0) {
        await api.get("/medicamentos?pk_mdc=" + mdc).then((r) => {
          if (r.data[0])
            auxValues["nomemdc"] = r.data[0].nome_comercial;
        });
      }
      setFilter(auxValues);
    };
    fetchMdc();
  }, [mdc]);


  useEffect(() => {
    const fetchMat = async () => {
      const auxValues = { ...filter };
      auxValues["fk_mat"] = mat;
      auxValues["nomemat"] = ''
      if (mat > 0) {
        await api.get("/materiais?pk_mat=" + mat).then((r) => {
          if (r.data[0])
            auxValues["nomemat"] = r.data[0].descricao;
        });
      }
      setFilter(auxValues);
    };
    fetchMat();
  }, [mat]);


  useEffect(() => {
    const fetchTme = async () => {
      const auxValues = { ...filter };
      auxValues["fk_tme"] = tme;
      auxValues["nometme"] = ''
      if (tme > 0) {
        await api.get("/tipo_mov_estoque?pk_tme=" + tme).then((r) => {
          if (r.data[0])
            auxValues["nometme"] = r.data[0].descricao;
        });
      }
      setFilter(auxValues);
    };
    fetchTme();
  }, [tme]);

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    queryObject.buscar = true
    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/primavera/movimentacao_estoque?" + objectToQueryString(queryObject));
    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/movimentacao_estoque', { params: queryObject }).then(r => {
      setData(r.data)
      swal.close()
    })
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/movimentacao_estoque');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
      auxValues['fk_pac'] = e.target.value;
      setPac(e.target.value)
    } else
      auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Movimentação de Estoques</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                color="#528b46"
                label="Período Movimento"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}
              />

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />


              <br />

              <div class="container-fluid">
                <div class="row" >

                  <div class="column">
                    <AutoComp
                      value={filter.nomemdc || ""}
                      valor={filter.fk_mdc}
                      name="fk_mdc"
                      id="fk_mdc"
                      label="Medicamento"
                      data={comboMdc}
                      style={{ width: 250 }}
                      width={300}
                      setValue={setMdc}
                    />
                  </div>
                  <div class="column" >
                    <FrameF2 tipoModal={'Medicamento'} data={data} mdc={mdc} setMdc={setMdc} ></FrameF2>
                  </div>

                  <AutoComp
                    value={filter.nomemat || ""}
                    valor={filter.fk_mat}
                    name="fk_mat"
                    id="fk_mat"
                    label="Material"
                    data={comboMat}
                    style={{ width: 300 }}
                    width={300}
                    setValue={setMat}
                  />

                  <AutoComp
                    value={filter.nometme || ""}
                    valor={filter.fk_tme}
                    name="fk_tme"
                    id="fk_tme"
                    label='Tipo de Movimentação'
                    data={comboTme}
                    width={300}
                    setValue={setTme}
                  />

                </div>
              </div>

              <div class="container-fluid">

                <div class="row" >

                  <div class="column">
                    <AutoComp
                      value={filter.nomepac || ""}
                      valor={filter.fk_pac}
                      name="fk_pac"
                      id="fk_pac"
                      label="Residente"
                      data={comboPac}
                      style={{ width: 250 }}
                      width={300}
                      setValue={setPac}
                    />

                  </div>

                  <div class="column" >
                    <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/primavera/regmovimentacao_estoque/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}


            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="movimentacao_estoque" columns={columns} searched={filtered} codeName="pk_mit" page={'estoques'} data={data} enableEdit={'N'} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Movimentacao_Estoque;
