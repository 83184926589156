import React, { useEffect, useState } from 'react';
import ReactExport from "react-data-export";
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Dialog from '@material-ui/core/Dialog';


import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'
import FrameF2 from '../components/FrameF2'

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Estoques() {
  const [data, setData] = useState([])
  const [dataPlanilha, setDataPlanilha] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboMdc, setComboMdc] = useState([{ value: '', display: '' }])
  const [comboMat, setComboMat] = useState([{ value: '', display: '' }])
  const [residente, setResidente] = useState(false)
  const [comboSaldo, setComboSaldo] = useState([{ value: ' and coalesce(saldo_estoque,0)>0', display: 'Com Estoque' }, { value: ' and coalesce(saldo_estoque,0)=0', display: 'Sem Estoque' }])
  const [pac, setPac] = useState('')

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [dataEsl, setDataEsl] = useState([])

  let auth = JSON.parse(localStorage['authPrimavera'])
  const [codPac, setCodPac] = useState('')
  const [fk_mdc, setFk_Mdc] = useState('')

  const columns = [
    { id: 'descricao', numeric: false, label: 'Material / Medicamento', align: 'left', minWidth: 300 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 150 },
    { id: 'estoque_minimo', numeric: false, label: 'Estoque Mínimo', align: 'left', minWidth: 150 },
    { id: 'saldo_estoque', numeric: false, label: 'Saldo Estoque', align: 'left', minWidth: 150 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 150 },
  ]

  const columns2 = [
    { id: 'descricao', numeric: false, label: 'Material / Medicamento', align: 'left', minWidth: 300 },
    { id: 'saldo_estoque', numeric: false, label: 'Saldo Estoque', align: 'left', minWidth: 150 },
    { id: 'nomeuni', numeric: false, label: 'Unidade', align: 'left', minWidth: 150 },
    { id: 'estoque_minimo', numeric: false, label: 'Estoque Mínimo', align: 'left', minWidth: 150 },
  ]


  const columnsEsl = [
    {
      id: "nomematmdc",
      numeric: false,
      label: "Material/Medicamento",
      align: "left",
      minWidth: 150,
    },

    {
      id: "lote",
      numeric: false,
      label: "Lote",
      align: "left",
      minWidth: 150,
    },
    {
      id: "validade",
      numeric: false,
      label: "Validade",
      align: "left",
      minWidth: 150,
    },
    {
      id: "saldo",
      numeric: false,
      label: "Saldo",
      align: "left",
      minWidth: 150,
    },

  ];

  const [showModal, setShowModal] = useState(false)
  const [fk_pac, setfk_pac] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setResidente(auth.residente > 0)
      let tela = 'Estoques'
      let consulta = await api.getAcessos(tela, 'Consulta')
      setCodPac(auth.residente)
      if (auth.residente > 0) {

        setenableAdd('N')
        setenableEdit('N')
        setenableDel('N')
        setComboPac(await api.getComboData('pacientes'))

        let event = new Event('build');
        setTimeout(() => getData(event), 1500)
      } else {

        if (consulta !== 'S') {
          swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
            .then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
        } else {

          swal.fire({
            html: <FrLoading text="Carregando..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          })

          setenableAdd(await api.getAcessos(tela, 'Inclusao'))
          setenableEdit(await api.getAcessos(tela, 'Alteracao'))
          setenableDel(await api.getAcessos(tela, 'Exclusao'))
          setComboMdc(await api.getComboData('medicamentos'))
          setComboMat(await api.getComboData('materiais'))

          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)

          let pacientes
          if (query.fk_pac)
            pacientes = await api.getComboData('pacientes', 'pac' + query.fk_pac)
          else
            pacientes = await api.getComboData('pacientes', 'reg')

          pacientes.unshift({ display: 'Residencial Primavera', value: 'null' })
          setComboPac(pacientes)

          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)

          swal.close()
          if (query.buscar === 'true') {
            let event = new Event('build');
            getData(event)
          }
        }
      }

    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      if (residente) queryObject.tem_saldo = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/estoques?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/movimentacao_estoque/reprocessa').then(r => {

        api.get('/estoques?codpac=' + auth.residente + '&' + objectToQueryString(queryObject)).then(r => {
          setData(r.data)

          api.get('/estoques/planilha?codpac=' + auth.residente + '&' + objectToQueryString(queryObject)).then(r => {
            setDataPlanilha(r.data)


            swal.close()
          })
        })
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function getDataEsl(e) {
    e.preventDefault()
    api.get('/estoque_lote/selectZerar?fk_pac=' + fk_pac).then(r => {
      setDataEsl(r.data)
    })
  }



  useEffect(() => {
    const fetchPac = async () => {

      let pacientes = await api.getComboData('pacientes', 'pac' + pac)
      pacientes.unshift({ display: 'Residencial Primavera', value: 'null' })

      setComboPac(pacientes)

      const auxValues = { ...filter };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setFilter(auxValues);
      }
    }
    fetchPac()
  }, [pac])


  useEffect(() => {
    const fetchMdc = async () => {

      const auxValues = { ...filter };

      if (fk_mdc > 0) {
        auxValues['fk_mdc'] = fk_mdc;

        setFilter(auxValues);
      }
    }
    fetchMdc()
  }, [fk_mdc])



  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/estoques');
    document.getElementById('searchForm').reset()
    setFk_Mdc(0)
    setFilter({})
  }

  function zerarEstoques(e) {
    e.preventDefault();
    setfk_pac(0)
    setDataEsl([])
    setShowModal(true)

  }

  const confirmaModal = (e) => {
    setShowModal(false);

    swal
      .fire({
        title: "ATENÇÃO",
        text: "Confirma zerar os estoques listados?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          auxValues.fk_pac = fk_pac;
          auxValues.fk_tme = 6;
          auxValues.data_mov = new Date()
          auxValues.fk_usu = getUserCode();
          auxValues.dataMit = dataEsl;
          api.post("/movimentacao_estoque/add", auxValues).then((r) => {
            if (r.status === 200 && r.data == "") {
              swal
                .fire({
                  title: "Registros Zerados",
                  text: "Registros zerados com sucesso!",
                  icon: "success",
                })
                .then((result) => {
                  if (result.value) {
                    api.get("/movimentacao_estoque/reprocessa").then((r) => {
                    })
                  }
                });
            };
          })
        }
      })
  }


  const escondeModal = (e) => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const auxValues = { ...filter };
    if (e.target.name == 'fk_mdc') {
      auxValues['fk_mdc'] = e.target.value;
      setFk_Mdc(e.target.value)
    } else
      if ((e.target.name == 'fk_pac') || (e.target.name == 'pac')) {
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
      } else
        auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);

    if (e.target.name == 'pac') {
      setfk_pac(e.target.value)
      getDataEsl(e)
    }
  };


  function agrupaPri(e) {
    e.preventDefault();
    window.location.href = "/primavera/estoquesPri"
  }


  return (

    <div className={"Manut" + (residente ? '-app' : '')}>
      <NavBar />
      <div className={"ManutContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>Estoques</h3>)}
          <UserOptions app={'Estoques'} />
        </div>
        {residente ? (<></>) : (<br />)}
        <div className={"filtersDiv" + (residente ? '-app' : '')}>



          <form id="searchForm" onSubmit={getData} className={residente ? 'filter-app' : ''}>




            <div style={{ display: (residente ? 'flex' : 'block'), flex: (residente ? '1' : 'block'), flexDirection: (residente ? 'column' : '') }}>

              <div class="container-fluid">
                <div class="row" >


                  <div class="column">
                    <FrSelect
                      value={filter.fk_pac || codPac}
                      name="fk_pac"
                      id="fk_pac"
                      label='Residente'
                      data={comboPac}
                      style={{ width: (residente ? '100%' : 300), flex: (residente ? '1' : '') }}
                      onChange={handleChange}
                      disabled={auth.residente > 0}
                      app={residente}

                    />
                  </div>

                  <div hidden={auth.residente > 0} class="column" >
                    <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                  </div>


                  <div class="column">
                    <FrSelect
                      value={filter.fk_mdc || fk_mdc}
                      style={{ width: (residente ? '100%' : 300), flex: (residente ? '1' : '') }}
                      name="fk_mdc"
                      id="fk_mdc"
                      label={auth.residente > 0 ? '' : 'Medicamento'}
                      data={comboMdc}
                      onChange={handleChange}
                      hidden={auth.residente > 0}
                    />
                  </div>
                  <div class="column" >
                    <FrameF2 tipoModal={'Medicamento'} data={data} mdc={fk_mdc} setMdc={setFk_Mdc} ></FrameF2>
                  </div>

                  <div class="column">

                    <FrSelect
                      value={filter.fk_mat || ''}
                      style={{ width: 300 }}
                      name="fk_mat"
                      id="fk_mat"
                      label={auth.residente > 0 ? '' : 'Material'}
                      data={comboMat}
                      onChange={handleChange}
                      hidden={auth.residente > 0}
                    />

                    <FrSelect
                      value={filter.situacao}
                      style={{ width: 150 }}
                      name="situacao"
                      id="situacao"
                      label='Situação'
                      data={comboSaldo}
                      onChange={handleChange}
                      hidden={auth.residente > 0}
                    />
                  </div>


                </div>
              </div>

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button hidden={auth.residente > 0} onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/primavera/estoques/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

              <ExcelFile element={<button>
                <Icon icon={fileExcel} size={18} />
                Exportar</button>}>
                <ExcelSheet data={dataPlanilha} name="Estoques">
                  <ExcelColumn label="Código Sistema" value="pk_est" />
                  <ExcelColumn label="Material/Medicamento" value="descricao" />
                  <ExcelColumn label="Residente" value="nomepac" />
                  <ExcelColumn label="Estoque Mínimo" value="estoque_minimo" />
                  <ExcelColumn label="Unidade" value="nomeuni" />
                  <ExcelColumn label="Saldo Estoque" value="saldo_estoque" />
                  <ExcelColumn label="Saldo Inventariado" value="saldo_estoque" />
                  <ExcelColumn label="Lote" value="lote" />
                  <ExcelColumn label="Saldo Inventariado" value="saldo_lote" />
                  <ExcelColumn label="Validade" value="data_validade" />
                </ExcelSheet>
              </ExcelFile>

              <button style={{ width: '240px' }} onClick={agrupaPri}>
                Agrupar por Princípio Ativo
              </button>
            </div>

            <div >
              <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >

                <div style={{ padding: '20px', paddingTop: '40px' }}>

                  <FrSelect
                    value={fk_pac}
                    style={{ width: (residente ? '100%' : 300), flex: (residente ? '1' : '') }}
                    name="pac"
                    id="pac"
                    label='Residente'
                    data={comboPac}
                    onChange={handleChange}
                  />

                  <button onClick={getDataEsl} style={{ backgroundColor: '#528b46', height: '30px', color: 'white' }}>
                    <Icon icon={ic_search} size={18} />
                    Pesquisar
                  </button>
                </div>

                <FrTable readOnly={true} regName="" columns={columnsEsl} searched={filtered} codeName="pk_est" page={'estoques'} data={dataEsl} />

                <div className="FrModalFooter">
                  <div className="FrModalButtonsDiv" >
                    <button style={{ height: '40px' }} onClick={confirmaModal}> Confirmar </button>
                    <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                  </div>
                </div>
              </Dialog>
            </div>



          </form>
        </div>
        {residente ? (<></>) : (<br />)}
        <div className={"tableDiv" + (residente ? '-app' : '')}>
          <FrTable detail={true} readOnly={auth.residente > 0} regName="estoques" columns={auth.residente > 0 ? columns2 : columns} searched={filtered} codeName="pk_est" page={'estoques'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div >
    </div >
  );
}


export default Estoques;
