import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Tooltip from '@material-ui/core/Tooltip';
import FrTextInput from "../components/FrTextInput";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";

import api from "../services/api";
import "./styles/DefaultReg.css";

import FrDatePicker from '../components/FrDatePicker';
const swal = withReactContent(Swal);

export default function Internacao(props) {

  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [blockAdmissao, setBlockAdmissao] = useState(true)
  const [comboPac, setComboPac] = useState([
    {
      value: "",
      display: ""
    }
  ]);
  const [comboPrf, setComboPrf] = useState([
    {
      value: "",
      display: ""
    }
  ]);
  const [comboPho, setComboPho] = useState([
    {
      value: "",
      display: ""
    }
  ]);
  const [comboPlt, setComboPlt] = useState([
    {
      value: "",
      display: ""
    }
  ]);
  const [comboSti, setComboSti] = useState([
    {
      value: "",
      display: ""
    }
  ]);

  const [comboAna, setComboAna] = useState([
    {
      value: "",
      display: ""
    }
  ]);

  
  useEffect(() => {
    const fetchAdmissao = async () => {
      if (data.fk_pac > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Admissões..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboAna(await api.getComboData('anamnese', data.fk_pac))
        swal.close()
        setBlockAdmissao(false)
      } else setBlockAdmissao(true)
    }

    fetchAdmissao()
  }, [data.fk_pac])


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      setComboPac(await api.getComboData("pacientes"));
      setComboPrf(await api.getComboData("profissionais"));
      setComboPho(await api.getComboData("plano_hospedagem"));
      // setComboPlt(await api.getComboData('plano_trabalho'))
      setComboSti(await api.getComboData("status_internacao"));

      
      if (props.pk_int > 0) {
        setUpdateType(true);
        api.get("/internacao?pk_int=" + props.pk_int).then(r => {
          if (r.data[0]) {
            setData(r.data[0]);


            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then(result => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else swal.close();
    };
    fetchData();
  }, [props.pk_int]);

  function consistData(data) {
    let required = [
      "data_entrada",
      "fk_prf",
      "fk_pac",
      "fk_pho",
      // 'fk_plt',
      "fk_sti"
    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "fk_prf") fieldName = "Profissional";
      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "fk_pho") fieldName = "Plano de Hospedagem";
      if (curr === "fk_sti") fieldName = "Status Internação";
      if (curr === "fk_plt") fieldName = "Plano de Trabalho";

      if (!data[curr]) return errors.push(fieldName);
    });


    if ((data['exige_data_saida'] === 'S') && (!data['data_saida'])) {
      errors.push('data_saida')
    }

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Deseja salvar " +
          (updateType ? "as alterações" : "a inclusão") +
          " do registro?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          const auxValues = {
            ...data
          };

          let errors = consistData(auxValues);

          if (errors.length === 0) {
            if (updateType) {
              api.post("/internacao/edit", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Alterado",
                      text: "O registro foi alterado com sucesso!",
                      icon: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi alterado!",
                    icon: "warning"
                  });
                }
              });
            } else {
              api.post("/internacao/add", auxValues).then(r => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Adicionado",
                      text: "O registro foi adicionado com sucesso!",
                      icon: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                } else {
                  swal.fire({
                    title: "Erro!",
                    text: "O registro não foi adicionado!",
                    icon: "warning"
                  });
                }
              });
            }
          } else {
            swal.fire({
              title: "Erro!",
              text: "Verifique o(s) campo(s) " + errors.join(","),
              icon: "warning"
            });
          }
        }
      });
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = e => {
    const auxValues = {
      ...data
    };
    switch (e.target.name) {
      case 'fk_sti':
        api.get('/status_internacao?pk_sti=' + e.target.value).then(r => {
          auxValues['exige_data_saida'] = r.data[0].exige_data_saida;
        })
        auxValues[e.target.name] = e.target.value;
        break

      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (

      <div>
        <FrSelect
          value={data.fk_pac || ""}
          onChange={handleChange}
          name="fk_pac"
          id="fk_pac"
          label="Residente"
          data={comboPac}
          style={{ width: 400 }}
        />
        <FrSelect
          value={data.fk_prf || ""}
          onChange={handleChange}
          name="fk_prf"
          id="fk_prf"
          label="Profissional"
          data={comboPrf}
          style={{
            width: 400
          }}
        />
        <br />
        <FrDatePicker
          value={(data.data_entrada || "").split("T")[0]}
          onChange={handleChange}
          name="data_entrada"
          id="data_entrada"
          color="#528b46"
          label="Entrada"
          variant="outlined"
          size="small"
          style={{ width: 190 }}
        />
        <FrDatePicker
          value={(data.data_prev_saida || "").split("T")[0]}
          onChange={handleChange}
          name="data_prev_saida"
          id="data_prev_saida"
          color="#528b46"
          label="Previsão Saída"
          variant="outlined"
          size="small"
          style={{ width: 190 }}
        />
        <FrDatePicker
          value={(data.data_saida || "").split("T")[0]}
          onChange={handleChange}
          name="data_saida"
          id="data_saida"
          color="#528b46"
          label="Saída"
          variant="outlined"
          size="small"
          style={{ width: 190 }}
        />
        <br />

        <Tooltip title={blockAdmissao ? 'Informe o residente antes de informar a admissão!' : ''}>
          <FrSelect
            value={data.fk_ana || ''}
            onChange={handleChange}
            name="fk_ana"
            id="fk_ana"
            label='Admissão'
            data={comboAna}
            style={{ width: 370 }}
            disabled={blockAdmissao}
            required
          />
        </Tooltip>

        <br />

        <FrSelect
          value={data.fk_pho || ""}
          onChange={handleChange}
          name="fk_pho"
          id="fk_pho"
          label="Plano de Hospedagem"
          data={comboPho}
          style={{ width: 200 }}
        />

        <FrSelect
          value={data.fk_plt || ""}
          onChange={handleChange}
          name="fk_plt"
          id="fk_plt"
          label="Plano de Trabalho"
          data={comboPlt}
          style={{ width: 200 }}
        />
        <FrSelect
          value={data.fk_sti || ""}
          onChange={handleChange}
          name="fk_sti"
          id="fk_sti"
          label="Status"
          data={comboSti}
          style={{ width: 150 }}
        />

        <br />
        <FrTextInput
          maxLength="500"
          value={data.observacoes || ""}
          onChange={handleChange}
          name="observacoes"
          id="observacoes"
          color="#528b46"
          label="Observações"
          variant="filled"
          size="small"
          required
          style={{ width: 400 }}
          multiline
          rows="8"
          rowsMax="8"
          fullWidth
        />
      </div>

  );
}

// export default Internacao;
