import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrTextInput from '../components/FrTextInput'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import NavBar from '../components/NavBar';
import UserOptions from '../components/UserOptions';


import api from '../services/api'
import { dateSql, getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Mov_Bco() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCbc, setComboCbc] = useState([{ value: '', display: '' }])
  const [comboCredDeb, setComboCredDeb] = useState([{ value: 'C', display: 'Crédito' }, { value: 'D', display: 'Débito' }])

  useEffect(() => {
    const fetchData = async () => {
      let auth = JSON.parse(localStorage['authPrimavera']);
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboCbc(await api.getComboData('contas_bancarias'))


      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Movimentações Bancárias', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Movimentações Bancárias', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/mov_bco?pk_mbc=' + query.codigo).then(r => {
          setData(r.data[0])
          swal.close()
        })
      }
      else swal.close()
    }

    fetchData()
  }, [])


  function consistData(data) {
    let required = [
      'data',
      'fk_cbc',
      'cred_deb',
      'valor',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_cbc") fieldName = "Conta";


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues['data'] = dateSql(auxValues['data'])
        auxValues.fk_usu = getUserCode()
        if (errors.length === 0) {
          if (updateType) {
            api.post('/mov_bco/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/mov_bco/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className={"defaultReg"}>
      <NavBar />
      <div className={"defaultRegContent"}>
        <div className={"defaultHeader"}>
          {(<h3>{updateType ? 'Edição' : 'Inclusão'} de Movimentação Bancária</h3>)}
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} >
          <div>

            <FrDatePicker
              value={(data.data || "").split("T")[0]}
              onChange={handleChange}
              name="data"
              id="data"
              color="#528b46"
              label="Data Movimento"
              variant="outlined"
              size="small"
              style={{ width: 180 }}
            />

            <FrSelect
              value={data.fk_cbc || ''}
              onChange={handleChange}
              name="fk_cbc"
              id="fk_cbc"
              data={comboCbc}
              style={{ width: 450 }}
              required
              label='Conta'
            />

            <FrSelect
              value={data.cred_deb || ''}
              onChange={handleChange}
              name="cred_deb"
              id="cred_deb"
              data={comboCredDeb}
              style={{ width: 150 }}
              required
              label='Crédito/Débito'
            />

            <FrTextInput
              maxLength='150'
              value={data.valor || ''}
              onChange={handleChange}
              name='valor'
              id='valor'
              color='#c0c0c0'
              label='Valor'
              variant='outlined'
              size='small'
              style={{ width: 120 }}
            />

            <br />
            <FrTextInput
              maxLength='50'
              value={data.numdoc || ''}
              onChange={handleChange}
              name='numdoc'
              id='numdoc'
              color='#c0c0c0'
              label='Nº Doc.'
              variant='outlined'
              size='small'
              style={{ width: 120 }}
            />

            <FrTextInput
              maxLength='150'
              value={data.historico || ''}
              onChange={handleChange}
              name="historico"
              id="historico"
              color="#528b46"
              label="Histórico"
              variant='outlined'
              size="small"
              style={{ width: 300 }}
            />
          </div>


        </form>
        <div className={"regButtonsDiv"}>
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}
export default Mov_Bco;
