import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function MostraAlertasProrrogacoes() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)

  const columns = [
    { id: '', numeric: false, label: '', align: 'left', minWidth: 10, icon: true },
    { id: 'nome', numeric: false, label: 'Residente', align: 'left', minWidth: 150 },
    { id: 'formatdata_entrada', numeric: false, label: 'Data Entrada', align: 'left', minWidth: 50 },
    { id: 'formatultimo_vcto', numeric: false, label: 'Último Vcto', align: 'left', minWidth: 50 },


  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      swal.close()
      let event = new Event('build');
      getData(event)
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    setFiltered(true)
    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    setFilter(query)

    api.get('/internacao/mostraAlertasProrrogacoes').then(r => {
      api.get('/internacao/mostraAlertasProrrogacoes').then(r => {

        setData(r.data)
        swal.close()
      })
    })
  }

  function handleClose(e) {
    e.preventDefault();
    window.history.back()
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Contratos com Pendência de Prorrogação</h3>
          <UserOptions />
        </div>
        <div className="tableDiv">
          <FrTableReg regName="prorrogacoes" readonly={true} columns={columns} searched={filtered} codeName="pk_int" page={'Evolucao'} data={data} enableEdit={false} enableDelete={false} />
        </div>

        <div className="regButtonsDiv">
          <button onClick={handleClose}>Sair</button>
        </div>

        <FooterDelphus />
      </div>
    </div>
  );
}

export default MostraAlertasProrrogacoes;
