import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {ic_visibility} from 'react-icons-kit/md/ic_visibility'
import {ic_visibility_off} from 'react-icons-kit/md/ic_visibility_off'
import {upload} from 'react-icons-kit/fa/upload'
import { Modal } from 'react-bootstrap'
import $ from 'jquery'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Usuarios() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [certificado, setCertificado] = useState({ id: auth.user, certificado: null, senha: null })
  const [showPassword, setShowPassword] = useState(false)

 


  const confirmaModal = (e) => {
    e.preventDefault()
    if (certificado.id && certificado.senha && certificado.certificado) {
      let formData = new FormData();
      formData.append('file', certificado.certificado, certificado.certificado.name)
      api.post('/usuarios/certificado/'+certificado.id+'/'+certificado.senha,formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Certificado enviado!',
            text: "O certificado foi enviado com sucesso!",
            icon: 'success',
          }).then((result) => {
              if (result.value) {
                window.location.href = '/sistema/home'
              }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "O certificado não pôde ser enviado!",
            icon: 'error',
        })
        }
      })
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Selecione um certificado válido e preencha a senha!",
        icon: 'error',
      })
    }
  }

  const escondeModal = (e) => {
    e.preventDefault();
    window.location.href = '/sistema/home'
  }

   const handleChangeCertificado = (e) => {
    const auxValues = { ...certificado };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setCertificado(auxValues);
  };

  const selectFile = (e) => {
		e.preventDefault();
		$('#html_btn_certificado').click();
  }

  const handleSelectedFile = (e) => {
		let file = e.target.files[0];
    console.log(file)
    if (file) {
      const auxValues = { ...certificado };
      console.log(auxValues)
      auxValues.certificado = file;
      setCertificado(auxValues);
    }
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3></h3>
          <UserOptions />
        </div>
        <br />


          <Modal.Dialog className="Modal">
            <div>
              <Modal.Body className="ModalBg" >
                <h4>Certificado</h4>
                <br/>
                <div>
                  <input 
                    type="file" 
                    name="certificado"
                    id="html_btn_certificado"
                    accept=".pfx, .p12"
                    onChange={handleSelectedFile}
                    style={{ display: 'none' }}
                  />
                  <button className="uploadFile" type="file" onClick={selectFile}>
                      <Icon icon={upload} size={20}/> Selecionar Certificado
                  </button>
                  {certificado?.certificado?.name || 'Nenhum arquivo selecionado.'}
                </div>
                <br/>
                <FrTextInput
                  maxLength='150'
                  value={certificado?.senha}
                  onChange={handleChangeCertificado}
                  name='senha'
                  id='senha'
                  color='#c0c0c0'
                  label='Senha'
                  variant='outlined'
                  size='small'
                  type={showPassword ? 'text' : 'password'}
                  style={{ width: 120 }}
                />
                <button className="showPasswordButton" onClick={e => { e.preventDefault(); setShowPassword(!showPassword); }}><Icon icon={showPassword ? ic_visibility_off : ic_visibility} size={18} /></button>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <div className="regButtonsDiv">
                <button onClick={confirmaModal}> Enviar </button>
                <button onClick={escondeModal}> Cancelar </button>
              </div>
            </Modal.Footer>
          </Modal.Dialog>

        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
