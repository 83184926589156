import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'
import { paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import FooterDelphus from '../components/FooterDelphus'
import UserOptions from '../components/UserOptions'
import MUIRichTextEditor from 'mui-rte'
import NavBar from '../components/NavBar'

const swal = withReactContent(Swal)

function ImprimeReceita() {
  const [data, setData] = useState({})
  const [editorState, setEditorState] = useState({})


  let sampleMarkup = ''
  let contentHTML = convertFromHTML(sampleMarkup)
  let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
  const [content, setContent] = useState(JSON.stringify(convertToRaw(state)))
  const [content2, setContent2] = useState('')


  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      api.get('/prescricao_medica?pk_pmd=' + query.pk_pmd).then(r => {
        setData(r.data[0])

        api.get('/prescricao_medica/preImpressao?pk_pmd=' + query.pk_pmd).then(async r => {
          if (r.data[0]) {
            let sampleMarkup = r.data
            let contentHTML = convertFromHTML(sampleMarkup)
            let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
            setContent(JSON.stringify(convertToRaw(state)))
          }
        })
      })
    }
    fetchData()
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma salvar as alterações do registro?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.texto_prescricao = stateToHTML(editorState.getCurrentContent())
        api.post('/prescricao_medica/editTexto', auxValues).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Alterado',
              text: "O registro foi alterado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.open(api.backendUrl + '/api/prescricao_medica/print?pk_pmd=' + data.pk_pmd + '&pk_usu=' + getUserCode() + '&tipo=O');
                window.location.href = '/primavera/prescricao_medica?situacao=Ativas'
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    window.location.href = '/primavera/prescricao_medica?situacao=Ativas'
  }


  return (
    <div className={"defaultReg"}>
      <NavBar />

      <div className={"defaultRegContent"}>
        <div className={"defaultHeader"}>
          <h3>Impressão de Receita</h3>
          <UserOptions />
        </div>
        <form className={"formReceita"} id="regForm" onSubmit={handleSubmit} >
          <div className={"divReceita"}>
            <h4>RECEITUÁRIO DE CONTROLE ESPECIAL</h4>
          </div>
          <br />
          <table style={{ width: '100%', margin: '5px' }}>
            <tr>
              <td style={{ width: '45%' }}>
                <h6 style={{ textAlign: 'center', border: '3px solid', fontWeight: 'bold' }}>IDENTIFICAÇÃO DO EMITENTE</h6>
                <tr>
                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    NOME:
                  </td>
                  <td>
                    {data.nomeprf}
                  </td>
                </tr>

                <tr>
                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    CRM:
                  </td>
                  {data.matricula} - {data.nomecop} / {data.copuf}
                </tr>

                <tr>
                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    ENDEREÇO:
                  </td>
                  <td>
                    Rua Elaine Cristine Milanese, Mina Brasil - 88811-042 - (48)99608-1779 - Criciúma/SC
                  </td>
                </tr>

                <tr>
                  <td style={{ paddingTop: '10px', textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    PACIENTE:
                  </td>
                  <td style={{ paddingTop: '10px' }}>
                    {data.nomepac}
                  </td>
                </tr>

              </td>

              <td style={{ width: '12%' }}>
              </td>


              <td>
                <table>
                  <tr style={{ fontWeight: 'bold' }}>
                    <h6 style={{ fontWeight: 'bold' }}>1º VIA FARMÁCIA</h6>
                  </tr>
                  <tr>
                    <h6 style={{ fontWeight: 'bold' }}>2º VIA PACIENTE</h6>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <br />

          <h6 style={{ fontWeight: 'bold', margin: '5px' }}>PRESCRIÇÃO:</h6>
          <div style={{ width: 'auto', border: '1px solid #528b46', backgroundColor: 'silver', marginLeft: '5px', marginRight: '5px' }}>

            <MUIRichTextEditor
              onChange={value => {
                const content = JSON.stringify(convertToRaw(value.getCurrentContent()));
                setContent2(content);
                setEditorState(value)
              }}

              controls={[]}//{["title", "bold", "italic", "underline", "highlight", "undo", "redo", "numberList", "bulletList"]}
              defaultValue={content} data={data.texto_prescricao} name="texto_prescricao" id="texto_prescricao" />


          </div>


        </form>



        <div className={"regButtonsDiv"}>
          <button onClick={handleSubmit}>Salvar</button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ImprimeReceita;
