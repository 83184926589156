import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import { mCNPJ, mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';


const swal = withReactContent(Swal)

function Fornecedores(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])


  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_cad ? props.pk_cad : query.codigo

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Fornecedores/Funcionários', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Fornecedores/Funcionários', 'Inclusao'))
      }

      setComboCid(await api.getComboData('cidades'))
      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/fornecedores?pk_cad=' + codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
          } else {
            swal.fire({
              customClass: {
                container: 'my-swal'
              },
              title: 'Erro!',
              text: 'Código informado não encontrado!',
              icon: 'warning',
            })
              .then((result) => {
                if (result.value) {
                  if (!props.modal)
                    window.history.back()
                }
              })
          }
        })
      }
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'razao_social',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (!data[curr]) return errors.push(fieldName)

      if ((data.tipo_for !== 'S') & (data.tipo_fun !== 'S'))
        errors.push('Tipo')
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/fornecedores/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal({
                  customClass: {
                    container: 'my-swal'
                  },
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result) {
                    if (props.modal)
                      props.setShowModal(false)

                    else
                      window.history.back()
                  }
                })
              } else {
                swal({
                  customClass: {
                    container: 'my-swal'
                  },
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/fornecedores/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire(
                  {
                    customClass: {
                      container: 'my-swal'
                    },
                    title: 'Registro Alterado',
                    text: "O registro foi alterado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      if (props.modal) {
                        if (props.setShowModal)
                          props.setShowModal(false)
                      } else
                        window.history.back()
                    }
                  })
              } else {
                swal.fire(
                  {
                    customClass: {
                      container: 'my-swal'
                    },
                    title: 'Erro!',
                    text: "O registro não foi alterado!",
                    icon: 'warning',
                  })
              }
            })
          }
        } else {
          swal.fire(
            {
              customClass: {
                container: 'my-swal'
              },
              title: 'Erro!',
              text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })

        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cnpj':
        auxValues[e.target.name] = mCNPJ(e.target.value);
        break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value);
        break

      case 'tipo_for':
      case 'tipo_fun':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break


      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">

      {!props.modal ? <NavBar /> : <></>}
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Fornecedor/Funcionário</h3>
          {!props.modal ? <UserOptions /> : <></>}
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FormControlLabel
            control={
              <Checkbox
                value={data.tipo_for || ''}
                checked={data.tipo_for === 'S'}
                name="tipo_for"
                id="tipo_for"
                size='small'
                color="primary"
                onChange={handleChange}
              />
            }
            label="Fornecedor"
          />

          <FormControlLabel
            control={
              <Checkbox
                value={data.tipo_fun || ''}
                checked={data.tipo_fun === 'S'}
                name="tipo_fun"
                id="tipo_fun"
                size='small'
                color="primary"
                onChange={handleChange}
              />
            }
            label="Funcionário"
          />

          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Situação'
            data={comboAtivo}
            style={{ width: 80 }}
          />

          <br />
          <FrTextInput
            maxLength='100'
            value={data.razao_social || ''}
            onChange={handleChange}
            name="razao_social"
            id="razao_social"
            color="#528b46"
            label="Razão Social"
            variant="outlined"
            size="small"
            required
            style={{ width: 430 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.fantasia || ''}
            onChange={handleChange}
            name="fantasia"
            id="fantasia"
            color="#528b46"
            label="Nome Fantasia"
            variant="outlined"
            size="small"
            style={{ width: 430 }}
          />
          <br />

          <FrTextInput
            maxLength='18'
            value={data.cnpj || ''}
            onChange={handleChange}
            name="cnpj"
            id="cnpj"
            color="#528b46"
            label="CNPJ"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='14'
            value={data.cpf || ''}
            onChange={handleChange}
            name="cpf"
            id="cpf"
            color="#528b46"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.rg || ''}
            onChange={handleChange}
            name="rg"
            id="rg"
            color="#528b46"
            label="RG"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
          />

          <FrTextInput
            maxLength='20'
            value={data.inscricao_estadual || ''}
            onChange={handleChange}
            name="inscricao_estadual"
            id="inscricao_estadual"
            color="#528b46"
            label="Inscrição Estadual"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
          />

          <FrTextInput
            maxLength='20'
            value={data.inscricao_municipal || ''}
            onChange={handleChange}
            name="inscricao_municipal"
            id="inscricao_municipal"
            color="#528b46"
            label="Inscrição Municipal"
            variant="outlined"
            size="small"
            style={{ width: 130 }}
          />

          <br />


          <FrTextInput
            maxLength='100'
            value={data.endereco || ''}
            onChange={handleChange}
            name="endereco"
            id="endereco"
            color="#528b46"
            label="Endereço"
            variant="outlined"
            size="small"
            style={{ width: 400 }}
          />

          <FrTextInput
            maxLength='30'
            value={data.bairro || ''}
            onChange={handleChange}
            name="bairro"
            id="bairro"
            color="#528b46"
            label="Bairro"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrSelect
            value={data.fk_cid || ''}
            onChange={handleChange}
            name="fk_cid"
            id="fk_cid"
            label='Cidade'
            data={comboCid}
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='8'
            value={data.cep || ''}
            onChange={handleChange}
            name="cep"
            id="cep"
            color="#528b46"
            label="CEP"
            variant="outlined"
            size="small"
            style={{ width: 100 }}
          />

          <br />

          <FrTextInput
            maxLength='30'
            value={data.fone1 || ''}
            onChange={handleChange}
            name="fone1"
            id="fone1"
            color="#528b46"
            label="Fone 1"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='30'
            value={data.fone2 || ''}
            onChange={handleChange}
            name="fone2"
            id="fone2"
            color="#528b46"
            label="Fone 2"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='30'
            value={data.celular || ''}
            onChange={handleChange}
            name="celular"
            id="celular"
            color="#528b46"
            label="Celular"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.email || ''}
            onChange={handleChange}
            name="email"
            id="email"
            color="#528b46"
            label="E-mail"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          />

          <br />

          <FrTextInput
            maxLength='200'
            value={data.observacao || ''}
            onChange={handleChange}
            name='observacao'
            id='observacao'
            color='#528b46'
            label='Observações'
            variant="filled"
            size='small'
            style={{ width: 500 }}
            multiline
            rows="3"
            rowsMax="3"
            fullWidth
          />


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Fornecedores;
